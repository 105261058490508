import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Room.scss'
import { formatFileSize, getUUID } from '../../../../utility'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { fileUploadS3Public, uploadTypes } from '../../../../fetch'
import Message from './Message/Message'

const Room = ({ activeRoomUUID, objectUUID, socket, roomSelect }) => {
  const token = useSelector((state) => state.TOKEN)
  const user = useSelector((state) => state.USER)
  const translations = useSelector((state) => state.translations)

  const [messages, setMessages] = useState(null)
  const [message, setMessage] = useState('')
  const [attachments, setAttachments] = useState([])
  const [room, setRoom] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [fileInProgress, setFileInProgress] = useState(null)

  useEffect(() => {
    const scroll = () => {
      if (messages) {
        document.getElementById('chat-messages-wrapper').scrollTop = document.getElementById('chat-messages-wrapper').scrollHeight
      }
    }

    if (token && !room && !messages) {
      const payload = { token }
      if (objectUUID && !activeRoomUUID) {
        payload.objectUUID = objectUUID
      } else if (activeRoomUUID) {
        payload.roomUUID = activeRoomUUID
      }
      socket.emit('join-room', payload)
      socket.on('join-room', ({ room, messages }) => {
        socket.emit('update-read-timestamp', { token, roomUUID: room.uuid })
        setRoom(room)
        setMessages(messages)
        scroll()
      })
    } else {
      scroll()
    }
    socket.on('message', (data) => {
      socket.emit('update-read-timestamp', { token, roomUUID: room.uuid })
      setMessages([...messages, data])
      scroll()
    })

    return () => {
      socket.off('message')
    }
  }, [activeRoomUUID, room, socket, token, objectUUID, messages])

  const uploadAttachments = async () => {
    const items = []
    if (attachments.length > 0) {
      setUploading(true)
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i]
        setFileInProgress(attachment)
        const url = await fileUploadS3Public(token, attachment, uploadTypes.CHAT_ATTACHMENT)
        if (url) {
          items.push({
            name: attachment.name,
            size: attachment.size,
            url,
          })
        }
      }
      setAttachments([])
      setFileInProgress(null)
      setUploading(false)
    }
    return items
  }

  const sendMessage = async () => {
    if (message.trim().length > 0) {
      const items = await uploadAttachments()

      socket.emit('create-message', {
        message: message.trim(),
        token,
        attachments: items,
        roomUUID: room.uuid,
      })
      setMessage('')
    }
  }

  const removeAttachment = (index) => setAttachments(attachments.filter((a, i) => i !== index))

  if (!room && user) return <></>
  return (
    <div className="chat-room">
      {messages && (
        <div id="chat-messages-wrapper">
          {messages.map((m) => (
            <Message key={getUUID()} message={m} />
          ))}
        </div>
      )}
      {!uploading ? (
        <div className="compose">
          <div className="input">
            <textarea rows={1} value={message} onChange={(e) => setMessage(e.target.value)} placeholder={translations['chat.messagestab.conversation.messageplaceholder']}></textarea>
            <input type="file" id="attachment-input" multiple accept="*/*" onChange={(e) => setAttachments([...attachments, ...e.target.files])} />
            <label htmlFor="attachment-input" className="attachment-wrapper">
              <AttachFileIcon />
            </label>
          </div>
          <div className="submit" onClick={sendMessage}>
            <SendOutlinedIcon />
          </div>
        </div>
      ) : (
        <div className="uploading">
          <div className="label">
            {translations['chat.messagestab.conversation.attachments.upload-label']}
            {fileInProgress ? `(${fileInProgress.name})` : ''}
          </div>
        </div>
      )}
      {attachments.length > 0 && !uploading && (
        <div className="attachment-list">
          <div className="headline">{translations['chat.messagestab.conversation.attachments']}</div>
          {attachments.map((a, i) => (
            <div key={getUUID()} className="item">
              <div className="name">{a.name}</div>
              <div className="size">{formatFileSize(a.size)}</div>
              <div className="remove" onClick={() => removeAttachment(i)}>
                <CloseOutlinedIcon />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Room
