import { useDispatch, useSelector } from 'react-redux'
import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'
import props from '../../../../../../redux/props'
import { setLocal } from '../../../../../../redux/action/local'

const DealCriteria = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (type) => (newArr) => {
    const updatePurchaseProfile = { ...purchaseProfile, dealCriteria: { ...purchaseProfile.dealCriteria, [type]: newArr } }
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  if (!settings && !translations) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.deal-criteria']}</div>
        <div className="subgroup">
          <div className="element">
            <div className="label">{translations['object.deal-criteria.deal-type']}</div>
            <div className="values">
              <CheckboxGroup
                options={settings.getGroup('object.deal-criteria.deal-type.option').map((option) => ({ label: translations[option.key], value: option.key }))}
                values={purchaseProfile.dealCriteria.dealType}
                onChange={update('dealType')}
                itemsPerRow={3}
              />
            </div>
          </div>
          <div className="element">
            <div className="label">{translations['object.deal-criteria.investment-type']}</div>
            <div className="values">
              <CheckboxGroup
                options={settings.getGroup('object.deal-criteria.investment-type.option').map((option) => ({ label: translations[option.key], value: option.key }))}
                values={purchaseProfile.dealCriteria.investmentType}
                onChange={update('investmentType')}
                itemsPerRow={4}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealCriteria
