import { useSelector } from 'react-redux'
import { fileUploadS3Private, uploadTypes } from '../../../../fetch'
import './Upload.scss'
import Modal from '../../../UI/Modal/Modal'
import Button from '../../../UI/Button/Button'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import { formatFileSize, getUUID } from '../../../../utility'

const Upload = ({ pushFiles, selectedFolder, selectedDocumentType, selectedSpecification }) => {
  const token = useSelector((state) => state.TOKEN)
  const translations = useSelector((state) => state.translations)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const uploadFiles = async () => {
    const files = []

    for (const file of selectedFiles) {
      const s3Key = await fileUploadS3Private(token, file, uploadTypes.DATENRAUM)
      if (s3Key) {
        files.push({
          folderKey: selectedFolder.key,
          documentTypeKey: selectedDocumentType?.key || null,
          specificationKey: selectedSpecification?.key || null,
          filename: file.name,
          filesize: file.size,
          s3Key,
        })
      }
    }

    pushFiles(files)
    setSelectedFiles([])
    setOpenModal(false)
  }

  const processFileSelection = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFiles([...selectedFiles, ...e.target.files])
    }
  }

  const removeFile = (index) => () => {
    setSelectedFiles(selectedFiles.filter((f, i) => i !== index))
  }

  return (
    <>
      <div id="storage-upload">
        <Button onClick={() => setOpenModal(true)} text={translations['storage.upload.cta']} fullWidth={false} />
      </div>
      <Modal open={openModal} close={() => setOpenModal(false)} title={translations['storage.upload.modal.headline']}>
        <div className="storage-upload-modal">
          <input type="file" id="file-input" multiple onChange={processFileSelection} />
          <label className="upload-label" htmlFor="file-input">
            {translations['storage.upload.label']}
          </label>
          <div className="file-list">
            {selectedFiles.map((file, i) => (
              <div className="file" key={getUUID()}>
                <div className="name">{file.name}</div>
                <div className="size">{formatFileSize(file.size)}</div>
                <div className="remove-icon" onClick={removeFile(i)}>
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>
          {selectedFiles.length > 0 && (
            <div className="upload-cta" onClick={uploadFiles}>
              {translations['storage.upload.start-cta']}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Upload
