import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const InvestmentVolume = () => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, investmentVolume: { ...purchaseProfile.investmentVolume, [key]: value } }))
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, investmentVolume: { ...purchaseProfile.investmentVolume, [key]: value } }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.investment-volume.single']}</div>
        <div className="values">
          <div className="subgroup">
            <div className="element">
              <Input
                type="customNumber"
                value={purchaseProfile.investmentVolume.singleFrom}
                onChange={update('singleFrom')}
                placeholder={translations['my-objects.pipeline.assets-class.investment-volume.from']}
                symbol={translations['currency.string']}
              />
            </div>
            <div className="element">
              <Input
                type="customNumber"
                value={purchaseProfile.investmentVolume.singleTo}
                onChange={update('singleTo')}
                placeholder={translations['my-objects.pipeline.assets-class.investment-volume.to']}
                symbol={translations['currency.string']}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.investment-volume.total']}</div>
        <div className="values">
          <div className="subgroup">
            <div className="element">
              <Input
                type="customNumber"
                value={purchaseProfile.investmentVolume.totalFrom}
                onChange={update('totalFrom')}
                placeholder={translations['my-objects.pipeline.assets-class.investment-volume.from']}
                symbol={translations['currency.string']}
              />
            </div>
            <div className="element">
              <Input
                type="customNumber"
                value={purchaseProfile.investmentVolume.totalTo}
                onChange={update('totalTo')}
                placeholder={translations['my-objects.pipeline.assets-class.investment-volume.to']}
                symbol={translations['currency.string']}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="values">
          <RadioGroup
            name={translations['my-objects.pipeline.assets-class.investment-volume.portfolio']}
            value={purchaseProfile.investmentVolume.portfolioSearch}
            onChange={update('portfolioSearch')}
            options={settings.getGroup('my-objects.pipeline.assets-class.investment-volume.portfolio').map((option) => ({
              key: option.key,
              value: translations[option.translationKey],
            }))}
          />
        </div>
      </div>
    </div>
  )
}

export default InvestmentVolume
