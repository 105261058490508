import fetch from '../../../../../fetch'
import Checkbox from '../../../../UI/Checkbox/Checkbox'
import QRCode from '../../../../UI/QRCode/QRCode'
import Input from '../../../../UI/Input/Input'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const TwoFA = () => {
  const token = useSelector((state) => state.TOKEN)
  const translations = useSelector((state) => state.translations)
  const [tokenInput, setTokenInput] = useState('')
  const [twoFAState, setTwoFAState] = useState(null)
  const [showVerificationInput, setShowVerificationInput] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [message, setMessage] = useState(null)

  const changeActivationState = async (input) => {
    setTokenInput(input)
    if (input.trim().length === 6) {
      const { status, state } = await fetch('/user/2fa/swap-activation-state', { token, input: input.trim() })
      if (status) {
        setMessage(translations['settings.personal-data.2fa.success'])
        setTwoFAState(state)
        setShowVerificationInput(false)
        setShowQRCode(false)
        setTokenInput('')
      } else {
        setMessage(translations['settings.personal-data.2fa.error'])
      }
    }
  }

  const enableVerification = (targetActivationStatus) => {
    const message = targetActivationStatus ? translations['settings.personal-data.2fa.enable-hint'] : translations['settings.personal-data.2fa.disable-hint']
    setMessage(message)
    setShowQRCode(targetActivationStatus)
    setShowVerificationInput(true)
  }

  useEffect(() => {
    const get2FAState = async () => {
      const { status, state } = await fetch('/user/2fa/state', { token })
      if (status && state) {
        setTwoFAState(state)
      }
    }

    if (!twoFAState) {
      get2FAState()
    }
  }, [twoFAState, token])

  if (!twoFAState) return <></>
  return (
    <div id="twoFA">
      <Checkbox checked={twoFAState.twoFAActivated} onChange={enableVerification} text={translations['settings.personal-data.2fa.activate']} />
      {message && <div className="message">{message}</div>}
      {showQRCode && (
        <div className="qr-code-wrapper">
          <div className="configure2FA">
            <QRCode data={twoFAState.twoFAuri} />
          </div>
        </div>
      )}
      {showVerificationInput && (
        <div className="input-wrapper">
          <Input value={tokenInput} onChange={changeActivationState} placeholder={translations['settings.personal-data.2fa.token-input']} />
        </div>
      )}
    </div>
  )
}

export default TwoFA
