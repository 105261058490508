import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import { useSelector } from 'react-redux'
import './NewObjects.scss'
import FullScreenHeader from '../../Layout/Dashboard/FullScreenHeader/FullScreenHeader'
import TabView from '../../UI/TabView/TabView'
import Suggestions from './Suggestions/Suggestions'
import Requested from './Requested/Requested'

const NewObjects = () => {
  const translations = useSelector((state) => state.translations)
  const tabItems = [
    { name: translations['new-objects.tab.object-suggestions'], component: <Suggestions /> },
    { name: translations['new-objects.tab.requested'], component: <Requested /> },
  ]

  return (
    <DashboardLayout>
      <div id="new-objects">
        <FullScreenHeader headline={translations['new-objects.headline']} />
        <TabView items={tabItems} />
      </div>
    </DashboardLayout>
  )
}

export default NewObjects
