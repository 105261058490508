import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import { getUUID } from '../../../../../../utility'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const ObjectCondition = ({ payload }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const newState = { ...purchaseProfile, objectCondition: { ...purchaseProfile.objectCondition, [key]: value } }
    // dispatch(setNewPurchaseProfile(newState))
    dispatch(setLocal(props.PURCHASE_PROFILE, newState))
  }

  console.log(purchaseProfile.objectCondition)
  const getValue = (key) => (purchaseProfile.objectCondition?.[key] ? purchaseProfile.objectCondition?.[key] : '')
  console.log(settings.getGroup(payload))
  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.object-condition']}</div>
        <div className="values">
          {settings.getGroup(payload).map((option) => (
            <RadioGroup
              inline={true}
              key={getUUID()}
              name={translations[option.translationKey]}
              value={getValue(option.key)}
              onChange={update(option.key)}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              showEmptyOption={true}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ObjectCondition
