import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const PlotPrice = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (value) => {
    const updatePurchaseProfile = { ...newObject, plotPrice: value }
    // dispatch(setNewObject(updatePurchaseProfile))
    dispatch(setLocal(props.OBJECT, updatePurchaseProfile))
  }

  if (!newObject || !translations) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['plotPrice.title']}</div>
          <div className="values">
            <Input type="customNumber" value={newObject.plotPrice || ''} symbol={translations['currency.string']} onChange={update} placeholder={'Grundstückspreis'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlotPrice
