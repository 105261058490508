import './Item.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import Input from '../../../UI/Input/Input'

const Item = ({ translation, selectedLang }) => {
  const [value, setValue] = useState(translation.value)
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [message, setMessage] = useState(null)

  const update = async () => {
    setMessage(null)
    if (value.length > 0) {
      const { status } = await fetch('/admin/translations/upsert', { token, lang: selectedLang, key: translation.key, value })
      if (status) {
        setMessage(translations['admin.translations.update-message.success'])
      } else {
        setMessage(translations['admin.translations.update-message.failure'])
      }
    } else {
      setMessage(translations['admin.translations.update-message.failure'])
    }
  }

  return (
    <div className="translation-item">
      <div className="key">
        {translations['admin.translations.key']}: {translation.key}
      </div>
      <div className="property">
        <Input value={value} onChange={setValue} placeholder={translations['admin.translations.value']} />
      </div>
      <div className="update" onClick={update}>
        {translations['admin.translations.update']}
      </div>
      {message && <div className="message">{message}</div>}
    </div>
  )
}

export default Item
