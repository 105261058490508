import { useSelector } from 'react-redux'
import Button from '../../../../../UI/Button/Button'
import TenantListModal from './TenantListModal'
import { useState } from 'react'

const TenantList = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)

  const [showModal, setShowModal] = useState(false)

  const handleClose = () => setShowModal(false)

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="values">
          <div className="label">{translations['object.tenantlist.title']}</div>
        </div>
      </div>
      <div className="group">
        <div className="values">
          <Button text={translations['object.tenantlist.create']} onClick={() => setShowModal(true)} />
          <TenantListModal open={showModal} handleClose={handleClose} />
        </div>
      </div>
    </div>
  )
}

export default TenantList
