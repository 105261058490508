import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fileUploadToBackend } from '../../../../fetch'

const ExportImport = () => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [selectedCSV, setSelectedCSV] = useState([null])
  const [CSVUploadMessage, setCSVUploadMessage] = useState(null)
  const [selectedPostcodeLatLngMappingCSV, setSelectedPostcodeLatLngMappingCSV] = useState([])
  const [CSVPostcodeLatLngMappingUploadMessage, setCSVPostcodeLatLngMappingUploadMessage] = useState(null)

  const csvAttributes = [
    'Land',
    'PLZ',
    'Stadt',
    'ABCD',
    'Einwohner',
    'NUTS Level 1',
    'NUTS Level 2',
    'NUTS Level 3',
    'Bundesland (Level 1)',
    'Bundeslandtyp',
    'Norddeutschland',
    'Ostdeutschland',
    'Süddeutschland',
    'Westdeutschland',
    'Regierungsbezirke (Level 2)',
    'Kreise und kreisfreie Städte (Level 3)',
    'Metropolregion (nach Stadt)',
    'Metropolregion (nach Landkreis)',
    'Demographietyp',
  ]
  const csvPostcodeLatLngMappingAttributes = ['Postleitzahl', 'Lng (Längengrad)', 'Lat (Breitengrad)']

  const uploadCSV = async () => {
    setCSVUploadMessage(null)
    if (selectedCSV.length === 1) {
      const status = await fileUploadToBackend(token, '/admin/geo-data/import-by-csv', selectedCSV[0])
      setCSVUploadMessage(status ? translations['admin.geo-data.import.csv.upload.message.success'] : translations['admin.geo-data.import.csv.upload.message.error'])
    }
  }

  const uploadPostcodeLatLngMappingCSV = async () => {
    setCSVPostcodeLatLngMappingUploadMessage(null)
    if (selectedPostcodeLatLngMappingCSV.length === 1) {
      const status = await fileUploadToBackend(token, '/admin/geo-data/import-postcode-lat-lng-mapping', selectedPostcodeLatLngMappingCSV[0])
      setCSVPostcodeLatLngMappingUploadMessage(
        status ? translations['admin.geo-data.import.postcode-lat-lng-mapping.upload.message.success'] : translations['admin.geo-data.import.postcode-lat-lng-mapping.upload.message.error'],
      )
    }
  }

  useEffect(() => {
    setCSVUploadMessage(null)
    setCSVPostcodeLatLngMappingUploadMessage(null)
  }, [selectedCSV, setSelectedPostcodeLatLngMappingCSV])

  return (
    <div className="import-export">
      <div className="import-csv-wrapper">
        <input type="file" id="file-selection-csv" className="upload-input" accept="text/csv" onChange={(e) => setSelectedCSV(e.target.files)} />
        <label htmlFor="file-selection-csv">{translations['admin.geo-data.import.by-csv']}</label>
        {selectedCSV[0] && (
          <div className="trigger-upload" onClick={uploadCSV}>
            {selectedCSV[0].name}
            {translations['admin.geo-data.import.csv.upload-button-suffix']}
          </div>
        )}
        {CSVUploadMessage && <div className="message">{CSVUploadMessage}</div>}
      </div>
      <div className="import-csv-wrapper">
        <input type="file" id="file-selection-postcode-lat-lng-mapping-csv" className="upload-input" accept="text/csv" onChange={(e) => setSelectedPostcodeLatLngMappingCSV(e.target.files)} />
        <label htmlFor="file-selection-postcode-lat-lng-mapping-csv">{translations['admin.geo-data.import.postcode-lat-lng-mapping']}</label>
        {selectedPostcodeLatLngMappingCSV[0] && (
          <div className="trigger-upload" onClick={uploadPostcodeLatLngMappingCSV}>
            {selectedPostcodeLatLngMappingCSV[0].name}
            {translations['admin.geo-data.import.postcode-lat-lng-mapping.upload-button-suffix']}
          </div>
        )}
        {CSVPostcodeLatLngMappingUploadMessage && <div className="message">{CSVPostcodeLatLngMappingUploadMessage}</div>}
      </div>
      <div className="explain">
        <div className="label">{translations['admin.geo-data.import.by-csv.explain.label']}</div>
        <div className="attributes">{csvAttributes.join(', ')}</div>
      </div>
      <div className="explain">
        <div className="label">{translations['admin.geo-data.import.postcode-lat-lng-mapping.explain.label']}</div>
        <div className="attributes">{csvPostcodeLatLngMappingAttributes.join(', ')}</div>
      </div>
    </div>
  )
}

export default ExportImport
