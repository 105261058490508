const conditions = () => {
  return [
    { projectType: { $in: ['object.project-type.options.project-development', 'object.project-type.options.plot'] } },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.wohnen': 'object.construction-method.living.options',
          'object.asset-class.options.handel-retail': 'object.construction-method.living.options',
          'object.asset-class.options.hotel': 'object.construction-method.living.options',
          'object.asset-class.options.healthcare-medical': 'object.construction-method.living.options',
          'object.asset-class.options.buero': 'object.construction-method.living.options',
          'object.asset-class.options.logistik-industrie': 'object.construction-method.living.options',
          'object.asset-class.options.micro-und-serviced-living': 'object.construction-method.living.options',
          'object.asset-class.options.freies-ankaufsprofil': 'object.construction-method.living.options',
        },
      },
    },
  ]
}

export default conditions
