import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const Beds = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...purchaseProfile, beds: { ...purchaseProfile.beds, [key]: value } }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.beds.from']}</div>
        <div className="values">
          <Input type="number" value={purchaseProfile.beds.from} onChange={update('from')} placeholder={translations['my-objects.pipeline.yield-and-object-criteria.beds.from']} />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.beds.until']}</div>
        <div className="values">
          <div className="values">
            <Input type="number" value={purchaseProfile.beds.until} onChange={update('until')} placeholder={translations['my-objects.pipeline.yield-and-object-criteria.beds.until']} />
          </div>
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.beds.ez-quota.from']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.beds.ezQuotaFrom}
            onChange={update('ezQuotaFrom')}
            placeholder={translations['my-objects.pipeline.yield-and-object-criteria.beds.ez-quota.from']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.beds.ez-quota.until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              value={purchaseProfile.beds.ezQuotaUntil}
              onChange={update('ezQuotaUntil')}
              placeholder={translations['my-objects.pipeline.yield-and-object-criteria.beds.ez-quota.until']}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Beds
