import { useDispatch, useSelector } from 'react-redux'

import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'

const ProjectType = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (newArr) => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, projectType: newArr }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, projectType: newArr }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.project-type']}</div>
        <div className="values">
          <CheckboxGroup
            options={settings.getGroup('object.project-type.options').map((option) => ({ label: translations[option.key], value: option.key }))}
            values={purchaseProfile.projectType}
            onChange={update}
            itemsPerRow={3}
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectType
