import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../../../UI/Dropdown/Dropdown'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const AssetClass = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (value) => {
    // dispatch(setNewObject({ ...newObject, assetClass: value }))
    dispatch(setLocal(props.OBJECT, { ...newObject, assetClass: value }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.asset-class']}</div>
        <div className="values">
          <Dropdown
            name={translations['my-objects.pipeline.assets-class.asset-class']}
            value={newObject.assetClass}
            onChange={update}
            options={settings.getGroup('object.asset-class.options').map((option) => ({
              key: option.key,
              value: translations[option.translationKey],
            }))}
          />
        </div>
      </div>
    </div>
  )
}

export default AssetClass
