import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { setNewObject } from '../../../../../action/newObject'
import administrationConditions from './config/administration'
import assetClassConditions from './config/assetClass'
import dealTypeConditions from './config/dealType'
import financingConditions from './config/financing'
import floorSpaceConditions from './config/floorSpace'
import hotelAdditionalConditions from './config/hotelAdditional'
import hotelRatingConditions from './config/hotelRating'
import hotelRoomsConditions from './config/hotelRooms'
import investmentConditions from './config/investment'
import investmentTypeConditions from './config/investmentType'
import investmentVolumeConditions from './config/investmentVolume'
import locationConditions from './config/location'
import measuresConditions from './config/measures'
import mezzanineLoanConditions from './config/mezzanineLoan'
import numberOfPitchesConditions from './config/numberOfPitches'
import objectTypeConditions from './config/objectType'
import operatingCostConditions from './config/operatingCost'
import operatorContractConditions from './config/operatorContract'
import otherConditions from './config/other'
import plotPriceConditions from './config/plotPrice'
import portfolioInvestmentVolumeConditions from './config/portfolioInvestmentVolume'
import projectTypeConditions from './config/projectType'
import rentalIncomeConditions from './config/rentalIncome'
import salesProcessConditions from './config/salesProcess'
import searchedConditions from './config/searched'
import sellingPriceConditions from './config/sellingPrice'
import seniorLoanConditions from './config/seniorLoan'
import storageConditions from './config/storage'
import totalInvestmentConditions from './config/totalInvestment'
import typeOfFinancingConditions from './config/typeOfFinancing'
import typeOfUsageConditions from './config/typeOfUsage'
import wholeLoanConditions from './config/wholeLoan'
import objectDataConditions from './config/objectData'
import portfolioConditions from './config/portfolio'
import yieldCriteriaConditions from './config/yieldCriteria'
import moreConditions from './config/more'
import photosConditions from './config/photos'
import freeTagsConditions from './config/freeTags'
import tenantListConditions from './config/tenantList'

import defaultState from '../../../../../reducer/defaultState'
import { setLocal } from '../../../../../redux/action/local'
import props from '../../../../../redux/props'

const Condition = ({ defaultStateName, Component, widthInPercent }) => {
  // remove all purchaseProfile depemendencies from
  // NewObjects/Pipeline
  // NewObjects/Condition/Condition
  // NewObjects/Elements/Elements.js
  // NewObjects/Elements/Elements.scss
  // NewObjects/Elements/SubComponents/Sample.js

  const newObject = useSelector((state) => state.OBJECT)
  const translations = useSelector((state) => state.translations)
  const [visiblePayload, setVisiblePayload] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const evaluate = (object, conditionSet) => {
      return conditionSet.reduce((acc, set) => {
        // multiple conditionSets are treated with "and"
        if (acc === false) return false
        const key = Object.keys(set)[0]
        const valueType = typeof set[key]
        if (valueType === 'boolean') {
          // check if the boolean is equal to the expected one
          return set[key] === object[key]
        } else if (valueType === 'string') {
          if (Array.isArray(object[key])) {
            // check if the condition string is included within the object's array
            return object[key].includes(set[key])
          } else {
            // check if the string is equal to the expected one
            return object[key] === set[key]
          }
        } else if (valueType === 'object') {
          // check if value is NOT the expected value (!== undefined required because '' is also false)
          if (set[key]['$ne'] !== undefined) {
            // if the object value is an array
            if (Array.isArray(object[key])) {
              // if the object array is zero the $ne value is always false
              if (object[key].length === 0) return false
              // non of the object array values must match the value given by $ne
              return object[key].reduce((acc, value) => {
                if (!acc) return acc
                return value !== set[key]['$ne']
              }, true)
            } else {
              // if the $ne value and the object value is a string simple check if they're not matching
              return object[key] !== set[key]['$ne']
            }
            // check if value is included within an object or an array (!== undefined required because '' is also false)
          } else if (set[key]['$in'] !== undefined) {
            if (typeof set[key]['$in'] === 'object' && !Array.isArray(set[key]['$in'])) {
              // returns matching value if the value is included within the object
              return set[key]['$in'][object[key]]
            } else if (Array.isArray(set[key]['$in'])) {
              // translate a single item to an array for the following comparision
              const valueArray = Array.isArray(object[key]) ? object[key] : [object[key]]
              // returns true/false if at least one array item is included within the condition array
              return valueArray.reduce((acc, value) => {
                if (acc === true) return acc
                return set[key]['$in'].includes(value)
              }, false)
            }
          } else {
            // check if subobject value is matching
            return evaluate(object[key], [set[key]])
          }
        }
        return false
      }, true)
    }

    if (translations && defaultStateName && newObject) {
      const conditionMapping = {
        hotelRating: hotelRatingConditions,
        administration: administrationConditions,
        assetClass: assetClassConditions,
        dealType: dealTypeConditions,
        financing: financingConditions,
        floorSpace: floorSpaceConditions,
        hotelAdditional: hotelAdditionalConditions,
        hotelRooms: hotelRoomsConditions,
        investment: investmentConditions,
        investmentType: investmentTypeConditions,
        investmentVolume: investmentVolumeConditions,
        location: locationConditions,
        measures: measuresConditions,
        mezzanineLoan: mezzanineLoanConditions,
        numberOfPitches: numberOfPitchesConditions,
        objectType: objectTypeConditions,
        operatingCost: operatingCostConditions,
        operatorContract: operatorContractConditions,
        other: otherConditions,
        plotPrice: plotPriceConditions,
        portfolioInvestmentVolume: portfolioInvestmentVolumeConditions,
        portfolio: portfolioConditions,
        projectType: projectTypeConditions,
        rentalIncome: rentalIncomeConditions,
        salesProcess: salesProcessConditions,
        searched: searchedConditions,
        sellingPrice: sellingPriceConditions,
        seniorLoan: seniorLoanConditions,
        storage: storageConditions,
        totalInvestment: totalInvestmentConditions,
        typeOfFinancing: typeOfFinancingConditions,
        typeOfUsage: typeOfUsageConditions,
        wholeLoan: wholeLoanConditions,
        objectData: objectDataConditions,
        yieldCriteria: yieldCriteriaConditions,
        more: moreConditions,
        photos: photosConditions,
        freeTags: freeTagsConditions,
        tenantList: tenantListConditions,
      }
      if (conditionMapping[defaultStateName]) {
        const conditionsDoMatch = evaluate(newObject, conditionMapping[defaultStateName](translations))
        if (!conditionsDoMatch && newObject[defaultStateName] !== defaultState.newObject[defaultStateName]) {
          dispatch(setLocal(props.OBJECT, { ...newObject, [defaultStateName]: defaultState.newObject[defaultStateName] }))
        }

        setVisiblePayload(conditionsDoMatch)
      } else {
        setVisiblePayload(true)
      }
    }
  }, [defaultStateName, newObject, translations, dispatch])

  if (!visiblePayload) return <></>
  return <Component payload={visiblePayload} widthInPercent={widthInPercent} />
}

export default Condition
