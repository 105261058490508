import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import Textarea from '../../../../../UI/Textarea/Textarea'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const Notes = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, notes: { ...purchaseProfile.notes, [key]: value } }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, notes: { ...purchaseProfile.notes, [key]: value } }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.navigation.extendet-criteria.notes']}</div>
        <div className="values">
          <Input value={purchaseProfile.notes.url} onChange={update('url')} placeholder={translations['my-objects.pipeline.navigation.extendet-criteria.notes.url']} />
          <Textarea value={purchaseProfile.notes.text} onChange={update('text')} placeholder={translations['my-objects.pipeline.navigation.extendet-criteria.notes.text']} />
        </div>
      </div>
    </div>
  )
}

export default Notes
