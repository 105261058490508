const translation = [
  { key: 'object.rentalIncome', value: 'Mieteinnahmen' },
  { key: 'object.rentalIncome.pa', value: 'Wohnen Mieteinnahmen p.a.' },
  { key: 'object.rentalIncome.pm', value: 'Wohnen Mieteinnahmen p.m.' },
  { key: 'object.rentalIncome.commercialPa', value: 'Gewerbe Mieteinnahmen p.a.' },
  { key: 'object.rentalIncome.commercialPm', value: 'Gewerbe Mieteinnahmen p.m.' },
  { key: 'object.rentalIncome.averageResidentialRentalIncomePerSqm', value: 'Durchschnittliche Wohnmieteinnahmen pro qm' },
  { key: 'object.rentalIncome.commercialAverageResidentialRentalIncomePerSqm', value: 'Durchschnittliche Gewerbemieteinnahmen pro qm' },
  { key: 'object.rentalIncome.totalPa', value: 'Gesamtmieteinnahmen p.a.' },
  { key: 'object.rentalIncome.totalPm', value: 'Gesamtmieteinnahmen p.m.  (wenn Wohn- und Geschäftshaus)' },

  { key: 'remainingtime.symbol', value: 'Restlaufzeit' },
  { key: 'min.symbol', value: 'min.' },
  { key: 'radiogroup.default.options.no', value: 'Nein' },
  { key: 'percent.share', value: 'Anteil' },
  { key: 'radiogroup.default.options.yes', value: 'Ja' },
  { key: 'radiogroup.empty.option', value: 'n. A.' },
  { key: 'purchaseprofile.remove-request-modal.btn-delete', value: 'Löschen' },
  { key: 'purchaseprofile.remove-request-modal.btn-abort', value: 'Abbrechen' },
  { key: 'my-objects.pipeline.location-criteria.abcd-selection-required', value: 'ABCD *' },
  { key: 'purchaseprofile.remove-request-modal.text', value: 'Sind Sie sicher, dass Sie dieses Ankaufsprofil löschen wollen?' },
  { key: 'my-objects.pipeline.location-criteria.min-population-required', value: 'Min. Einwohnerzahl *' },
  { key: 'location.more-criterions', value: 'Weitere Kriterien' },
  { key: 'location.title.city', value: 'Städte' },
  { key: 'plotPrice.title', value: 'Grundstückspreis' },
  { key: 'totalInvestment.title', value: 'Gesamtinvestitionskosten' },
  { key: 'location.title.state', value: 'Regionen' },
  { key: 'location.title.country', value: 'Bundesweit' },
  { key: 'add-cta', value: 'Hinzufügen' },
  { key: 'admin.geo-data.import.csv.upload-button-suffix', value: ' hochladen' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.save', value: 'Speichern' },
  { key: 'admin.geo-data.import.csv.upload.message.error', value: 'Bei der Verarbeitung der CSV ist ein Problem aufgetreten.' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping.upload-button-suffix', value: ' hochladen' },
  { key: 'admin.geo-data.import.csv.upload.message.success', value: 'Erfolgreich hochgeladen und verarbeitet.' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping.upload.message.success', value: 'Erfolgreich hochgeladen und verarbeitet.' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping.upload.message.error', value: 'Bei der Verarbeitung der CSV ist ein Problem aufgetreten.' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping.explain.label', value: 'CSV Attribute für einen Postleitzahl-Lat/Lng-Mapping-Import:' },
  { key: 'admin.geo-data.import.by-csv.explain.label', value: 'CSV Attribute für einen Standort-Import:' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping.process', value: 'Verarbeiten' },
  { key: 'admin.geo-data.import.by-csv.close', value: 'Schließen' },
  { key: 'admin.geo-data.import.by-csv', value: 'Import mit CSV' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping.close', value: 'Schließen' },
  { key: 'admin.geo-data.import.by-csv.process', value: 'Verarbeiten' },
  { key: 'admin.geo-data.import.close', value: 'Schließen' },
  { key: 'admin.geo-data.import.postcode-lat-lng-mapping', value: 'Import Postleitzahl zu Lat/Lng CSV' },
  { key: 'admin.geo-data.import.process', value: 'Verarbeiten' },
  { key: 'admin.geo-data.import', value: 'Import' },
  { key: 'admin.geo-data.export', value: 'Export' },
  { key: 'admin.geo-data.export.close', value: 'Schließen' },
  { key: 'admin.geo-data.create', value: 'Hinzufügen' },
  { key: 'admin.geo-data.create-message.success', value: 'Einstellung wurde gespeichert!' },
  { key: 'admin.navigation.statistics', value: 'Statistiken' },
  { key: 'admin.settings.application.frontend', value: 'Frontend' },
  { key: 'admin.settings.application', value: 'Anwendung' },
  { key: 'admin.navigation.settings', value: 'Einstellungen' },
  { key: 'admin.settings.application.backend', value: 'Backend' },
  { key: 'admin.settings.key', value: 'Key' },
  { key: 'admin.settings.translationkey', value: 'Übersetzungsschlüssel' },
  { key: 'admin.settings.group', value: 'Gruppe' },
  { key: 'admin.settings.value', value: 'Wert' },
  { key: 'admin.settings.create', value: 'Hinzufügen' },
  { key: 'admin.settings.export', value: 'Export' },
  { key: 'admin.settings.import.process', value: 'Verarbeiten' },
  { key: 'admin.settings.export.close', value: 'Schließen' },
  { key: 'admin.settings.import.close', value: 'Schließen' },
  { key: 'admin.settings.create-message.success', value: 'Einstellung wurde gespeichert!' },
  { key: 'admin.settings.import.translations-as-settings', value: 'Import Translations as Settings' },
  { key: 'admin.settings.import.translations-as-settings.process', value: 'Verarbeiten' },
  { key: 'admin.settings.import', value: 'Import' },
  { key: 'admin.settings.import.translations-as-settings.close', value: 'Schließen' },
  { key: 'admin.settings.import.translations-as-settings.group', value: 'Group' },
  { key: 'admin.settings.create-error.duplicate', value: 'Dieser "Key" existiert bereits.' },
  { key: 'admin.settings.update-message.success', value: 'Gespeichert!' },
  { key: 'admin.settings.create-error.value-missing', value: 'Alle Eingaben abgesehen von "Übersetzungsschlüssel" sind Pflicht.' },
  { key: 'admin.settings.update', value: 'Aktualisieren' },
  { key: 'admin.translations.create-message.success', value: 'Übersetzung erfolgreich gespeichert!' },
  { key: 'admin.settings.update-message.failure', value: 'Fehler!' },
  { key: 'admin.translations.create-error.value-missing', value: 'Alle Eingaben sind Pflicht.' },
  { key: 'admin.translations.create', value: 'Hinzufügen' },
  { key: 'admin.translations.create-error.duplicate', value: 'Dieser "Key" existiert bereits.' },
  { key: 'admin.translations.value', value: 'Wert' },
  { key: 'admin.translations.key', value: 'Key' },
  { key: 'admin.translations.import.close', value: 'Schließen' },
  { key: 'admin.translations.export.close', value: 'Schließen' },
  { key: 'admin.translations.import.translations-from-file.process', value: 'Verarbeiten' },
  { key: 'admin.translations.import.process', value: 'Verarbeiten' },
  { key: 'admin.translations.export', value: 'Export' },
  { key: 'admin.translations.import.translations-from-file.close', value: 'Schließen' },
  { key: 'admin.translations.lang-selector.choose', value: 'Sprache wählen' },
  { key: 'admin.translations.import', value: 'Import' },
  { key: 'login.headline', value: 'Anmelden' },
  { key: 'admin.translations.import.translations-from-file', value: 'Import Translations from File' },
  { key: 'login.password', value: 'Passwort' },
  { key: 'login.button', value: 'Anmelden' },
  { key: 'login.mail', value: 'E-Mail' },
  { key: 'login.missing-inputs', value: 'E-Mail und Password sind Pflichtfelder.' },
  { key: 'login.wrong-credentials', value: 'Falsche E-Mail, Passwort oder Authenticator-Code.' },
  { key: 'login.registration', value: 'Hier Registrieren' },
  { key: 'registration.headline', value: 'Registrieren' },
  { key: 'registration.salutation', value: 'Anrede' },
  { key: 'login.password-lost', value: 'Passwort vergessen?' },
  { key: 'registration.mail', value: 'E-Mail' },
  { key: 'registration.firstname', value: 'Vorname' },
  { key: 'registration.title', value: 'Titel' },
  { key: 'registration.lastname', value: 'Nachname' },
  { key: 'registration.done.text', value: 'Wir haben Ihnen einen Bestätigungslink per E-Mail geschickt.' },
  { key: 'registration.button', value: 'Registrieren' },
  { key: 'registration.password', value: 'Passwort' },
  { key: 'registration.unknown-error', value: 'Ein unbekannter Fehler ist aufgetreten.' },
  { key: 'registration.login', value: 'bereits registriert?' },
  { key: 'registration.missing-inputs', value: 'Bitte überprüfen Sie Ihre Eingaben.' },
  { key: 'password-lost.login', value: 'Zum Login' },
  { key: 'registration.done.login', value: 'Zum Login' },
  { key: 'registration.confirm.unknown-error', value: 'Ein unbekannter Fehler ist aufgetreten.' },
  { key: 'registration.mail-duplicate', value: 'Ein Account mit dieser E-Mail Adresse existiert bereits.' },
  { key: 'password-lost.unknown-error', value: 'Ein unbekannter Fehler ist aufgetreten.' },
  { key: 'registration.confirm.success', value: 'Ihr Account wurde bestätigt.' },
  { key: 'password-lost.headline', value: 'Passwort vergessen?' },
  { key: 'password-lost.explain', value: 'Geben Sie Ihre E-Mail Adresse ein um einen Änderungslink zu erhalten.' },
  { key: 'password-lost.missing-input', value: 'Bitte überprüfen Sie Ihre Eingaben.' },
  { key: 'password-lost.mail', value: 'E-Mail' },
  { key: 'password-lost.button', value: 'E-mail anfordern' },
  { key: 'password-lost.success', value: 'Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts geschickt.' },
  { key: 'password-reset.login', value: 'Zum Login' },
  { key: 'password-reset.unknown-error', value: 'Ein unbekannter Fehler ist aufgetreten.' },
  { key: 'password-reset.missing-input', value: 'Bitte überprüfen Sie Ihre Eingaben.' },
  { key: 'password-reset.headline', value: 'Passwort zurücksetzen' },
  { key: 'layout-dashboard.navigation.admin.title', value: 'Admin' },
  { key: 'password-reset.button', value: 'Passwort zurücksetzen' },
  { key: 'password-reset.password', value: 'Passwort' },
  { key: 'layout-dashboard.navigation.purchase.title', value: 'Einkauf' },
  { key: 'layout-dashboard.navigation.admin.settings', value: 'Dashboard' },
  { key: 'password-reset.success', value: 'Sie haben Ihr Passwort erfolgreich zurückgesetz' },
  { key: 'layout-dashboard.navigation.purchase.new-objects', value: 'Neue Objekte' },
  { key: 'layout-dashboard.navigation.sales.title', value: 'Verkauf' },
  { key: 'password-reset.explain', value: 'Geben Sie Ihr neues Passwort ein.' },
  { key: 'layout-dashboard.navigation.purchase.in-examination', value: 'In Prüfung' },
  { key: 'layout-dashboard.navigation.purchase.declined', value: 'Abgelehnt' },
  { key: 'layout-dashboard.navigation.purchase.bought', value: 'Erworben' },
  { key: 'layout-dashboard.navigation.purchase.purchase-profile', value: 'Ankaufsprofil' },
  { key: 'layout-admin.logo-label', value: 'Admin Panel' },
  { key: 'layout-dashboard.navigation.sales.received-offers', value: 'Erhaltene Angebote' },
  { key: 'layout-admin.navigation.company.title', value: 'Unternehmen' },
  { key: 'layout-admin.navigation.user.list', value: 'Alle Nutzer' },
  { key: 'layout-dashboard.navigation.sales.my-objects', value: 'Meine Objekte' },
  { key: 'layout-admin.navigation.object.title', value: 'Objekte' },
  { key: 'layout-dashboard.navigation.sales.interest-requests', value: 'Interessentenanfragen' },
  { key: 'layout-admin.navigation.user.title', value: 'Benutzer' },
  { key: 'layout-admin.navigation.user.add', value: 'Hinzufügen' },
  { key: 'layout-admin.navigation.buying-profile.title', value: 'Ankausprofile' },
  { key: 'layout-admin.navigation.settings.title', value: 'Einstellungen' },
  { key: 'layout-admin.navigation.settings.global', value: 'System' },
  { key: 'layout-admin.navigation.settings.geo-data', value: 'Geo-Daten' },
  { key: 'layout-admin.navigation.settings.translation', value: 'Übersetzungen' },
  { key: 'settings.header.hint', value: 'Bitte vervollständigen Sie zunächst Ihr Profil' },
  { key: 'settings.header.headline.prefix', value: 'Willkommen ' },
  { key: 'settings.personal-data.title', value: 'Titel' },
  { key: 'settings.personal-data.lastname', value: 'Nachname' },
  { key: 'settings.personal-data.responsibilities.title', value: 'Ich bin zuständig für...' },
  { key: 'settings.personal-data.salutation', value: 'Anrede' },
  { key: 'settings.personal-data.mobile-phone', value: 'Mobil' },
  { key: 'settings.personal-data.headline', value: 'Persönliche Daten' },
  { key: 'settings.personal-data.firstname', value: 'Vorname' },
  { key: 'settings.personal-data.position', value: 'Position' },
  { key: 'settings.personal-data.mail', value: 'E-Mail' },
  { key: 'settings.company-data.name', value: 'Firmenname' },
  { key: 'settings.personal-data.responsibility.other', value: 'Sonstiges' },
  { key: 'settings.company-data.headline', value: 'Unternehmensdaten' },
  { key: 'settings.company-data.street', value: 'Straße' },
  { key: 'settings.personal-data.birthday', value: 'Geburtstag' },
  { key: 'settings.company-data.phone', value: 'Allgemein Telefon' },
  { key: 'settings.personal-data.phone', value: 'Telefon' },
  { key: 'settings.company-data.street-number', value: 'Nr.' },
  { key: 'settings.company-data.mail', value: 'Allgemein E-Mail' },
  { key: 'settings.company-data.street-addition', value: 'Zusatz' },
  { key: 'settings.company-data.corporate-form', value: 'Gesellschaftsform' },
  { key: 'settings.company-data.purchase-mail', value: 'Ankauf E-Mail' },
  { key: 'settings.company-data.website', value: 'Website' },
  { key: 'settings.company-data.registration-number', value: 'HRB' },
  { key: 'settings.company-data.court', value: 'Amtsgericht' },
  { key: 'settings.company-data.postcode', value: 'Postleitzahl' },
  { key: 'settings.save.success', value: 'Ihre Daten wurden erfolgreich gespeichert.' },
  { key: 'settings.company-data.intention.title', value: 'Wir sind...' },
  { key: 'new-object.suggestions.no-results', value: 'Aktuell haben wir keine Vorschläge für Sie.' },
  { key: 'settings.company-data.city', value: 'Stadt' },
  { key: 'new-objects.tab.requested', value: 'Angefragt' },
  { key: 'settings.save-cta', value: 'Speichern' },
  { key: 'new-object.suggestions.error', value: 'Ihre Vorschläge konnten nicht geladen werden.' },
  { key: 'new-objects.headline', value: 'Neue Angebote' },
  { key: 'new-objects.tab.object-suggestions', value: 'Objektvorschläge' },
  { key: 'my-objects.tab.paused-objects', value: 'Pausierte Objekte' },
  { key: 'settings.save.error', value: 'Ihre Daten konnten nicht gespeichert werden.' },
  { key: 'my-objects.headline', value: 'Meine Objekte' },
  { key: 'my-objects.tab.aktive-objects', value: 'Aktive Objekte' },
  { key: 'my-objects.paused.error', value: 'Ihre Objekte konnten nicht geladen werden.' },
  { key: 'my-objects.pipeline.navigation.location-criteria.label', value: 'Standortkriterien' },
  { key: 'my-objects.active.error', value: 'Ihre Objekte konnten nicht geladen werden.' },
  { key: 'my-objects.pipeline.assets-class.asset-class', value: 'Assetklasse auswählen' },
  { key: 'my-objects.pipeline.navigation.asset-class.label', value: 'Assetklasse bestimmen' },
  { key: 'my-objects.pipeline.assets-class.object-type', value: 'Objekttyp' },
  { key: 'my-objects.pipeline.assets-class', value: 'Allgemein' },
  { key: 'my-objects.pipeline.navigation.extended-criteria.label', value: 'Erweiterte Kriterien' },
  { key: 'my-objects.pipeline.assets-class.project-type', value: 'Projektart' },
  { key: 'my-objects.pipeline.assets-class.headline', value: 'Objektprofil' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.from', value: 'Kaufpreis von' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.single', value: 'Einzelinvestments' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.portfolio.yes', value: 'Ja' },
  { key: 'my-objects.pipeline.assets-class.investment-volume', value: 'Investitionsvolumen' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.to', value: 'Kaufpreis bis' },
  { key: 'my-objects.pipeline.assets-class.portfolio-investment-volume.single', value: 'Portfolioinvestments' },
  { key: 'my-objects.pipeline.assets-class.portfolio-investment-volume', value: 'Einzelinvestments' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.total', value: 'Präferiertes Investmentvolumen' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.portfolio.no', value: 'Nein' },
  { key: 'my-objects.pipeline.assets-class.portfolio-investment-volume.to', value: 'Kaufpreis bis' },
  { key: 'my-objects.pipeline.assets-class.portfolio-investment-volume.min-invest-single', value: 'Mindestinvestment pro Einzelobjekt' },
  { key: 'my-objects.pipeline.assets-class.investment-volume.portfolio', value: 'Portfolio gesucht' },
  { key: 'my-objects.pipeline.assets-class.portfolio-investment-volume.total', value: 'Präferiertes Investmentvolumen' },
  { key: 'my-objects.pipeline.assets-class.total-investment.to', value: 'bis' },
  { key: 'my-objects.pipeline.assets-class.total-investment.from', value: 'von' },
  { key: 'my-objects.pipeline.assets-class.type-of-usage', value: 'Art der baulichen Nutzung' },
  { key: 'my-objects.pipeline.assets-class.plot-price.from', value: 'von' },
  { key: 'my-objects.pipeline.assets-class.total-investment', value: 'Gesamtinvestitionskosten' },
  { key: 'my-objects.pipeline.assets-class.portfolio-investment-volume.from', value: 'Kaufpreis von' },
  { key: 'my-objects.pipeline.assets-class.plot-price', value: 'Grundstückspreis pro qm' },
  { key: 'person.title.options.dr', value: 'Dr.' },
  { key: 'person.title.options.prof-dr', value: 'Prof. Dr.' },
  { key: 'my-objects.pipeline.assets-class.plot-price.to', value: 'bis' },
  { key: 'person.salutation.options.man', value: 'Herr' },
  { key: 'person.responsibility.options.sales', value: 'Verkauf' },
  { key: 'person.title.options.prof', value: 'Prof.' },
  { key: 'person.responsibility.options.purchase', value: 'Einkauf' },
  { key: 'company.intention.options.investor', value: 'Investor' },
  { key: 'company.intention.options.bautraeger', value: 'Bauträger' },
  { key: 'person.salutation.options.diverse', value: 'Divers' },
  { key: 'person.salutation.options.woman', value: 'Frau' },
  { key: 'company.intention.options.bank', value: 'Bank' },
  { key: 'company.intention.options.projektentwickler', value: 'Projektentwickler' },
  { key: 'company.intention.options.stiftung', value: 'Stiftung' },
  { key: 'company.intention.options.asset-property-investment-manager', value: 'Asset - / Property - / Investment Manager' },
  { key: 'company.intention.options.pensionskasse', value: 'Pensionskasse' },
  { key: 'company.intention.options.hnwi', value: 'HNWI' },
  { key: 'company.intention.options.family-office', value: 'Family Office' },
  { key: 'company.corporate-form.options.einzelunternehmen', value: 'Einzelunternehmen' },
  { key: 'company.intention.options.verkaufsberater', value: 'Verkaufsberater' },
  { key: 'company.intention.options.stadt-gemeinde', value: 'Stadt / Gemeinde' },
  { key: 'company.corporate-form.options.ohg', value: 'OHG' },
  { key: 'company.corporate-form.options.gbr', value: 'GbR' },
  { key: 'company.corporate-form.options.kg', value: 'KG' },
  { key: 'company.intention.options.privatperson', value: 'Privatperson' },
  { key: 'company.corporate-form.options.partg', value: 'PartG' },
  { key: 'company.corporate-form.options.gmbh', value: 'GmbH' },
  { key: 'company.corporate-form.options.ug-haftungsbeschraenkt', value: 'UG (haftungsbeschränkt)' },
  { key: 'company.corporate-form.options.gmbh-and-co-kg', value: 'GmbH & Co. KG' },
  { key: 'company.corporate-form.options.ev', value: 'e.V.' },
  { key: 'company.corporate-form.options.kg-aa', value: 'KG aA' },
  { key: 'company.corporate-form.options.treuhaenderische-stiftung', value: 'treuhänderische Stiftung' },
  { key: 'company.corporate-form.options.ag', value: 'AG' },
  { key: 'company.corporate-form.options.partg-mbb', value: 'PartG mbB' },
  { key: 'company.corporate-form.options.anstalt-d-oeffentl-rechts', value: 'Anstalt d. Öffentl. Rechts' },
  { key: 'company.corporate-form.options.sarl', value: 'SARL' },
  { key: 'company.corporate-form.options.koerperschaft-d-oeffentl-rechts', value: 'Körperschaft d. Öffentl. Rechts' },
  { key: 'company.corporate-form.options.eg', value: 'eG' },
  { key: 'company.corporate-form.options.se', value: 'SE' },
  { key: 'company.corporate-form.options.sa', value: 'S.A.' },
  { key: 'company.corporate-form.options.rechtsfaehige-stiftung', value: 'rechtsfähige Stiftung' },
  { key: 'company.corporate-form.options.scs', value: 'SCS' },
  { key: 'company.corporate-form.options.seca', value: 'SECA' },
  { key: 'company.corporate-form.options.secs', value: 'SECS' },
  { key: 'company.corporate-form.options.ltd', value: 'Ltd.' },
  { key: 'company.corporate-form.options.scsp', value: 'SCSp' },
  { key: 'object.asset-class.options.buero', value: 'Büro' },
  { key: 'object.asset-class.options.logistik-industrie', value: 'Logistik / Industrie' },
  { key: 'object.asset-class.options.hotel', value: 'Hotel' },
  { key: 'object.asset-class.options.wohnen', value: 'Wohnen' },
  { key: 'company.corporate-form.options.sarl-s', value: 'SARL-S' },
  { key: 'object.asset-class.options.kapitalgeber', value: 'Kapitalgeber' },
  { key: 'object.asset-class.options.healthcare-medical', value: 'Healthcare / Medical' },
  { key: 'object.asset-class.options.handel-retail', value: 'Handel / Retail' },
  { key: 'object.object-type.living.options.wohn-und-geschaeftshaus', value: 'Wohn- und Geschäftshaus' },
  { key: 'object.project-type.options.stock', value: 'Bestand' },
  { key: 'object.project-type.options.plot', value: 'Grundstück' },
  { key: 'object.asset-class.options.micro-und-serviced-living', value: 'Micro- und Serviced Living' },
  { key: 'object.object-type.living.options.mehrfamilienhaus', value: 'Mehrfamilienhaus' },
  { key: 'object.asset-class.options.freies-ankaufsprofil', value: 'Freies Ankaufsprofil' },
  { key: 'object.object-type.living.options.quartiersentwicklungen', value: 'Quartiersentwicklungen' },
  { key: 'object.object-type.living.options.geschosswohnungsbau', value: 'Geschosswohnungsbau' },
  { key: 'object.project-type.options.project-development', value: 'Projektentwicklung' },
  { key: 'object.object-type.commerce-and-retail.options.high-street-retail', value: 'High Street Retail' },
  { key: 'object.object-type.commerce-and-retail.options.fachmaerkte', value: 'Fachmärkte' },
  { key: 'object.object-type.commerce-and-retail.options.shoppingzentren', value: 'Shoppingzentren' },
  { key: 'object.object-type.living.options.wohnsiedlung', value: 'Wohnsiedlung' },
  { key: 'object.object-type.commerce-and-retail.options.fachmarktzentren', value: 'Fachmarktzentren' },
  { key: 'object.object-type.hotel.options.apart-apartmenthotel', value: 'Apart- / Apartmenthotel' },
  { key: 'object.object-type.hotel.options.all-suite-hotel', value: 'All-Suite-Hotel' },
  { key: 'object.object-type.commerce-and-retail.options.einkaufsparks', value: 'Einkaufsparks' },
  { key: 'object.object-type.commerce-and-retail.options.baumaerkte', value: 'Baumärkte' },
  { key: 'object.object-type.commerce-and-retail.options.lebensmittelmaerkte', value: 'Lebensmittelmärkte' },
  { key: 'object.object-type.hotel.options.hotel', value: 'Hotel' },
  { key: 'object.object-type.hotel.options.gasthof', value: 'Gasthof' },
  { key: 'object.object-type.hotel.options.boarding-apartmenthaus', value: 'Boarding- / Apartmenthaus' },
  { key: 'object.object-type.hotel.options.hotel-garni', value: 'Hotel garni' },
  { key: 'object.object-type.hotel.options.jugendherberge', value: 'Jugendherberge' },
  { key: 'object.object-type.hotel.options.gaestehaus', value: 'Gästehaus' },
  { key: 'object.object-type.logistic.options.distributionshalle', value: 'Distributionshalle' },
  { key: 'object.object-type.hotel.options.pension', value: 'Pension' },
  { key: 'object.object-type.hotel.options.motel', value: 'Motel' },
  { key: 'object.object-type.logistic.options.hochregallager', value: 'Hochregallager' },
  { key: 'object.object-type.logistic.options.umschlaghalle', value: 'Umschlaghalle' },
  { key: 'object.object-type.logistic.options.lagerhalle', value: 'Lagerhalle' },
  { key: 'object.object-type.healthcare.options.tagespflege', value: 'Tagespflege' },
  { key: 'object.object-type.healthcare.options.pflege', value: 'Pflege' },
  { key: 'object.object-type.healthcare.options.aerztehaus', value: 'Ärztehaus' },
  { key: 'object.object-type.micro-and-serviced-living.options.business-apartments', value: 'Business Apartments' },
  { key: 'object.object-type.logistic.options.speziallager', value: 'Speziallager' },
  { key: 'object.object-type.healthcare.options.behinderteneinrichtung', value: 'Behinderteneinrichtung' },
  { key: 'object.object-type.healthcare.options.betreutes-wohnen', value: 'Betreutes Wohnen' },
  { key: 'object.object-type.micro-and-serviced-living.options.studenten-apartments', value: 'Studenten Apartments' },
  { key: 'object.object-type.healthcare.options.ambulante-pflege', value: 'Ambulante Pflege' },
  { key: 'object.object-type.free-selection.options.parkhaeuser', value: 'Parkhäuser' },
  { key: 'object.object-type.free-selection.options.rastplaetze', value: 'Rastplätze' },
  { key: 'object.object-type.micro-and-serviced-living.options.mikroapartments', value: 'Mikroapartments' },
  { key: 'object.object-type.free-selection.options.casinos-spielhallen', value: 'Casinos / Spielhallen' },
  { key: 'object.object-type.free-selection.options.burger king', value: 'Burger King' },
  { key: 'object.object-type.free-selection.options.mcdonalds', value: 'McDonalds' },
  { key: 'object.object-type.free-selection.options.kindergaerten', value: 'Kindergärten' },
  { key: 'object.object-type.free-selection.options.burgen-schloesser', value: 'Burgen / Schlösser' },
  { key: 'object.object-type.free-selection.options.labore', value: 'Labore' },
  { key: 'object.object-type.free-selection.options.bahnhoefe', value: 'Bahnhöfe' },
  { key: 'object.object-type.free-selection.options.staedtische-einrichtungen-beheorden', value: 'Städtische Einri.free-selectionchtungen (Behörden)' },
  { key: 'object.object-type.free-selection.options.freizeitparks', value: 'Freizeitparks' },
  { key: 'object.object-type.free-selection.options.weingueter', value: 'Weingüter' },
  { key: 'object.object-type.free-selection.options.flughafengebaeude', value: 'Flughafengebäude' },
  { key: 'object.object-type.free-selection.options.raststaetten', value: 'Raststätten' },
  { key: 'object.object-type.free-selection.options.infrastruktur', value: 'Infrastruktur' },
  { key: 'object.object-type.free-selection.options.sporteinrichtungen', value: 'Sporteinrichtungen' },
  { key: 'object.object-type.free-selection.options.strassen', value: 'Straßen' },
  { key: 'object.object-type.free-selection.options.kraftwerke', value: 'Kraftwerke' },
  { key: 'object.object-type.free-selection.options.ferienwohnungen-ferienanlagen', value: 'Ferienwohnungen (Ferienanlagen)' },
  { key: 'object.object-type.free-selection.options.autowaschanlagen', value: 'Autowaschanlagen' },
  { key: 'object.object-type.free-selection.options.autorennstrecken', value: 'Autorennstrecken' },
  { key: 'object.type-of-usage.options.kleinsiedlungsgebiet-ws', value: 'Kleinsiedlungsgebiet (WS)' },
  { key: 'object.type-of-usage.options.reines-wohngebiet-wr', value: 'Reines Wohngebiet (WR)' },
  { key: 'object.type-of-usage.options.allgemeines-wohngebiet-wa', value: 'Allgemeines Wohngebiet (WA)' },
  { key: 'object.type-of-usage.options.besonderes-wohngebiet-wb', value: 'Besonderes Wohngebiet (WB)' },
  { key: 'object.type-of-usage.options.mischgebiet-mi', value: 'Mischgebiet (MI)' },
  { key: 'object.type-of-usage.options.kerngebiet-mk', value: 'Kerngebiet (MK)' },
  { key: 'object.type-of-usage.options.gewerbegebiet-ge', value: 'Gewerbegebiet (GE)' },
  { key: 'object.type-of-usage.options.dorfgebiet-md', value: 'Dorfgebiet (MD)' },
  { key: 'object.type-of-usage.sub-options.logistik-industrie', value: 'Logistik / Industrie' },
  { key: 'object.type-of-usage.sub-options.wohnen', value: 'Wohnen' },
  { key: 'object.type-of-usage.sub-options.handel-retail', value: 'Handel / Retail' },
  { key: 'object.type-of-usage.options.industriegebiet-gi', value: 'Industriegebiet (GI)' },
  { key: 'object.type-of-usage.sub-options.buero-gewerbe', value: 'Büro / Gewerbe' },
  { key: 'currency.string', value: ' EUR' },
  { key: 'currency.symbol', value: ' €' },
  { key: 'object.type-of-usage.sub-options.micro-und-serviced-living', value: 'Micro- und Serviced Living' },
  { key: 'object.type-of-usage.sub-options.hotel', value: 'Hotel' },
  { key: 'dropdown.empty-selection', value: '-' },
  { key: 'object.type-of-usage.sub-options.healthcare', value: 'Healthcare' },
  { key: 'object-detail.tabs.object-profile', value: 'Objektprofil' },
  { key: 'object-detail.tabs.storage', value: 'Datenraum' },
  { key: 'object-detail.tabs.q-and-a', value: 'Q & A' },
  { key: 'object-detail.tabs.chat', value: 'Nachrichten' },
  { key: 'chat.messagestab.conversation.messageplaceholder', value: 'Schreiben Sie eine Nachricht...' },
  { key: 'chat.messagestab.conversation.attachments.upload-label', value: 'Dateien werden hochgeladen... ' },
  { key: 'chat.messagestab.room-list.search', value: 'Suchen' },
  { key: 'storage.breakcrumbs.home', value: 'Home' },
  { key: 'chat.headline', value: 'Nachrichten' },
  { key: 'storage.folder.options.accounting', value: 'Buchhaltung' },
  { key: 'storage.folder.options.building-regulations', value: 'Bauordnungsrecht' },
  { key: 'storage.folder.options.plot', value: 'Grundstück' },
  { key: 'storage.folder.options.property', value: 'Eigentum' },
  { key: 'storage.folder.options.introduction', value: 'Einleitung' },
  { key: 'storage.folder.options.building-planning-law', value: 'Bauplanungsrecht' },
  { key: 'storage.folder.options.management', value: 'Bewirtschaftung' },
  { key: 'storage.folder.options.project-cost-estimate', value: 'Projektkostenschätzung' },
  { key: 'storage.folder.options.financing', value: 'Finanzierung' },
  { key: 'storage.folder.options.commercial-documents', value: 'Kaufmännische Unterlagen' },
  { key: 'storage.document-type.options.building-regulations.other-permits', value: 'Andere Genehmigungen' },
  { key: 'storage.folder.options.building', value: 'Gebäude' },
  { key: 'storage.folder.options.renter', value: 'Mieter' },
  { key: 'storage.document-type.options.building-planning-law.development-plan', value: 'Bebauungsplan' },
  { key: 'storage.folder.options.litigation', value: 'Rechtstreitigkeiten' },
  { key: 'storage.document-type.options.building-planning-law.monument-protection', value: 'Denkmalschutz' },
  { key: 'storage.document-type.options.building-regulations.statics', value: 'Statik' },
  { key: 'storage.document-type.options.building-planning-law.urban-development-contracts', value: 'Städtebauliche Verträge' },
  { key: 'storage.document-type.options.building-regulations.building-permit', value: 'Baugenehmigungen' },
  { key: 'storage.document-type.options.building-planning-law.development', value: 'Erschließung' },
  { key: 'storage.document-type.options.building-planning-law.development-area', value: 'Entwicklungsgebiet/Erhaltungssatzung' },
  { key: 'storage.document-type.options.building-planning-law.official-certificates', value: 'Behördliche Bescheinigungen' },
  { key: 'storage.document-type.options.building-planning-law.zoning-plan', value: 'Flächennutzungsplan' },
  { key: 'storage.document-type.options.management.statement-of-operating-costs', value: 'Betriebskostenaufstellung' },
  { key: 'storage.document-type.options.management.management-contracts', value: 'Bewirtschaftungsverträge' },
  { key: 'storage.document-type.options.management.proof-of-insurance', value: 'Versicherungsnachweise' },
  { key: 'storage.document-type.options.management.insurance-damage', value: 'Versicherungsschaden' },
  { key: 'storage.document-type.options.property.leasehold-contracts', value: 'Erbbaurechtsverträge' },
  { key: 'storage.document-type.options.accounting.payment-order', value: 'Mahnbescheid' },
  { key: 'storage.document-type.options.accounting.incoming-invoice', value: 'Eingangsrechnung' },
  { key: 'storage.document-type.options.accounting.outgoing-invoice', value: 'Ausgangsrechnung' },
  { key: 'storage.document-type.options.property.land-purchase-agreement', value: 'Grundstückskaufvertrag' },
  { key: 'storage.document-type.options.property.land-register-extract', value: 'Grundbuchauszug' },
  { key: 'storage.document-type.options.property.neighborhood-contracts', value: 'Nachbarschaftsverträge' },
  { key: 'storage.document-type.options.accounting.warning', value: 'Mahnung' },
  { key: 'storage.document-type.options.property.commercial-register-extract', value: 'Handelsregisterauszug' },
  { key: 'storage.document-type.options.introduction.photo', value: 'Foto' },
  { key: 'storage.document-type.options.introduction.object-description', value: 'Objektbeschreibung' },
  { key: 'storage.document-type.options.property.part-ownership', value: 'Teileigentum' },
  { key: 'storage.document-type.options.financing.balance-confirmation', value: 'Saldenbestätigung' },
  { key: 'storage.document-type.options.financing.general-correspondence', value: 'Allgemeine Korrespondenz' },
  { key: 'storage.document-type.options.building.warranty-mirror', value: 'Gewährleistungsspiegel' },
  { key: 'storage.document-type.options.building.building-description', value: 'Baubeschreibung' },
  { key: 'storage.document-type.options.building.trade', value: 'Gewerk' },
  { key: 'storage.document-type.options.building.layout', value: 'Grundriss' },
  { key: 'storage.document-type.options.financing.loan-agreement', value: 'Kreditvertrag' },
  { key: 'storage.document-type.options.building.sustainability', value: 'Nachhaltigkeit (inkl. Energieausweis)' },
  { key: 'storage.document-type.options.building.views', value: 'Ansichten' },
  { key: 'storage.document-type.options.building.ventilation', value: 'Lüftung' },
  { key: 'storage.document-type.options.building.maintenance-protocols-revision-documents-operating-instructions', value: 'Wartungsprotokolle / Revisionsunterlagen / Bedienungsanleitungen' },
  { key: 'storage.document-type.options.building.sanitary', value: 'Sanitär' },
  { key: 'storage.document-type.options.building.electric', value: 'Elektro' },
  { key: 'storage.document-type.options.building.fire-protection', value: 'Brandschutz' },
  { key: 'storage.document-type.options.building.building-construction-status-analysis', value: 'Gebäude / Bauzustandsanalyse' },
  { key: 'storage.document-type.options.building.heater', value: 'Heizung' },
  { key: 'storage.document-type.options.plot.land-map-cadastre-official-site-plan', value: 'Flurkarte / Kataster / amtlicher Lageplan' },
  { key: 'storage.document-type.options.building.climate', value: 'Klima' },
  { key: 'storage.document-type.options.plot.superstructures', value: 'Überbauungen' },
  { key: 'storage.document-type.options.building.cuts', value: 'Schnitte' },
  { key: 'storage.document-type.options.commercial-documents.offer', value: 'Angebot' },
  { key: 'storage.document-type.options.building.area-measurement', value: 'Flächenaufmaß' },
  { key: 'storage.document-type.options.commercial-documents.order-confirmation', value: 'Auftragsbestätigung' },
  { key: 'storage.document-type.options.plot.ground-value-card', value: 'Bodenrichtwertkarte' },
  { key: 'storage.document-type.options.plot.environment', value: 'Umwelt' },
  { key: 'storage.document-type.options.plot.foundation-building-site-appraisal', value: 'Gründungs- / Baugrundgutachten' },
  { key: 'storage.document-type.options.commercial-documents.warning', value: 'Mahnung' },
  { key: 'storage.document-type.options.commercial-documents.announcement', value: 'Ausschreibung' },
  { key: 'storage.document-type.options.commercial-documents.payment-order', value: 'Mahnbescheid' },
  { key: 'storage.document-type.options.renter.house-rule', value: 'Hausordnung' },
  { key: 'storage.document-type.options.renter.tenant-security', value: 'Mietersicherheiten' },
  { key: 'storage.document-type.options.commercial-documents.invoice', value: 'Rechnung' },
  { key: 'storage.document-type.options.renter.rent-arrears', value: 'Mietrückstände' },
  { key: 'storage.document-type.options.renter.terminations', value: 'Kündigungen' },
  { key: 'storage.document-type.options.renter.rent-adjustments-long-term-rent-invoice', value: 'Mietanpassungen / Dauermietrechnung' },
  { key: 'storage.document-type.options.renter.invoice-for-additional-costs', value: 'Nebenkostenabrechnung' },
  { key: 'storage.document-type.options.renter.overview-of-tenancies-usage-relationships', value: 'Übersicht Mietverhältnisse / Nutzungsverhältnisse' },
  { key: 'storage.document-type.options.litigation.assessment', value: 'Gutachten' },
  { key: 'storage.document-type.options.renter.rental-agreements-including-supplements', value: 'Mietverträge (inkl. Nachträge)' },
  { key: 'storage.document-type.options.litigation.guarantees', value: 'Bürgschaften' },
  { key: 'storage.document-type.options.litigation.legal-action', value: 'Klage' },
  { key: 'storage.document-type.options.renter.handover-protocols', value: 'Übergabeprotokolle' },
  { key: 'storage.document-type.options.renter.important-correspondence', value: 'Wichtiger Schriftverkehr' },
  { key: 'storage.document-type.options.litigation.cost-fixing-decision', value: 'Kostenfestsetzungsbeschluss' },
  { key: 'storage.document-type.options.litigation.court-costs', value: 'Gerichtskosten' },
  { key: 'storage.document-type.options.litigation.enforcement-order', value: 'Vollstreckungsbescheid' },
  { key: 'storage.document-type.options.litigation.statement-of-defense', value: 'Klageerwiderung' },
  { key: 'storage.document-type.options.litigation.verdict', value: 'Urteil' },
  { key: 'storage.document-type.options.litigation.general-correspondence', value: 'Allgemeine Korrespondenz' },
  { key: 'storage.document-type.options.litigation.notification-of-defects', value: 'Mängelrüge' },
  { key: 'storage.document-type.options.litigation.events', value: 'Termine' },
  { key: 'storage.document-type.options.litigation.other-documents-contracts-agreements', value: 'Sonstige Dokumente / Verträge / Vereinbarungen' },
  { key: 'storage.specification.options.building-permit.deviation', value: 'Abweichung' },
  { key: 'storage.specification.options.building-permit.application', value: 'Antrag' },
  { key: 'storage.specification.options.building-permit.preliminary-inquiry', value: 'Voranfrage' },
  { key: 'storage.specification.options.building-permit.cancellation', value: 'Abbruch' },
  { key: 'storage.specification.options.building-permit.start-of-building', value: 'Baubeginn' },
  { key: 'storage.specification.options.building-permit.permit', value: 'Genehmigung' },
  { key: 'storage.specification.options.official-certificates.contaminated-sites', value: 'Altlasten' },
  { key: 'storage.specification.options.building-permit.building-structure-inspection', value: 'Bausubstanzbesichtigung' },
  { key: 'storage.specification.options.building-permit.statics', value: 'Statik' },
  { key: 'storage.specification.options.building-permit.preliminary-decision', value: 'Vorbescheid' },
  { key: 'storage.specification.options.official-certificates.construction-load', value: 'Baulast' },
  { key: 'storage.specification.options.building-permit.change-of-use', value: 'Nutzungsänderung' },
  { key: 'storage.specification.options.official-certificates.completion-notice', value: 'Fertigstellungsbescheid' },
  { key: 'storage.specification.options.official-certificates.rainwater', value: 'Niederschlagswasser' },
  { key: 'storage.specification.options.official-certificates.shell-acceptance', value: 'Rohbauabnahme' },
  { key: 'storage.specification.options.official-certificates.resident-certificate', value: 'Anliegerbescheinigung' },
  { key: 'storage.specification.options.official-certificates.use-permit', value: 'Nutzungsgenehmigung' },
  { key: 'storage.specification.options.official-certificates.geothermal-probe', value: 'Erdwärmesonde' },
  { key: 'storage.specification.options.official-certificates.division-permit', value: 'Teilungsgenehmigung' },
  { key: 'storage.specification.options.official-certificates.others', value: 'Sonstige' },
  { key: 'storage.specification.options.official-certificates.heat-pump', value: 'Wärmepumpe' },
  { key: 'storage.specification.options.management-contracts.elevator-maintenance', value: 'Aufzugswartung' },
  { key: 'storage.specification.options.management-contracts.garden-maintenance', value: 'Gartenpflege' },
  { key: 'storage.specification.options.management-contracts.caretaker-service', value: 'Hausmeisterservice' },
  { key: 'storage.specification.options.official-certificates.grant-notification', value: 'Zuwendungsbescheid' },
  { key: 'storage.specification.options.management-contracts.maintenance', value: 'Wartung' },
  { key: 'storage.specification.options.management-contracts.winter-service', value: 'Winterdienst' },
  { key: 'storage.specification.options.outgoing-invoice.others', value: 'Sonstige' },
  { key: 'storage.specification.options.management-contracts.meter-reading', value: 'Zählerablesung' },
  { key: 'storage.specification.options.incoming-invoice.invest', value: 'Invest' },
  { key: 'storage.specification.options.outgoing-invoice.rental-period-invoice', value: 'Mietdauerrechnung' },
  { key: 'storage.specification.options.incoming-invoice.rent', value: 'Miete' },
  { key: 'storage.specification.options.incoming-invoice.repair', value: 'Reparatur' },
  { key: 'storage.specification.options.outgoing-invoice.meter-load-to-tenants', value: 'Meterbelastung an Mieter' },
  { key: 'storage.specification.options.incoming-invoice.others', value: 'Sonstige' },
  { key: 'storage.specification.options.incoming-invoice.insurance', value: 'Versicherung' },
  { key: 'storage.specification.options.fire-protection.valuation', value: 'Bewertung' },
  { key: 'storage.specification.options.incoming-invoice.maintenance', value: 'Wartung' },
  { key: 'storage.specification.options.fire-protection.perpetration', value: 'Begehung' },
  { key: 'storage.specification.options.fire-protection.instruction', value: 'Unterweisung' },
  { key: 'storage.specification.options.fire-protection.exams', value: 'Prüfungen' },
  { key: 'storage.specification.options.fire-protection.key-fire-department', value: 'Schlüssel Feuerwehr' },
  { key: 'storage.specification.options.fire-protection.concept', value: 'Konzept' },
  { key: 'storage.specification.options.building-construction-status-analysis.disturbance-notice', value: 'Behinderungsanzeige' },
  { key: 'storage.specification.options.building-construction-status-analysis.construction-diary', value: 'Bautagebuch' },
  { key: 'storage.specification.options.building-construction-status-analysis.site-manager-report', value: 'Bauleiterbericht' },
  { key: 'storage.specification.options.foundation-building-site-appraisal.soil-survey-of-contaminated-sites', value: 'Bodengutachten Altlasten' },
  { key: 'storage.specification.options.building-construction-status-analysis.appraisal', value: 'Wertgutachten' },
  { key: 'storage.specification.options.foundation-building-site-appraisal.geological-survey-of-the-soil', value: 'Bodengutachten geologisch' },
  { key: 'storage.specification.options.environment.contamination', value: 'Kontaminierung' },
  { key: 'admin.translations.update-message.success', value: 'Gespeichert!' },
  { key: 'storage.specification.options.foundation-building-site-appraisal.ordnance', value: 'Kampfmittel' },
  { key: 'admin.translations.update', value: 'Aktualisieren' },
  { key: 'storage.specification.options.environment.animals', value: 'Tiere' },
  { key: 'admin.translations.update-message.failure', value: 'Fehler!' },
  { key: 'storage.specification.options.environment.plants', value: 'Pflanzen' },
  { key: 'admin.settings.list-headline', value: 'Bestehende Einstellungen:' },
  { key: 'admin.geo-data.list-headline', value: 'Bestehende Standorte' },
  { key: 'admin.translations.list-headline', value: 'Bestehende Übersetzungen:' },
  { key: 'settings.personal-data.2fa.token-input', value: 'Code-Eingabe' },
  { key: 'settings.personal-data.2fa.disable-hint', value: 'Geben Sie Ihren aktuellen Authenticator-Code ein um Ihre Zwei-Faktor-Authentisierung zu deaktivieren.' },
  { key: 'settings.personal-data.2fa.activate', value: 'Zwei-Faktor-Authentisierung' },
  {
    key: 'settings.personal-data.2fa.enable-hint',
    value: 'Scannen Sie den QR-Code mit einer Authenticator-App wie Google Authenticator und geben Sie den Code in das Eingabefeld darunter ein, um die Zwei-Faktor-Authentisierung zu aktivieren.',
  },
  { key: 'settings.personal-data.2fa.success', value: '2FA-Einstellung erfolgreich gespeichert.' },
  { key: 'login.2fa.code-input', value: 'Authenticator-Code' },
  { key: 'settings.personal-data.2fa.error', value: 'Ihr eingegebener Code war ungültig.' },
  { key: 'layout-dashboard.logout', value: 'Abmelden' },
  { key: 'login.2fa.cancel', value: 'Abbrechen' },
  { key: 'login.2fa.headline', value: 'Zwei-Faktor-Authentisierung' },
  { key: 'storage.document-type.options.litigation.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.renter.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.plot.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.financing.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.commercial-documents.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.property.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.building.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.introduction.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.accounting.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.management.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.building-planning-law.other', value: 'Sonstiges' },
  { key: 'storage.document-type.options.building-regulations.other', value: 'Sonstiges' },
  { key: 'storage.upload.modal.headline', value: 'Dateien Hochladen' },
  { key: 'my-objects.pipeline.navigation.storage.label', value: 'Datenraum' },
  { key: 'storage.folder.options.other', value: 'Sonstiges' },
  { key: 'storage.upload.cta', value: 'Dateien hochladen' },
  { key: 'storage.upload.label', value: 'Dateien auswählen' },
  { key: 'storage.upload.start-cta', value: 'Hochladen' },
  { key: 'storage.file.download-cta', value: 'Download' },
  { key: 'storage.specification.options.building-permit.other', value: 'Sonstiges' },
  { key: 'my-objects.pipeline.component.navigation.next', value: 'weiter' },
  { key: 'my-objects.pipeline.component.navigation.back', value: 'zurück' },
  { key: 'my-objects.pipeline.assets-class.deal-criteria', value: 'Dealkriterien' },
  { key: 'my-objects.pipeline.component.navigation.complete', value: 'abschließen' },
  { key: 'object.deal-criteria.deal-type.option.asset-deal', value: 'Asset Deal' },
  { key: 'object.deal-criteria.deal-type.option.forward-deal', value: 'Forward Deal' },
  { key: 'object.deal-criteria.deal-type', value: 'Deal Typ' },
  { key: 'object.deal-criteria.deal-type.option.share-deal', value: 'Share Deal' },
  { key: 'object.deal-criteria.investment-type.option.core', value: 'Core' },
  { key: 'object.deal-criteria.investment-type', value: 'Investmentart' },
  { key: 'object.deal-criteria.investment-type.option.oppurtunistic', value: 'Opportunistic' },
  { key: 'object.deal-criteria.investment-type.option.core-plus', value: 'Core+' },
  { key: 'object.deal-criteria.investment-type.option.value-add', value: 'Value Add' },
  { key: 'my-objects.pipeline.location-criteria.headline', value: 'Standortkriterien' },
  { key: 'my-objects.pipeline.location-criteria.abcd-selection', value: 'ABCD' },
  { key: 'my-objects.pipeline.location-criteria.abcd.options.b', value: 'B' },
  { key: 'my-objects.pipeline.location-criteria.abcd.options.d', value: 'D' },
  { key: 'my-objects.pipeline.location-criteria.min-population', value: 'Min. Einwohnerzahl' },
  { key: 'my-objects.pipeline.location-criteria.abcd.options.c', value: 'C' },
  { key: 'kilometer', value: ' km' },
  { key: 'my-objects.pipeline.location-criteria.abcd.options.a', value: 'A' },
  { key: 'my-objects.pipeline.location-criteria.state', value: 'Bundesländer / Landkreise / Regionen' },
  { key: 'my-objects.pipeline.location-criteria.radius', value: 'Umkreis' },
  { key: 'custom-mui-suggestion.selected.title', value: 'Ausgewählt:' },
  { key: 'custom-mui-suggestion.options.title', value: 'Vorschläge:' },
  { key: 'my-objects.pipeline.location-criteria.city', value: 'Städte / PLZ' },
  { key: 'my-objects.pipeline.navigation.yield-and-object-criteria.label', value: 'Ertrags- und Objektkriterien' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.yield-criteria', value: 'Ertragskriterien' },
  { key: 'percent.symbol', value: ' %' },
  { key: 'my-objects.pipeline.location-criteria.factor-and-return.max-factor', value: 'Max. Faktor' },
  { key: 'my-objects.pipeline.location-criteria.rental-status.until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.rental-status.from', value: 'Vermietungsstand von' },
  { key: 'object.fixed-rental.price.options.whatever', value: 'Egal' },
  { key: 'object.fixed-rental.price.options.yes', value: 'Ja' },
  { key: 'my-objects.pipeline.location-criteria.fixed-rental-price', value: 'Mietpreisbindung' },
  { key: 'object.fixed-rental.price.options.no', value: 'Nein' },
  { key: 'year.symbol', value: 'Jahr' },
  { key: 'my-objects.pipeline.location-criteria.factor-and-return-timespan.until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.factor-and-return-timespan.from', value: 'Laufzeit Mietpreisbindung von' },
  { key: 'my-objects.pipeline.location-criteria.factor-and-return.min-return', value: 'Min. Rendite' },
  { key: 'years.symbol', value: ' Jahre' },
  { key: 'my-objects.pipeline.location-criteria.general-object-criteria.residental-units-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.general-object-criteria.living-space-from', value: 'Wohnfläche von' },
  { key: 'my-objects.pipeline.location-criteria.general-object-criteria.residental-units-from', value: 'Wohneinheiten von' },
  { key: 'my-objects.pipeline.location-criteria.general-object-criteria.living-space-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.plot.size-from', value: 'Grundstücksgröße von' },
  { key: 'my-objects.pipeline.location-criteria.plot.size-until', value: 'Bis' },
  { key: 'square-meter.symbol', value: ' qm' },
  { key: 'object.object-condition.wohnen.options.renovation', value: 'Renovierung' },
  { key: 'my-objects.pipeline.location-criteria.construction-year.from', value: 'Baujahr von' },
  { key: 'object.object-condition.wohnen.options.maintenance-backlog', value: 'Instandhaltungsstau' },
  { key: 'my-objects.pipeline.location-criteria.construction-year.until', value: 'Bis' },
  { key: 'object.object-condition.wohnen.options.new-buildings', value: 'Neubauten' },
  { key: 'object.object-condition.wohnen.options.redevelopment', value: 'Sanierung' },
  { key: 'object.object-condition.wohnen.options.vacancy', value: 'Leerstand' },
  { key: 'object.special-rights.wohnen.options.leasehold', value: 'Erbbaurecht' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.object-condition', value: 'Objektzustand' },
  { key: 'object.special-rights.wohnen.options.usufruct', value: 'Nießbrauchrecht' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.special-rights', value: 'Sonderrechte' },
  { key: 'my-objects.pipeline.location-criteria.residential-and-commercial-building.commercial-share-from', value: 'Gewerbeanteil von' },
  { key: 'object.special-rights.wohnen.options.monument-protection', value: 'Denkmalschutz' },
  { key: 'object.special-rights.wohnen.options.redevelopment-area', value: 'Sanierungsgebiet' },
  { key: 'my-objects.pipeline.location-criteria.residential-and-commercial-building.share-of-commercial-income-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.residential-and-commercial-building.commercial-share-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.more-types-of-use.share-from', value: 'Anteil von' },
  { key: 'my-objects.pipeline.location-criteria.residential-and-commercial-building.share-of-commercial-income-from', value: 'Anteil Gewerbeeinnahmen von' },
  { key: 'my-objects.pipeline.location-criteria.more-types-of-use', value: 'Weitere Nutzungsarten' },
  { key: 'my-objects.pipeline.location-criteria.more-types-of-use.share-until', value: 'Bis' },
  { key: 'object.more-types-of-usage.living.options.logistic', value: 'Logistik' },
  { key: 'object.more-types-of-usage.living.options.office', value: 'Büro' },
  { key: 'object.more-types-of-usage.living.options.handel-retail', value: 'Handel / Retail' },
  { key: 'my-objects.pipeline.location-criteria.building-law-situation', value: 'Baurechtliche Situation' },
  { key: 'object.building-law-situation.living.options.constructions-loads', value: 'Baulasten' },
  { key: 'object.building-law-situation.living.options.old-stock', value: 'Altbestand' },
  { key: 'object.building-law-situation.living.options.building-laws', value: 'Baurecht' },
  { key: 'object.building-law-situation.living.options.preliminary-building-permit', value: 'Bauvorbescheid' },
  { key: 'object.building-law-situation.living.options.conversion-and-fallow-areas', value: 'Konversions- und Brachflächen' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation', value: 'Bausituation' },
  { key: 'object.building-law-situation.living.options.building-permit', value: 'Baugenehmigung' },
  { key: 'my-objects.pipeline.location-criteria.residential-and-commercial-building', value: 'Wohn- und Geschäftshaus' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation.bgf-from', value: 'BGF von' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation.bgf-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation.grz-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation.grz-from', value: 'GRZ von' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation.gfz-until', value: 'Bis' },
  { key: 'my-objects.pipeline.location-criteria.construction-situation.gfz-from', value: 'GFZ von' },
  { key: 'object.construction-method.living.options.closed-design', value: 'Geschlossene Bauweise' },
  { key: 'object.construction-method.living.options.open-design', value: 'Offene Bauweise' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.label', value: 'Erweiterte Kriterien' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.free-tags', value: 'Freie Tags' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.value', value: 'Wert' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.create', value: 'Tag hinzufügen' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.key', value: 'Titel' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.remove', value: 'Entfernen' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.notes', value: 'Notizen' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.notes.url', value: 'Link zum Ankaufsprofil' },
  { key: 'object.required-information-and-documents.living.options.complete-object-address', value: 'Vollständige Objektanschrift' },
  { key: 'my-objects.pipeline.navigation.extendet-criteria.notes.text', value: 'Text' },
  { key: 'object.required-information-and-documents.living.options.target-annual-net-rent', value: 'SOLL-Jahresnettokaltmiete' },
  { key: 'object.required-information-and-documents.living.options.division-of-space', value: 'Flächenaufteilung' },
  { key: 'object.required-information-and-documents.living.options.actual-annual-net-rent', value: 'IST-Jahresnettokaltmiete' },
  { key: 'object.required-information-and-documents.living.options.property-specific-features', value: 'Objektspezifische Besonderheiten' },
  { key: 'object.required-information-and-documents.living.options.property-photos', value: 'Objektfotos' },
  { key: 'object.required-information-and-documents.living.options.tenant-list', value: 'Mieterliste' },
  { key: 'object.required-information-and-documents.living.options.sales-mandate-with-sales-consultants', value: 'Verkaufsmandat bei Verkaufsberatern' },
  { key: 'object.required-information-and-documents.living.options.layout', value: 'Grundriss' },
  { key: 'object.required-information-and-documents.living.options.land-map', value: 'Flurkarte' },
  { key: 'my-objects.pipeline.additional-criteria.object-criteria.required-documents', value: 'Erforderliche Informationen und Unterlagen' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.single-tenant.running-time.from', value: 'Vertragslaufzeit von' },
  { key: 'object.required-information-and-documents.living.options.zoning-plan', value: 'Bebauungsplan' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.single-tenant', value: 'Single Tenant' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.single-tenant.running-time.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.multi-tenant.running-time.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.multi-tenant', value: 'Multi Tenant' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.fashion', value: 'Fashion' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.multi-tenant.running-time.from', value: 'WALT von' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.electronics', value: 'Elektronik' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.grocer', value: 'Lebensmittler' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.department-store', value: 'Kaufhaus' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.hardware-store', value: 'Baumarkt' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.furniture', value: 'Möbel' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.type-of-anchor-tenant', value: 'Art des Ankermieters' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.facility', value: 'Einrichtung' },
  { key: 'object.anchor-tenant.commerce-and-retail.options.drugstore', value: 'Drogerie' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.space-percentage-from', value: 'Anteil der Fläche von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.space-percentage-until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.rent-percentage-until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.rent-percentage-from', value: 'Anteil der Miete von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split', value: 'Ankermieter: Aufteilung' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.usable-area.from', value: 'Nutzfläche von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.minimum-term-from', value: 'Mindestmietlaufzeit von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.minimum-term-until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.retail.share-of-fmcg.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.usable-area.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.retail.retail.from', value: 'Anteil Einzelhandel von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.retail.share-of-fmcg.from', value: 'Anteil FMCG von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.operator-contract.management-contract', value: 'Managementvertrag' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.retail.retail.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.operator-contract', value: 'Betreibervertrag' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.operator-contract.running-time-until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.operator-contract.lease-agreement', value: 'Pachtvertrag' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.operator-contract.running-time-from', value: 'Laufzeit von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.rooms.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.rooms.from', value: 'Anzahl der Zimmer von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.hotel.stars', value: 'Sterne (DEHOGA-Klassifizierung)' },
  { key: 'object.hotel.options.flight-hotel', value: 'Flughotel' },
  { key: 'object.hotel.options.mountain-hotel', value: 'Berghotel' },
  { key: 'object.hotel.options.city-hotel', value: 'Stadthotel' },
  { key: 'object.hotel.options.jugendstilhotel', value: 'Jugendstilhotel' },
  { key: 'object.hotel.options.winter-sports-hotel', value: 'Wintersporthotel' },
  { key: 'object.hotel.options.country-hotel', value: 'Landhotel' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.beds.ez-quota.from', value: 'EZ-Quote von' },
  { key: 'object.hotel.options.beach-hotel', value: 'Strandhotel' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.beds.from', value: 'Anzahl der Betten von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.beds.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.beds.to', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.beds.ez-quota.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.units.until', value: 'Bis' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.units.from', value: 'Anzahl der Einheiten von' },
  { key: 'my-objects.pipeline.location-criteria.construction-method', value: 'Bauweise' },
  { key: 'my-objects.pipeline.assets-class.type-of-financing', value: 'Finanzierungsart' },
  { key: 'object.type-of-financing.options.mezzanine-loan', value: 'Mezzanine Loan' },
  { key: 'object.type-of-financing.options.whole-loan', value: 'Whole Loan' },
  { key: 'object.type-of-financing.options.senior-loan', value: 'Senior Loan' },
  { key: 'my-objects.pipeline.assets-class.mezzanine-loan.from', value: 'Mezzanine Loan von' },
  { key: 'my-objects.pipeline.assets-class.mezzanine-loan.until', value: 'Bis' },
  { key: 'my-objects.pipeline.assets-class.whole-loan.from', value: 'Whole Loan von' },
  { key: 'my-objects.pipeline.assets-class.whole-loan.until', value: 'Bis' },
  { key: 'my-objects.pipeline.assets-class.senior-loan.from', value: 'Senior Loan von' },
  { key: 'my-objects.pipeline.yield-and-object-criteria.object-criteria', value: 'Objektkriterien' },
  { key: 'my-objects.pipeline.assets-class.senior-loan.until', value: 'Bis' },
  { key: 'purchase-profile.headline', value: 'Ankaufsprofile' },
  { key: 'purchase-profile.tab.paused-profiles', value: 'Pausierte Ankaufsprofile' },
  { key: 'purchase-profile.tab.aktive-profiles', value: 'Aktive Ankaufsprofile' },
  { key: 'purchase-profile.pipeline.cta', value: 'Neues Ankaufsprofil' },
  { key: 'purchase-profile.active.error', value: 'Ihre aktiven Ankaufsprofile konnten nicht geladen werden.' },
  { key: 'purchase-profile.paused.error', value: 'Ihre pausierten Ankaufsprofile konnten nicht geladen werden.' },
  { key: 'purchase-profile.active.no-results', value: 'Es gibt derzeit keine aktiven Ankaufsprofile.' },
  { key: 'purchase-profile.risk-class', value: 'Risikoklasse' },
  { key: 'purchase-profile.paused.no-results', value: 'Es gibt derzeit keine pausierten Ankaufsprofile.' },
  { key: 'purchase-profile.deal-type', value: 'Dealtyp' },
  { key: 'purchase-profile.deal-type.single-investment', value: 'Einzelinvestments' },
  { key: 'purchase-profile.deal-type.porfolio-investment', value: 'Portfolioinvestments' },
  { key: 'purchase-profile.return', value: 'Rendite' },
  { key: 'purchase-profile.plot-size', value: 'Grundstücksgröße' },
  { key: 'purchase-profile.return.factor', value: 'Faktor ' },
  { key: 'purchase-profile.rentable-space', value: 'vermietbare Fläche' },
  { key: 'purchase-profile.construction-year', value: 'Baujahr' },
  { key: 'purchase-profile.locations', value: 'Standorte' },
  { key: 'purchase-profile.locations.population', value: ' Einwohner' },
  { key: 'new-object.pipeline.cta', value: 'Neues Objekt' },
  { key: 'q-and-a.create-button', value: '+ NEUE FRAGE STELLEN' },
  { key: 'q-and-a.new-question', value: 'Neue Frage eingeben' },
  { key: 'q-and-a.new-answer', value: 'Neue Antwort eingeben' },
  { key: 'object.yield-criteria.livingsize', value: 'Wohnfläche' },
  { key: 'object.yield-criteria.commercialsize', value: 'Gewerbefläche' },
  { key: 'object.yield-criteria.commercialincomming', value: 'Mieteinnahmen (Gewerbe)' },
  { key: 'object.yield-criteria.livingincomming', value: 'Mieteinnahmen (Wohnen)' },
  { key: 'object.yield-criteria.livingcount', value: 'Anzahl Einheiten (Wohnen)' },
  { key: 'object.yield-criteria.commercialcount', value: 'Anzahl Einheiten (Gewerbe)' },
  { key: 'per-month.symbol', value: 'p.M.' },
  { key: 'object.measures.description', value: 'Beschreibung' },
  { key: 'object.sales-process.start-date', value: 'Startdatum' },
  { key: 'object.measures.add-measure', value: 'MAßNAHME HINZUFÜGEN' },
  { key: 'object.sales-process.start-time', value: 'Startzeit' },
  { key: 'object.sales-process.interval', value: 'Intervall' },
  { key: 'object.more', value: 'Weiteres' },
  { key: 'object.sales-process.per-interval', value: 'Ansprache pro Intervall' },
  { key: 'object.object-data', value: 'Objektdaten' },
  { key: 'object.measures', value: 'Maßnahmen (Renovierung / Sanierung)' },
  { key: 'object.sales-process', value: 'Verkaufsprozess' },
  { key: 'object.object-data.year', value: 'Baujahr' },
  { key: 'object.sales-process.documents.confidentiality-agreement', value: 'Geheimhaltungsvereinbarung (NDA)' },
  { key: 'object.object-data.size', value: 'Grundstücksgröße' },
  { key: 'object.sales-process.documents.expression-of-interest', value: 'Interessensbekundung (LOI)' },
  { key: 'object.sales-process.courtage', value: 'Außenprovision (Courtage)' },
  { key: 'object.sales-process.phone', value: 'Telefon' },
  { key: 'object.sales-process.email', value: 'Persönliche E-Mail' },
  { key: 'object.sales-process.mobile', value: 'Mobil' },
  { key: 'object.sales-price', value: 'Verkaufspreis' },
  { key: 'object.photos.label', value: 'Bilder hinzufügen' },
  { key: 'object.location', value: 'Standort' },
  { key: 'object.measures.measure', value: 'Maßnahme' },
  { key: 'my-objects.pipeline.location.state', value: 'Bundesland' },
  { key: 'my-objects.pipeline.location.district', value: 'Landkreis' },
  { key: 'my-objects.pipeline.location.region', value: 'Region' },
  { key: 'my-objects.pipeline.location.postcode', value: 'PLZ' },
  { key: 'my-objects.pipeline.location.city', value: 'Stadt' },
  { key: 'object.deal-criteria.portfolio', value: 'Portfolio' },
  { key: 'my-objects.pipeline.location.housenumber', value: 'Hausnummer' },
  { key: 'my-objects.pipeline.investment.buyingprice', value: 'Kaufpreis' },
  { key: 'my-objects.pipeline.investment.notaryfees', value: 'Notarkosten' },
  { key: 'my-objects.pipeline.investment.propertytransfertax', value: 'Grunderwerbssteuer' },
  { key: 'my-objects.pipeline.investment.agencyfee', value: 'Vermittlungsgebühr (Makler)' },
  { key: 'my-objects.pipeline.investment.landregistercosts', value: 'Grundbuchkosten' },
  { key: 'my-objects.pipeline.investment.pairingfee', value: 'Pairing-Gebühr' },
  { key: 'my-objects.pipeline.investment.yield', value: 'Rendite' },
  { key: 'my-objects.pipeline.investment.faktor', value: 'Faktor' },
  { key: 'my-objects.pipeline.investment.groundvalue', value: 'Bodenrichtwert' },
  { key: 'my-objects.pipeline.investment.rentalguaranteeselection', value: 'Mietgarantie' },
  { key: 'my-objects.pipeline.investment.rentalguarantee.from', value: 'Mietgarantie von' },
  { key: 'my-objects.pipeline.investment.rentalguarantee.to', value: 'Mietgarantie bis' },
  { key: 'object.deal-criteria.investment', value: 'Investment' },
  { key: 'object.other.monumentprotection', value: 'Denkmalschutz' },
  { key: 'object.other.label', value: 'Sonstiges' },
  { key: 'object.other.leasehold', value: 'Erbbaurecht' },
  { key: 'object.other.usufruct', value: 'Niessbrauchrecht' },
  { key: 'object.other.socialbond', value: 'Sozialbindung' },
  { key: 'object.other.constructioncharges', value: 'Baulasten' },
  { key: 'object.other.legacy', value: 'Altlasten' },
  { key: 'object.other.objectdescription', value: 'Objektbeschreibung' },
  { key: 'object.other.locationdescription', value: 'Lagebeschreibung' },
  { key: 'my-objects.pipeline.mapping-metric.options.aid', value: 'AID' },
  { key: 'object.other.more', value: 'Weiteres' },
  { key: 'my-objects.pipeline.mapping-metric.options.pid', value: 'PID' },
  { key: 'my-objects.pipeline.mapping-metric', value: 'Zuordnungskennzahl' },
  { key: 'my-objects.pipeline.mapping-metric.options.cid', value: 'CID' },
  { key: 'object.object-data.lift', value: 'Aufzug' },
  { key: 'object.pitches.number', value: 'Anzahl der Stellplätze' },
  { key: 'object.object-data.basement', value: 'Keller' },
  { key: 'object.pitches.garage', value: 'davon Parkhaus' },
  { key: 'object.pitches.outdoor', value: 'Außenstellplätze' },
  { key: 'object.pitches.underground', value: 'Tiefgarage' },
  { key: 'object.hotelrooms.single', value: 'Einzelzimmer' },
  { key: 'object.hotelrooms.double', value: 'Doppelzimmer' },
  { key: 'object.hotelrooms.junior', value: 'Junior Suite' },
  { key: 'object.hotelrooms.family', value: 'Familienzimmer' },
  { key: 'object.hotelrooms.suite', value: 'Suite' },
  { key: 'object.hotelrooms.multibed', value: 'Mehrbettzimmer' },
  { key: 'object.hotelrooms.apartment', value: 'Apartment' },
  { key: 'object.hotelrooms.number', value: 'Anzahl der Zimmer' },
  { key: 'object.operatorcontract.options.operatorfree', value: 'Betreiberfrei' },
  { key: 'object.operatorcontract.managementcontract', value: 'Managementvertrag' },
  { key: 'object.operatorcontract', value: 'Betreibervertrag' },
  { key: 'object.operatorcontract.options.leaseagreement', value: 'Pachtvertrag' },
  { key: 'object.financing.lender', value: 'Darlehensgeber' },
  { key: 'object.financing.interest', value: 'Zins' },
  { key: 'object.financing.annuity', value: 'Annuität' },
  { key: 'object.financing.repayment', value: 'Tilgung' },
  { key: 'object.financing.runningtime', value: 'Laufzeit' },
  { key: 'object.financing.remainingterm', value: 'Restlaufzeit' },
  { key: 'object.operatingcost.recoverable', value: 'umlegbare Betriebskosten' },
  { key: 'object.financing.specialrepayment', value: 'Sondertilgung' },
  { key: 'object.operatingcost.notrecoverable', value: 'nicht umlegbare Betriebskosten' },
  { key: 'object.hoteladditional.bedoccupancy', value: 'Bettenauslastung' },
  { key: 'object.hoteladditional.roomoccupancy', value: 'Zimmerauslastung' },
  { key: 'object.hoteladditional.averageroomrate', value: 'Average-Room-Rate' },
  { key: 'object.hoteladditional.revenuehotelguests', value: 'Umsatz durch Hotelgäste' },
  { key: 'object.hoteladditional.costsofsalesratio', value: 'Wareneinsatzquote' },
  { key: 'object.hoteladditional.revenueexternalguests', value: 'Umsatz durch externe Gäste' },
  { key: 'object.hoteladditional.personalcostsperfulltimeemployee', value: 'Personalkosten je Vollbeschäftigtem' },
  { key: 'object.hoteladditional.gastronomy', value: 'Gastronomie' },
  { key: 'object.hoteladditional.productivityperfulltimeemployee', value: 'Produktivität je Vollbeschäftigten' },
  { key: 'newobject.administration.costs', value: 'Verwaltungskosten' },
  { key: 'object.administration', value: 'Verwaltung' },
  { key: 'object.administration.costs', value: 'Verwaltungskosten' },
  { key: 'object.searched.label', value: 'Gesucht' },
  { key: 'object.operatingcost', value: 'Betriebskosten' },
  { key: 'object.searched.projectdeveloper', value: 'Projektentwickler' },
  { key: 'object.searched.leasehold', value: 'Bauunternehmer' },
  { key: 'object.searched.usufruct', value: 'Investor' },
  { key: 'object.tenantlist.name', value: 'Name des Mieters' },
  { key: 'object.tenantlist.descriptionspace', value: 'Bezeichnung der Fläche' },
  { key: 'object.tenantlist.sqm', value: 'Fläche in m2' },
  { key: 'object.tenantlist.coldrenttotal', value: 'Kaltmiete (gesamt)' },
  { key: 'object.tenantlist.coldrentsqm', value: 'Kaltmiete (pro m2)' },
  { key: 'object.tenantlist.operatingcoststotal', value: 'Betriebskosten (gesamt)' },
  { key: 'object.tenantlist.totalrent', value: 'Gesamtmiete (gesamt)' },
  { key: 'object.tenantlist.totalrentsqm', value: 'Gesamtmiete (m2)' },
  { key: 'object.tenantlist.operatingcostsprepayment', value: 'Betriebskostenvorauszahlung' },
  { key: 'object.tenantlist.startdate', value: 'Mietbeginn' },
  { key: 'object.tenantlist.enddate', value: 'Mietende' },
  { key: 'object.tenantlist.noticeperiod', value: 'Kündigungsfrist' },
  { key: 'object.tenantlist.options', value: 'Optionen' },
  { key: 'object.tenantlist.leasetype', value: 'Mietvertragsart' },
  { key: 'object.tenantlist.lastrentincrease', value: 'Höhe der letzten Mieterhöhung' },
  { key: 'object.tenantlist.note', value: 'Notizen' },
  { key: 'object.tenantlist.add', value: 'Hinzufügen' },
  { key: 'object.tenantlist.create', value: 'Mieterliste erstellen' },
  { key: 'object.tenantlist.title', value: 'Mieterliste' },
  { key: 'object.location.search', value: 'Adresssuche' },
  { key: 'my-objects.pipeline.photos', value: 'Fotos' },
  { key: 'object.object-type.searched.option.project-developer', value: 'Projektentwickler' },
  { key: 'object.object-type.searched.option.investor', value: 'Investor' },
  { key: 'object.object-type.searched.option.contractor', value: 'Bauunternehmer' },
  { key: 'object.object-type.searched.option.renter', value: 'Mieter' },
  { key: 'object.object-type.searched.option.operator', value: 'Betreiber' },
]

export default translation
