import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const ResidentialAndCommercialBuilding = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...purchaseProfile, residentialAndCommercialBuilding: { ...purchaseProfile.residentialAndCommercialBuilding, [key]: value } }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="label">{translations['my-objects.pipeline.location-criteria.residential-and-commercial-building']}</div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.commercial-share-from']}</div>
        <div className="values">
          <Input
            type="number"
            suffix={translations['percent.symbol']}
            value={purchaseProfile.residentialAndCommercialBuilding.commercialShareFrom}
            onChange={update('commercialShareFrom')}
            placeholder={translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.commercial-share-from']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.commercial-share-until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              suffix={translations['percent.symbol']}
              value={purchaseProfile.residentialAndCommercialBuilding.commercialShareUntil}
              onChange={update('commercialShareUntil')}
              placeholder={translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.commercial-share-until']}
            />
          </div>
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.share-of-commercial-income-from']}</div>
        <div className="values">
          <Input
            type="number"
            suffix={translations['percent.symbol']}
            value={purchaseProfile.residentialAndCommercialBuilding.shareOfCommercialIncomeFrom}
            onChange={update('shareOfCommercialIncomeFrom')}
            placeholder={translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.share-of-commercial-income-from']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.share-of-commercial-income-until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              suffix={translations['percent.symbol']}
              value={purchaseProfile.residentialAndCommercialBuilding.shareOfCommercialIncomeUntil}
              onChange={update('shareOfCommercialIncomeUntil')}
              placeholder={translations['my-objects.pipeline.location-criteria.residential-and-commercial-building.share-of-commercial-income-until']}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResidentialAndCommercialBuilding
