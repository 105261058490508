import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import socketIOClient from 'socket.io-client'
import { backendSocketIODomain } from '../../../utility'
import './Chat.scss'
import Room from './Room/Room'
import RoomList from './RoomList/RoomList'

const Chat = ({ objectUUID, chatOnly = false }) => {
  const token = useSelector((state) => state.TOKEN)
  const translations = useSelector((state) => state.translations)

  const [socket, setSocket] = useState(null)
  const [activeRoomUUID, setActiveRoomUUID] = useState(null)
  const [chatInitialized, setChatInitialized] = useState(false)

  useEffect(() => {
    if (token && !socket) {
      const socket = socketIOClient(backendSocketIODomain)
      setSocket(socket)
      socket.emit('init-chat', { token })

      socket.on('init-chat', () => setChatInitialized(true))
    }

    return () => {
      if (socket && socket.connected) {
        socket.disconnect()
        setChatInitialized(false)
        setSocket(null)
      }
    }
  }, [token, socket, activeRoomUUID])

  const plainChat = (
    <div className="split">
      <div className="room-list-wrapper">
        <RoomList objectUUID={objectUUID} activeRoomUUID={activeRoomUUID} socket={socket} roomSelect={setActiveRoomUUID} />
      </div>
      <div className="room-wrapper">
        <Room objectUUID={objectUUID} activeRoomUUID={activeRoomUUID} socket={socket} roomSelect={setActiveRoomUUID} />
      </div>
    </div>
  )
  if (chatOnly) {
    if (chatInitialized) {
      return <div id="chat">{plainChat}</div>
    } else {
      return <></>
    }
  }
  return (
    <DashboardLayout card={true}>
      {chatInitialized && (
        <div id="chat">
          <CardHeader headline={translations['chat.headline']} />
          <CardContent>{plainChat}</CardContent>
        </div>
      )}
    </DashboardLayout>
  )
}

export default Chat
