import * as types from '../type/types'

export const setNewObject = (object) => ({
  type: types.SET_NEW_OBJECT_PIPELINE,
  payload: object,
})

export const triggerNewObjectPipelineCleanup = () => ({
  type: types.TRIGGER_NEW_OBJECT_CLEANUP,
})
