import { useDispatch, useSelector } from 'react-redux'
import { getUUID } from '../../../../../../utility'
import Input from '../../../../../UI/Input/Input'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import './TenantListModal.scss'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const TenantListModal = ({ open, handleClose }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const [currentTenant, setCurrentTenant] = useState({})
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    setCurrentTenant({ ...currentTenant, [key]: value })
  }

  const onClickCreate = () => {
    // dispatch(setNewObject({ ...newObject, tenantList: [...newObject.tenantList, { ...currentTenant, uuid: getUUID() }] }))
    dispatch(setLocal(props.OBJECT, { ...newObject, tenantList: [...newObject.tenantList, { ...currentTenant, uuid: getUUID() }] }))
    setCurrentTenant({})
  }

  return (
    <Modal open={open} close={handleClose} title={translations['object.tenantlist.title']} size="lg">
      <div id="tenantListModalBody">
        <Input className="modalInput" type="text" value={currentTenant.name || ''} onChange={update('name')} placeholder={translations['object.tenantlist.name']} />
        <Input className="modalInput" type="text" value={currentTenant.descriptionSpace || ''} onChange={update('descriptionSpace')} placeholder={translations['object.tenantlist.descriptionspace']} />
        <Input className="modalInput" type="customNumber" value={currentTenant.sqm || ''} onChange={update('sqm')} placeholder={translations['object.tenantlist.sqm']} />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.coldRentTotal || ''}
          onChange={update('coldRentTotal')}
          placeholder={translations['object.tenantlist.coldrenttotal']}
          symbol={translations['currency.symbol']}
        />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.coldRentSqm || ''}
          onChange={update('coldRentSqm')}
          placeholder={translations['object.tenantlist.coldrentsqm']}
          symbol={translations['currency.symbol']}
        />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.operatingCostsTotal || ''}
          onChange={update('operatingCostsTotal')}
          placeholder={translations['object.tenantlist.operatingcoststotal']}
          symbol={translations['currency.symbol']}
        />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.totalRent || ''}
          onChange={update('totalRent')}
          placeholder={translations['object.tenantlist.totalrent']}
          symbol={translations['currency.symbol']}
        />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.totalRentSqm || ''}
          onChange={update('totalRentSqm')}
          placeholder={translations['object.tenantlist.totalrentsqm']}
          symbol={translations['currency.symbol']}
        />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.operatingCostsPrepayment || ''}
          onChange={update('operatingCostsPrepayment')}
          placeholder={translations['object.tenantlist.operatingcostsprepayment']}
          symbol={translations['currency.symbol']}
        />
        <Input className="modalInput" type="text" value={currentTenant.startDate || ''} onChange={update('startDate')} placeholder={translations['object.tenantlist.startdate']} />
        <Input className="modalInput" type="text" value={currentTenant.endDate || ''} onChange={update('endDate')} placeholder={translations['object.tenantlist.enddate']} />
        <Input className="modalInput" type="text" value={currentTenant.noticePeriod || ''} onChange={update('noticePeriod')} placeholder={translations['object.tenantlist.noticeperiod']} />
        <Input className="modalInput" type="text" value={currentTenant.options || ''} onChange={update('options')} placeholder={translations['object.tenantlist.options']} />
        <Input className="modalInput" type="text" value={currentTenant.leaseType || ''} onChange={update('leaseType')} placeholder={translations['object.tenantlist.leasetype']} />
        <Input
          className="modalInput"
          type="customNumber"
          value={currentTenant.lastRentIncrease || ''}
          onChange={update('lastRentIncrease')}
          placeholder={translations['object.tenantlist.lastrentincrease']}
        />
        <Input className="modalInput" type="text" value={currentTenant.note || ''} onChange={update('note')} placeholder={translations['object.tenantlist.note']} />
        <Button text={translations['object.tenantlist.add']} onClick={onClickCreate} />
        {/* {Array.isArray(newObject.tenantList) && newObject.tenantList.map((tenant) => <div></div>)} */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations['object.tenantlist.name']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.descriptionspace']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.sqm']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.coldrenttotal']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.coldrentsqm']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.operatingcoststotal']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.totalrent']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.totalrentsqm']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.operatingcostsprepayment']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.startdate']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.enddate']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.noticeperiod']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.options']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.leasetype']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.lastrentincrease']}</TableCell>
                <TableCell align="left">{translations['object.tenantlist.note']}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newObject.tenantList.map((row) => (
                <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.descriptionSpace}</TableCell>
                  <TableCell align="left">{row.sqm}</TableCell>
                  <TableCell align="left">{row.coldRentTotal}</TableCell>
                  <TableCell align="left">{row.coldRentSqm}</TableCell>
                  <TableCell align="left">{row.operatingCostsTotal}</TableCell>
                  <TableCell align="left">{row.totalRent}</TableCell>
                  <TableCell align="left">{row.totalRentSqm}</TableCell>
                  <TableCell align="left">{row.operatingCostsPrepayment}</TableCell>
                  <TableCell align="left">{row.startDate}</TableCell>
                  <TableCell align="left">{row.endDate}</TableCell>
                  <TableCell align="left">{row.noticePeriod}</TableCell>
                  <TableCell align="left">{row.options}</TableCell>
                  <TableCell align="right">{row.leaseType}</TableCell>
                  <TableCell align="left">{row.lastRentIncrease}</TableCell>
                  <TableCell>{row.note}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Modal>
  )
}

export default TenantListModal
