import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { getUUID } from '../../../../../../utility'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const AnchorTenant = ({ payload }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (isChecked) => {
    const updatePurchaseProfile = isChecked
      ? { ...purchaseProfile, anchorTenant: [...purchaseProfile.anchorTenant, key] }
      : { ...purchaseProfile, anchorTenant: [...purchaseProfile.anchorTenant.filter((k) => k !== key)] }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.type-of-anchor-tenant']}</div>
        <div className="values">
          {settings.getGroup(payload).map((option) => (
            <Checkbox key={getUUID()} checked={purchaseProfile.anchorTenant.includes(option.key)} onChange={update(option.key)} text={translations[option.translationKey]} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AnchorTenant
