import './Registration.scss'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Layout from '../../Layout/Layout'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Link from '../../UI/Link/Link'
import Dropdown from '../../UI/Dropdown/Dropdown'
import call from '../../../interface/functional'

const Registration = () => {
  const [salutation, setSalutation] = useState('')
  const [title, setTitle] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const settings = useSelector((state) => state.settings)

  const translations = useSelector((state) => state.translations)

  const everytingFilled = () => {
    return !!salutation && !!firstname && !!lastname && !!mail && !!password && !!passwordConfirm && password === passwordConfirm
  }

  const submit = async (e) => {
    e.preventDefault()
    if (everytingFilled()) {
      setError(null)
      const response = await call(null, 'registration', { salutation, title, firstname, lastname, mail, password })
      console.log(response)
      if (response) setSuccess(true)
    } else {
      setError(translations['registration.missing-inputs'])
    }
  }

  if (!settings) return <></>
  return (
    <Layout>
      <div id="registration">
        <div className="form-wrapper">
          <div className="headline">{translations['registration.headline']}</div>
          {success && (
            <div className="done">
              <div className="text">{translations['registration.done.text']}</div>
              <Link href="/login" text={translations['registration.done.login']} />
            </div>
          )}
          {!success && (
            <form onSubmit={submit}>
              <div className="double-element-wrapper">
                <div className="element">
                  <Dropdown
                    name={translations['registration.salutation']}
                    value={salutation}
                    onChange={setSalutation}
                    options={settings.getGroup('person.salutation.options').map((option) => ({
                      key: option.key,
                      value: translations[option.translationKey],
                    }))}
                  />
                </div>
                <div className="element">
                  <Dropdown
                    name={translations['registration.title']}
                    value={title}
                    onChange={setTitle}
                    options={settings.getGroup('person.title.options').map((option) => ({
                      key: option.key,
                      value: translations[option.translationKey],
                    }))}
                  />
                </div>
              </div>
              <div className="double-element-wrapper">
                <div className="element">
                  <Input placeholder={translations['registration.firstname']} value={firstname} onChange={setFirstname} />
                </div>
                <div className="element">
                  <Input placeholder={translations['registration.lastname']} value={lastname} onChange={setLastname} />
                </div>
              </div>
              <div className="element-wrapper">
                <Input type="email" placeholder={translations['registration.mail']} value={mail} onChange={setMail} />
              </div>
              <div className="element-wrapper">
                <Input type="password" placeholder={translations['registration.password']} value={password} onChange={setPassword} />
              </div>
              <div className="element-wrapper">
                <Input type="password" placeholder={translations['registration.password']} value={passwordConfirm} onChange={setPasswordConfirm} />
              </div>
              {error ? <div className="error">{error}</div> : ''}
              <div className="element-wrapper">
                <Button text={translations['registration.button']} type={'submit'} />
              </div>
              <Link href="/login" text={translations['registration.login']} />
            </form>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Registration
