import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
// import Home from "./components/Home/Home";
import DataProtection from './components/DataProtection/DataProtection'
import Imprint from './components/Imprint/Imprint'
import Login from './components/Auth/Login/Login'
import PasswordLost from './components/Auth/PasswordLost/PasswordLost'
import PasswordReset from './components/Auth/PasswordReset/PasswordReset'
import Registration from './components/Auth/Registration/Registration'
import RegistrationConfirm from './components/Auth/Registration/Confirm/Confirm'
import 'bootstrap/dist/css/bootstrap.min.css'
import Settings from './components/Settings/Settings'
// import Translations from './components/Translations/Translations'
import DashboardRouting from './DashboardRouting'
import AdminRouting from './AdminRouting'
import NotFound from './components/NotFound/NotFound'
import { useSelector } from 'react-redux'

function App() {
  const user = useSelector((state) => state.USER)
  return (
    <>
      <Settings>
        {/* <Translations> */}
        <Router>
          <Switch>
            {/* LANDINGPAGE */}
            <Route exact={true} path="/">
              <Redirect to={!!user ? '/dashboard/my-objects' : '/login'} />
              {/* <Home /> */}
            </Route>
            {/* ADMIN */}
            <Route exact={false} path="/admin" component={AdminRouting} />
            {/* LOGIN */}
            <Route exact={true} path="/login" component={Login} />
            {/* PASSWORD LOST */}
            <Route exact={true} path="/password-lost" component={PasswordLost} />
            {/* PASSWORD LOST */}
            <Route exact={true} path="/password-lost/:code" component={PasswordReset} />
            {/* REGISTRATION */}
            <Route exact={true} path="/registration" component={Registration} />
            {/* REGISTRATION CONFIRM */}
            <Route exact={true} path="/registration/:code" component={RegistrationConfirm} />
            {/* DASHBOARD - ROUTING */}
            <Route exact={false} path="/dashboard" component={DashboardRouting} />
            {/* IMPRINT */}
            <Route exact={true} path="/imprint" component={Imprint} />
            {/* DATA PROTECTION */}
            <Route exact={true} path="/data-protection" component={DataProtection} />
            <Route component={NotFound} />
          </Switch>
        </Router>
        {/* </Translations> */}
      </Settings>
    </>
  )
}

export default App
