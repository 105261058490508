import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import { getUUID } from '../../../../utility'
import './List.scss'
import Card from '../Profile/Card/Card'
import Modal from '../../../UI/Modal/Modal'
import Button from '../../../UI/Button/Button'
import { list, remove as crudRemove } from '../../../../interface/crud'
import props from '../../../../redux/props'

const List = ({ state }) => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [profiles, setProfiles] = useState(null)
  const [error, setError] = useState(false)
  const [requestRemove, setRequestRemove] = useState(null)
  const account = useSelector((state) => state.ACCOUNT)

  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = await list(token, props.PURCHASE_PROFILE, { userUUID: account.assignedToUUID, active: state === 'active' })
      if (profiles) {
        setProfiles(profiles)
      } else {
        setError(true)
      }
    }
    if (token && !profiles && account) {
      fetchProfiles()
    }
  })

  const copy = async (uuid) => {
    const { status, profile } = await fetch('/purchase-profile/create/copy', { token, uuid })
    if (status && profile) {
      setProfiles([profile, ...profiles])
    }
  }

  const remove = async (uuid) => {
    const result = await crudRemove(token, props.PURCHASE_PROFILE, { uuid })
    // const { status } = await fetch('/purchase-profile/remove', { token, uuid })
    if (result) {
      setProfiles(profiles.filter((p) => p.uuid !== uuid))
    }
  }

  return (
    <div className="purchase-profile-list">
      {Array.isArray(profiles) && profiles.length === 0 && <div className="message">{translations[`purchase-profile.${state}.no-results`]}</div>}
      {Array.isArray(profiles) &&
        profiles.length > 0 &&
        profiles.map((profile) => (
          <div key={getUUID()}>
            <Card data={profile} copy={copy} remove={setRequestRemove} />
            {requestRemove && (
              <Modal open={true} close={() => setRequestRemove(null)} title={translations[profile.assetClass]} className="modalRequestRemove">
                <div>
                  <div>{translations['purchaseprofile.remove-request-modal.text']}</div>
                  <div className="btnWrapper">
                    <Button text={translations['purchaseprofile.remove-request-modal.btn-abort']} onClick={() => setRequestRemove(null)} />
                    <Button className="colorRed" text={translations['purchaseprofile.remove-request-modal.btn-delete']} onClick={() => remove(requestRemove)} />
                  </div>
                </div>
              </Modal>
            )}
          </div>
        ))}
      {error && <div className="message">{translations[`purchase-profile.${state}.error`]}</div>}
    </div>
  )
}

export default List
