import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../fetch'
import './Translations.scss'
import Item from './Item/Item'
import { getUUID } from '../../../utility'
import AdminLayout from '../../Layout/Admin/Admin'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import RadioGroup from '../../UI/RadioGroup/RadioGroup'
import Create from './Create/Create'
import ExportImport from './ExportImport/ExportImport'

const Translations = () => {
  const token = useSelector((state) => state.TOKEN)
  const translations = useSelector((state) => state.translations)
  const langOptions = useSelector((state) => state.langOptions)
  const lang = useSelector((state) => state.lang)
  const [selectedLang, setSelectedLang] = useState(lang)
  const [list, setList] = useState(null)

  useEffect(() => {
    const loadTranslations = async () => {
      const { status, translations } = await fetch('/admin/translations/get-all', { token, lang: selectedLang || lang })
      if (status && Array.isArray(translations)) {
        setList(translations)
      }
    }

    if (!list && selectedLang && token) {
      loadTranslations()
    }
  }, [selectedLang, list, lang, token])

  const langSwap = (lang) => {
    setList(null)
    setSelectedLang(lang)
  }

  return (
    <AdminLayout card={true}>
      <CardHeader headline={translations['layout-admin.navigation.settings.translation']} />
      <CardContent>
        <div id="admin-translations">
          <div className="lang-selector">
            <RadioGroup name={translations['admin.translations.lang-selector.choose']} value={selectedLang || lang} onChange={langSwap} options={langOptions.map((lo) => ({ key: lo, value: lo }))} />
          </div>
          <Create selectedLang={selectedLang} />
          <ExportImport selectedLang={selectedLang} />
          <div className="list-headline">{translations['admin.translations.list-headline']}</div>
          <div className="list">{Array.isArray(list) ? list.map((translation) => <Item key={getUUID()} translation={translation} selectedLang={selectedLang} />) : ''}</div>
        </div>
      </CardContent>
    </AdminLayout>
  )
}

export default Translations
