import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Autocomplete from './Autocomplete'
import './Location.scss'

const Location = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const findLongName = (array, type) => array.find((el) => el.types.includes(type))?.long_name

  const hasValues = () => Object.keys(newObject.location).reduce((acc, el) => newObject.location[el] && !acc && true, false)

  const onSelectedPlace = (address_components, coordinates) => {
    const location = {}

    location['postcode'] = findLongName(address_components, 'postal_code')
    location['country'] = findLongName(address_components, 'country')
    location['state'] = findLongName(address_components, 'administrative_area_level_1')
    location['district'] = findLongName(address_components, 'administrative_area_level_2') || findLongName(address_components, 'administrative_area_level_3')
    location['city'] = findLongName(address_components, 'locality')
    location['street'] = findLongName(address_components, 'route')
    location['houseNumber'] = findLongName(address_components, 'street_number')

    dispatch(setLocal(props.OBJECT, { ...newObject, location, coordinates: { coordinates } }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.location']}</div>
          <div className="values">
            <Autocomplete onSelectedPlace={onSelectedPlace} />
            {newObject.location && hasValues() && (
              <div className="locationResult">
                <div className="sidebyside">
                  <div>{newObject.location.street}</div>
                  <div>{newObject.location.houseNumber}</div>
                </div>
                <div className="sidebyside">
                  <div>{newObject.location.postcode}</div>
                  <div>{newObject.location.city}</div>
                </div>
                <div className="sidebyside">
                  <div>{newObject.location.district},</div>
                  <div>{newObject.location.state}</div>
                </div>
                <div>{newObject.location.country}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Location
