const conditions = () => {
  return [
    { projectType: { $ne: '' } },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.hotel': 'object.hotel.options',
        },
      },
    },
  ]
}

export default conditions
