import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../../../UI/Dropdown/Dropdown'
import Input from '../../../../../UI/Input/Input'
import Button from '../../../../../UI/Button/Button'
import { getUUID } from '../../../../../../utility'
import { setLocationCountry } from '../../../../../../action/purchaseProfile'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'

import './LocationCard.scss'

const Country = () => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const [abcd, setAbcd] = useState('')
  const [minPopulation, setMinPopulation] = useState('')
  const [radius, setRadius] = useState('')

  const abcdOptions = settings.getGroup('my-objects.pipeline.location-criteria.abcd.options').map((option) => ({ key: option.value, value: option.value }))

  const onClickAdd = () => {
    const uuid = getUUID()

    dispatch(setLocationCountry([...purchaseProfile.locationCountry, { uuid, abcd, minPopulation, radius }]))
    setRadius('')
    setMinPopulation('')
    setAbcd('')
  }

  const onClickRemove = (uuid) => () => {
    dispatch(setLocationCountry(purchaseProfile.locationCountry.filter((el) => el.uuid !== uuid)))
  }

  return (
    <div className="locationCard">
      <div className="locationTitle">{translations['location.title.country']}</div>
      <div className="locationInputNextTo">
        <Dropdown name={translations['my-objects.pipeline.location-criteria.abcd-selection']} options={abcdOptions} value={abcd} onChange={(e) => setAbcd(e)} />
        <Input type="number" value={minPopulation} onChange={(e) => setMinPopulation(e)} placeholder={translations['my-objects.pipeline.location-criteria.min-population-required']} />
      </div>
      <div className="locationSubTitle">{translations['location.more-criterions']}</div>
      <div className="locationInputNextTo">
        <Input type="customNumber" value={radius} onChange={(e) => setRadius(e)} placeholder={translations['my-objects.pipeline.location-criteria.radius']} symbol={translations['kilometer']} />
      </div>
      <Button disabled={isNaN(minPopulation)} onClick={onClickAdd} text={translations['add-cta']} fullWidth={false} />
      <div className="itemList">
        {Array.isArray(purchaseProfile.locationCountry) &&
          purchaseProfile.locationCountry.map((location) => (
            <div className="locationListItem" key={location.uuid}>
              <div className="value">{`${location.abcd && abcdOptions.find((e) => e.key === location.abcd).value} ${location.abcd ? `| ${location.minPopulation}` : location.minPopulation} `}</div>
              <CancelSharpIcon className="btnRemove" onClick={onClickRemove(location.uuid)} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default Country
