import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import assetClassConditions from './config/AssetClass/assetClass'
import projectTypeConditions from './config/AssetClass/projectType'
import investmentVolumeConditions from './config/AssetClass/investmentVolume'
import portfolioInvestmentVolumeConditions from './config/AssetClass/portfolioInvestmentVolume'
import totalInvestmentConditions from './config/AssetClass/totalInvestment'
import typeOfUsageConditions from './config/AssetClass/typeOfUsage'
import plotPriceConditions from './config/AssetClass/plotPrice'
import objectTypeConditions from './config/AssetClass/objectType'
import dealCriteriaConditions from './config/AssetClass/dealCriteria'
import fixedRentalPriceConditions from './config/YieldAndObjectCriteria/fixedRentalPrice'
import fixedRentalPriceTimespanConditions from './config/YieldAndObjectCriteria/fixedRentalPriceTimespan'
import plotConditions from './config/YieldAndObjectCriteria/plot'
import objectConditionConditions from './config/YieldAndObjectCriteria/objectCondition'
import specialRightsConditions from './config/YieldAndObjectCriteria/specialRights'
import residentialAndCommercialBuildingConditions from './config/YieldAndObjectCriteria/residentialAndCommercialBuilding'
import moreTypesOfUseConditions from './config/YieldAndObjectCriteria/moreTypesOfUse'
import buildingLawSituationConditions from './config/YieldAndObjectCriteria/buildingLawSituation'
import constructionSituationConditions from './config/YieldAndObjectCriteria/constructionSituation'
import constructionMethodConditions from './config/YieldAndObjectCriteria/constructionMethod'
import singleTenantConditions from './config/YieldAndObjectCriteria/singleTenant'
import singleTenantRunningTimeConditions from './config/YieldAndObjectCriteria/singleTenantRunningTime'
import multiTenantConditions from './config/YieldAndObjectCriteria/multiTenant'
import multiTenantRunningTimeConditions from './config/YieldAndObjectCriteria/multiTenantRunningTime'
import anchorTenantConditions from './config/YieldAndObjectCriteria/anchorTenant'
import anchorTenantSplitConditions from './config/YieldAndObjectCriteria/anchorTenantSplit'
import usableAreaConditions from './config/YieldAndObjectCriteria/usableArea'
import roomsConditions from './config/YieldAndObjectCriteria/rooms'
import bedsConditions from './config/YieldAndObjectCriteria/beds'
import hotelConditions from './config/YieldAndObjectCriteria/hotel'
import operatorContractConditions from './config/YieldAndObjectCriteria/operatorContract'
import unitsConditions from './config/YieldAndObjectCriteria/units'
import retailConditions from './config/YieldAndObjectCriteria/retail'
import generalObjectCriteriaConditions from './config/YieldAndObjectCriteria/generalObjectCriteria'
import rentalStatusConditions from './config/YieldAndObjectCriteria/rentalStatus'
import factorAndReturnConditions from './config/YieldAndObjectCriteria/factorAndReturn'
import constructionYearConditions from './config/YieldAndObjectCriteria/constructionYear'
import requiredDocumentsConditions from './config/ExtendedCriteria/requiredDocuments'
import freeTagsConditions from './config/ExtendedCriteria/freeTags'
import notesConditions from './config/ExtendedCriteria/notes'
import abcdSelectionConditions from './config/LocationCriteria/abcdSelection'
import cityConditions from './config/LocationCriteria/city'
import minPopulationConditions from './config/LocationCriteria/minPopulation'
import radiusConditions from './config/LocationCriteria/radius'
import stateConditions from './config/LocationCriteria/state'
import typeOfFinancingConditions from './config/AssetClass/typeOfFinancing'
import mezzanineLoanConditions from './config/AssetClass/mezzanineLoan'
import seniorLoanConditions from './config/AssetClass/seniorLoan'
import wholeLoanConditions from './config/AssetClass/wholeLoan'
import defaultState from '../../../../../reducer/defaultState'
import { setLocal } from '../../../../../redux/action/local'
import props from '../../../../../redux/props'

const Condition = ({ defaultStateName, Component, widthInPercent }) => {
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const translations = useSelector((state) => state.translations)
  const [visiblePayload, setVisiblePayload] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const evaluate = (object, conditionSet) => {
      return conditionSet.reduce((acc, set) => {
        // multiple conditionSets are treated with "and"
        if (acc === false) return false
        const key = Object.keys(set)[0]
        const valueType = typeof set[key]
        if (valueType === 'boolean') {
          // check if the boolean is equal to the expected one
          return set[key] === object[key]
        } else if (valueType === 'string') {
          if (Array.isArray(object[key])) {
            // check if the condition string is included within the object's array
            return object[key].includes(set[key])
          } else {
            // check if the string is equal to the expected one
            return object[key] === set[key]
          }
        } else if (valueType === 'object') {
          // check if value is NOT the expected value (!== undefined required because '' is also false)
          if (set[key]['$ne'] !== undefined) {
            // if the object value is an array
            if (Array.isArray(object[key])) {
              // if the object array is zero the $ne value is always false
              if (object[key].length === 0) return false
              // non of the object array values must match the value given by $ne
              return object[key].reduce((acc, value) => {
                if (!acc) return acc
                return value !== set[key]['$ne']
              }, true)
            } else {
              // if the $ne value and the object value is a string simple check if they're not matching
              return object[key] !== set[key]['$ne']
            }
            // check if value is included within an object or an array (!== undefined required because '' is also false)
          } else if (set[key]['$in'] !== undefined) {
            if (typeof set[key]['$in'] === 'object' && !Array.isArray(set[key]['$in'])) {
              // returns matching value if the value is included within the object
              return set[key]['$in'][object[key]]
            } else if (Array.isArray(set[key]['$in'])) {
              // translate a single item to an array for the following comparision
              const valueArray = Array.isArray(object[key]) ? object[key] : [object[key]]
              // returns true/false if at least one array item is included within the condition array
              return valueArray.reduce((acc, value) => {
                if (acc === true) return acc
                return set[key]['$in'].includes(value)
              }, false)
            }
          } else {
            // check if subobject value is matching
            return evaluate(object[key], [set[key]])
          }
        }
        return false
      }, true)
    }

    if (translations && defaultStateName && purchaseProfile) {
      const conditionMapping = {
        assetClass: assetClassConditions,
        projectType: projectTypeConditions,
        investmentVolume: investmentVolumeConditions,
        portfolioInvestmentVolume: portfolioInvestmentVolumeConditions,
        totalInvestment: totalInvestmentConditions,
        typeOfUsage: typeOfUsageConditions,
        plotPrice: plotPriceConditions,
        objectType: objectTypeConditions,
        dealCriteria: dealCriteriaConditions,
        fixedRentalPrice: fixedRentalPriceConditions,
        fixedRentalPriceTimespan: fixedRentalPriceTimespanConditions,
        plot: plotConditions,
        objectCondition: objectConditionConditions,
        specialRights: specialRightsConditions,
        residentialAndCommercialBuilding: residentialAndCommercialBuildingConditions,
        moreTypesOfUse: moreTypesOfUseConditions,
        buildingLawSituation: buildingLawSituationConditions,
        constructionSituation: constructionSituationConditions,
        constructionMethod: constructionMethodConditions,
        singleTenant: singleTenantConditions,
        singleTenantRunningTime: singleTenantRunningTimeConditions,
        multiTenant: multiTenantConditions,
        multiTenantRunningTime: multiTenantRunningTimeConditions,
        anchorTenant: anchorTenantConditions,
        anchorTenantSplit: anchorTenantSplitConditions,
        requiredDocuments: requiredDocumentsConditions,
        freeTags: freeTagsConditions,
        notes: notesConditions,
        usableArea: usableAreaConditions,
        rooms: roomsConditions,
        beds: bedsConditions,
        hotel: hotelConditions,
        operatorContract: operatorContractConditions,
        units: unitsConditions,
        retail: retailConditions,
        generalObjectCriteria: generalObjectCriteriaConditions,
        rentalStatus: rentalStatusConditions,
        factorAndReturn: factorAndReturnConditions,
        constructionYear: constructionYearConditions,
        abcdSelection: abcdSelectionConditions,
        city: cityConditions,
        minPopulation: minPopulationConditions,
        radius: radiusConditions,
        state: stateConditions,
        typeOfFinancing: typeOfFinancingConditions,
        mezzanineLoan: mezzanineLoanConditions,
        seniorLoan: seniorLoanConditions,
        wholeLoan: wholeLoanConditions,
      }
      if (conditionMapping[defaultStateName]) {
        const conditionsDoMatch = evaluate(purchaseProfile, conditionMapping[defaultStateName](translations))
        if (!conditionsDoMatch && purchaseProfile[defaultStateName] !== defaultState.purchaseProfile[defaultStateName]) {
          // dispatch(setNewPurchaseProfile({ ...purchaseProfile, [defaultStateName]: defaultState.purchaseProfile[defaultStateName] }))
          dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, [defaultStateName]: defaultState.purchaseProfile[defaultStateName] }))
        }
        setVisiblePayload(conditionsDoMatch)
      } else {
        setVisiblePayload(true)
      }
    }
  }, [defaultStateName, purchaseProfile, translations, dispatch])

  if (!visiblePayload) return <></>
  return <Component payload={visiblePayload} widthInPercent={widthInPercent} />
}

export default Condition
