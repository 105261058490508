const conditions = () => {
  return [
    { assetClass: { $ne: '' } },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.wohnen': { show: true },
          'object.asset-class.options.handel-retail': { show: true },
          'object.asset-class.options.buero': { show: true },
          'object.asset-class.options.hotel': { show: true },
          'object.asset-class.options.logistik-industrie': { show: false },
          'object.asset-class.options.healthcare-medical': { show: false },
          'object.asset-class.options.micro-und-serviced-living': { show: false },
        },
      },
    },
  ]
}

export default conditions
