import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const Searched = ({ widthInPercent, payload }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const [selections, setSelections] = useState([])
  const dispatch = useDispatch()

  const update = (value) => {
    const updatePurchaseProfile = { ...newObject, searched: value }
    // dispatch(setNewObject(updatePurchaseProfile))
    dispatch(setLocal(props.OBJECT, updatePurchaseProfile))
  }

  useEffect(() => {
    const localSelections = settings.getGroup(payload)

    if (newObject.searched && Object.keys(newObject.searched).length !== 0) {
      const selected = localSelections.filter((s) => newObject.searched.includes(s.key)).map((s) => s.key)
      // dispatch(setNewObject({ ...newObject, searched: selected }))
      dispatch(setLocal(props.OBJECT, { ...newObject, searched: selected }))
    }
    setSelections(localSelections)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  if (!settings || !newObject || !translations) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.searched.label']}</div>
          <div className="values">
            <RadioGroup
              name={translations['object.searched.projectdeveloper']}
              options={selections.map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.searched}
              onChange={update}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Searched
