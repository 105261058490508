import { useDispatch, useSelector } from 'react-redux'
import { setNewObject } from '../../../../../../action/newObject'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const Investment = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...newObject, investment: { ...newObject.investment, [key]: value } }
    // dispatch(setNewObject(updatePurchaseProfile))
    dispatch(setLocal(props.OBJECT, updatePurchaseProfile))
  }

  const updateRentalGuarantee = (key) => (value) => {
    dispatch(
      setNewObject({
        ...newObject,
        investment: {
          ...newObject.investment,
          rentalGuarantee: {
            ...newObject.investment.rentalGuarantee,
            [key]: value,
          },
        },
      }),
    )
  }

  if (!settings || !newObject || !translations) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.deal-criteria.investment']}</div>
          <div className="values">
            <Input
              type="customNumber"
              value={newObject.investment.buyingPrice}
              onChange={update('buyingPrice')}
              placeholder={translations['my-objects.pipeline.investment.buyingprice']}
              symbol={translations['currency.string']}
            />
            <Input
              type="customNumber"
              value={newObject.investment.propertyTransferTax}
              onChange={update('propertyTransferTax')}
              placeholder={translations['my-objects.pipeline.investment.propertytransfertax']}
              symbol={translations['currency.string']}
            />
            <Input
              type="customNumber"
              value={newObject.investment.notaryFees}
              onChange={update('notaryFees')}
              placeholder={translations['my-objects.pipeline.investment.notaryfees']}
              symbol={translations['currency.string']}
            />
            {payload && payload.show && (
              <Input
                type="customNumber"
                value={newObject.investment.landRegisterCosts}
                onChange={update('landRegisterCosts')}
                placeholder={translations['my-objects.pipeline.investment.landregistercosts']}
                symbol={translations['currency.string']}
              />
            )}
            <Input
              type="customNumber"
              value={newObject.investment.agencyFee}
              onChange={update('agencyFee')}
              placeholder={translations['my-objects.pipeline.investment.agencyfee']}
              symbol={translations['currency.string']}
            />
            <Input
              type="customNumber"
              value={newObject.investment.pairingFee}
              onChange={update('pairingFee')}
              placeholder={translations['my-objects.pipeline.investment.pairingfee']}
              symbol={translations['currency.string']}
            />
            <Input type="number" value={newObject.investment.faktor} onChange={update('faktor')} placeholder={translations['my-objects.pipeline.investment.faktor']} />
            <Input type="number" value={newObject.investment.yield} onChange={update('yield')} placeholder={translations['my-objects.pipeline.investment.yield']} />
            <Input type="customNumber" value={newObject.investment.groundValue} onChange={update('groundValue')} placeholder={translations['my-objects.pipeline.investment.groundvalue']} />
            <RadioGroup
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.investment.rentalGuaranteeSelection}
              onChange={update('rentalGuaranteeSelection')}
              name={translations['my-objects.pipeline.investment.rentalguaranteeselection']}
            />
            {newObject.investment.rentalGuaranteeSelection === 'radiogroup.default.options.yes' && (
              <>
                <Input
                  type="text"
                  value={newObject.investment.rentalGuarantee.from}
                  onChange={updateRentalGuarantee('from')}
                  placeholder={translations['my-objects.pipeline.investment.rentalguarantee.from']}
                />
                <Input
                  type="text"
                  value={newObject.investment.rentalGuarantee.until}
                  onChange={updateRentalGuarantee('until')}
                  placeholder={translations['my-objects.pipeline.investment.rentalguarantee.to']}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Investment
