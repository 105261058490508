import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const Administration = ({ widthInPercent, payload }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    dispatch(setLocal(props.OBJECT, { ...newObject, administration: { ...newObject.administration, [key]: value } }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.administration']}</div>
        <div className="values">
          <Input type="number" value={newObject.administration.costs} onChange={update('costs')} placeholder={translations['object.administration.costs']} />
        </div>
      </div>
    </div>
  )
}

export default Administration
