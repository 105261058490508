import styled from 'styled-components'

export const StyledCard = styled.div`
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0px 5px 8px -1px rgba(21, 44, 87, 0.39);
  background-color: #ffffff;
  padding: 15px;
`

export const Header = styled.div`
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
export const Section = styled.div`
  width: 33%;
  max-width: 33%;
  padding: 0px 20px;

  display: flex;
  grid-gap: 25px;
  gap: 25px;
  flex-wrap: wrap;

  :nth-child(2) {
    border-left: 2px solid rgb(217, 217, 217);
    border-right: 2px solid rgb(217, 217, 217);
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Item = styled.div`
  width: calc(50% - 25px);
  /* flex: 0 1 0px; */
`
export const Headline = styled.div`
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 21px;
  color: rgb(21, 44, 87);
  font-family: 'Stolzl-Bold';
`
export const SubHeadline = styled.div`
  font-size: 15px;
`

export const Key = styled.div`
  font-size: 18px;
  color: rgb(21, 44, 87);
`
export const Value = styled.div`
  font-size: 15px;
`
