import { useSelector } from 'react-redux'
import { useState } from 'react'
import './Storage.scss'
import { getUUID } from '../../../utility'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useEffect } from 'react'
import File from './File/File'
import Upload from './Upload/Upload'
import { list as crudList } from '../../../interface/crud'
import props from '../../../redux/props'

const Storage = ({ objectUUID, pushFiles }) => {
  const settings = useSelector((state) => state.settings)
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)

  const [selectedFolder, setSelectedFolder] = useState(null)
  const [documentTypeOptions, setDocumentTypeOptions] = useState(null)
  const [selectedDocumentType, setSelectedDocumentType] = useState(null)
  const [specificationOptions, setSpecificationOptions] = useState(null)
  const [selectedSpecification, setSelectedSpecification] = useState(null)
  const [files, setFiles] = useState(null)
  const folderGroup = 'storage.folder.options'
  const [folderWhitelist, setFolderWhitelist] = useState(null)
  const [documentTypeWhitelist, setDocumentTypeWhitelist] = useState(null)
  const [specificationWhitelist, setSpecificationWhitelist] = useState(null)

  useEffect(() => {
    const documentTypeGroupMapping = {
      'storage.folder.options.building-regulations': 'storage.document-type.options.building-regulations',
      'storage.folder.options.building-planning-law': 'storage.document-type.options.building-planning-law',
      'storage.folder.options.management': 'storage.document-type.options.management',
      'storage.folder.options.accounting': 'storage.document-type.options.accounting',
      'storage.folder.options.property': 'storage.document-type.options.property',
      'storage.folder.options.introduction': 'storage.document-type.options.introduction',
      'storage.folder.options.financing': 'storage.document-type.options.financing',
      'storage.folder.options.building': 'storage.document-type.options.building',
      'storage.folder.options.plot': 'storage.document-type.options.plot',
      'storage.folder.options.commercial-documents': 'storage.document-type.options.commercial-documents',
      'storage.folder.options.renter': 'storage.document-type.options.renter',
      'storage.folder.options.litigation': 'storage.document-type.options.litigation',
    }

    const specificationGroupMapping = {
      'storage.document-type.options.building-regulations.building-permit': 'storage.specification.options.building-permit',
      'storage.document-type.options.building-planning-law.official-certificates': 'storage.specification.options.official-certificates',
      'storage.document-type.options.management.management-contracts': 'storage.specification.options.management-contracts',
      'storage.document-type.options.accounting.outgoing-invoice': 'storage.specification.options.outgoing-invoice',
      'storage.document-type.options.accounting.incoming-invoice': 'storage.specification.options.incoming-invoice',
      'storage.document-type.options.building.fire-protection': 'storage.specification.options.fire-protection',
      'storage.document-type.options.building.building-construction-status-analysis': 'storage.specification.options.building-construction-status-analysis',
      'storage.document-type.options.plot.foundation-building-site-appraisal': 'storage.specification.options.foundation-building-site-appraisal',
      'storage.document-type.options.plot.environment': 'storage.specification.options.environment',
    }

    const generateStructureWhitelist = (files) => {
      const folderWhitelist = []
      const documentTypeWhitelist = {}
      const specificationWhitelist = {}
      const isOwnerView = typeof pushFiles === 'function'
      const folders = settings.getGroup(folderGroup)
      for (const folder of folders) {
        if (files.find((file) => file.folderKey === folder.key) || isOwnerView) {
          folderWhitelist.push(folder)
        }
      }
      for (const folder of folderWhitelist) {
        if (documentTypeGroupMapping[folder.key]) {
          const documentTypes = settings.getGroup(documentTypeGroupMapping[folder.key])
          for (const documentType of documentTypes) {
            if (files.find((file) => file.documentTypeKey === documentType.key) || isOwnerView) {
              documentTypeWhitelist[folder.key] = documentTypeGroupMapping[folder.key]
              if (specificationGroupMapping[documentType.key]) {
                const specifications = settings.getGroup(specificationGroupMapping[documentType.key])
                for (const specification of specifications) {
                  if (files.find((file) => file.specificationKey === specification.key) || isOwnerView) {
                    specificationWhitelist[documentType.key] = specificationGroupMapping[documentType.key]
                  }
                }
              }
            }
          }
        }
      }
      setFolderWhitelist(folderWhitelist)
      setDocumentTypeWhitelist(documentTypeWhitelist)
      setSpecificationWhitelist(specificationWhitelist)
    }

    const getFiles = async () => {
      const files = await crudList(token, props.STORAGE, { objectUUID })
      // const { status, files } = await fetch('/storage/get-files', {
      //   token,
      //   objectUUID,
      // })
      if (files) {
        setFiles(files)
        generateStructureWhitelist(files)
      }
    }
    if (token && objectUUID && !files) {
      getFiles()
    } else {
      generateStructureWhitelist([])
    }
  }, [objectUUID, files, token, pushFiles, settings])

  const updateFolder = (folder) => {
    setSelectedFolder(folder)
    setSelectedDocumentType(null)
    setDocumentTypeOptions(null)
    setSelectedSpecification(null)
    setSpecificationOptions(null)
    if (!folder) return
    const matchingDocumentTypeGroup = documentTypeWhitelist[folder.key]
    if (matchingDocumentTypeGroup) {
      setDocumentTypeOptions(settings.getGroup(matchingDocumentTypeGroup))
    }
  }

  const updateDocumentType = (documentType) => {
    setSelectedDocumentType(documentType)
    setDocumentTypeOptions(null)
    setSelectedSpecification(null)
    setSpecificationOptions(null)
    const matchingSpecificationGroup = specificationWhitelist[documentType.key]
    if (matchingSpecificationGroup) {
      setSpecificationOptions(settings.getGroup(matchingSpecificationGroup))
    }
  }

  const getBreakcrumbItems = () => {
    const items = [
      {
        translationKey: 'storage.breakcrumbs.home',
        trigger: () => updateFolder(null),
      },
    ]
    if (selectedFolder && selectedDocumentType && selectedSpecification) {
      items.push({
        translationKey: selectedFolder.translationKey,
        trigger: () => updateFolder(selectedFolder),
      })
      items.push({
        translationKey: selectedDocumentType.translationKey,
        trigger: () => updateDocumentType(selectedDocumentType),
      })
      items.push({ translationKey: selectedSpecification.translationKey })
    } else if (selectedFolder && selectedDocumentType) {
      items.push({
        translationKey: selectedFolder.translationKey,
        trigger: () => updateFolder(selectedFolder),
      })
      items.push({ translationKey: selectedDocumentType.translationKey })
    } else if (selectedFolder) {
      items.push({ translationKey: selectedFolder.translationKey })
    }
    return items
  }

  const list = (items, clickFunction) => {
    if (Array.isArray(items) && items.length > 0) {
      return (
        <div className="list">
          {items.map((item) => (
            <div className="item" key={getUUID()} onClick={() => clickFunction(item)}>
              {translations[item.translationKey]}
            </div>
          ))}
        </div>
      )
    }
    return <></>
  }

  const loadFiles = () => {
    if (files && Array.isArray(files)) {
      return files.filter(
        (file) => file.folderKey === selectedFolder.key && file.documentTypeKey === (selectedDocumentType?.key || null) && file.specificationKey === (selectedSpecification?.key || null),
      )
    }
    return []
  }

  const addFiles = (inputFiles) => {
    const newFiles = [...(files || []), ...inputFiles]
    setFiles(newFiles)
    pushFiles(newFiles)
  }

  const removeFile = (file) => {
    const newFiles = files.filter(
      (f) =>
        f.filename !== file.filename || f.filesize !== file.filesize || f.folderKey !== file.folderKey || f.documentTypeKey !== file.documentTypeKey || f.specificationKey !== file.specificationKey,
    )
    setFiles(newFiles)
    pushFiles(newFiles)
  }

  const uploadIsEnabled = () => {
    if (typeof pushFiles !== 'function') return false
    if (!selectedFolder) return false
    else if (selectedFolder && !selectedDocumentType && !selectedSpecification) {
      return !documentTypeWhitelist[selectedFolder.key]
    } else if (selectedFolder && selectedDocumentType && !selectedSpecification) {
      return !specificationWhitelist[selectedDocumentType.key]
    } else if (selectedFolder) return true
  }

  return (
    <div id="storage">
      <div className="breadcrumbs">
        {getBreakcrumbItems().map((item, i) => (
          <div key={getUUID()}>
            {i > 0 && (
              <div className="arrow">
                <ArrowRightIcon />
              </div>
            )}
            <div className="crumb" onClick={item.trigger ? item.trigger : () => {}}>
              {translations[item.translationKey]}
            </div>
          </div>
        ))}
      </div>
      {uploadIsEnabled() && (
        <Upload pushFiles={addFiles} selectedFolder={selectedFolder} selectedDocumentType={selectedDocumentType} selectedSpecification={selectedSpecification} objectUUID={objectUUID} />
      )}
      {!selectedFolder && !selectedDocumentType && !selectedSpecification && list(folderWhitelist, updateFolder)}
      {selectedFolder && !selectedDocumentType && !selectedSpecification && list(documentTypeOptions, updateDocumentType)}
      {selectedFolder && selectedDocumentType && !selectedSpecification && list(specificationOptions, setSelectedSpecification)}
      {selectedFolder && loadFiles().map((file) => <File remove={removeFile} enableDownload={!pushFiles} key={getUUID()} file={file} />)}
    </div>
  )
}

export default Storage
