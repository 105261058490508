import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { list } from '../../../../interface/crud'
import props from '../../../../redux/props'
import './Paused.scss'

const Paused = () => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [objects, setObjects] = useState(null)
  const [error, setError] = useState(false)
  const account = useSelector((s) => s.ACCOUNT)

  useEffect(() => {
    const fetchObjects = async () => {
      const objects = await list(token, props.OBJECT, { userUUID: account.assignedToUUID, active: true })
      if (objects) {
        setObjects(objects)
      } else {
        setError(true)
      }
    }
    if (token && !objects && account) {
      fetchObjects()
    }
  })

  return (
    <div className="my-objects-paused">
      {Array.isArray(objects) && objects.length === 0 ? <div className="message">{translations['new-object.suggestions.no-results']}</div> : ''}
      {error && <div className="message">{translations['my-objects.paused.error']}</div>}
    </div>
  )
}

export default Paused
