const settings = [
  { key: 'radiogroup.default.options.no', application: 'frontend', group: 'radiogroup.default.options', translationKey: 'radiogroup.default.options.no', value: 'Nein' },
  { key: 'radiogroup.default.options.yes', application: 'frontend', group: 'radiogroup.default.options', translationKey: 'radiogroup.default.options.yes', value: 'Ja' },
  {
    key: 'storage.document-type.options.introduction.object-description',
    application: 'frontend',
    group: 'storage.document-type.options.introduction',
    translationKey: 'storage.document-type.options.introduction.object-description',
    value: 'Objektbeschreibung',
  },
  {
    key: 'storage.specification.options.environment.animals',
    application: 'frontend',
    group: 'storage.specification.options.environment',
    translationKey: 'storage.specification.options.environment.animals',
    value: 'Tiere',
  },
  {
    key: 'storage.document-type.options.introduction.photo',
    application: 'frontend',
    group: 'storage.document-type.options.introduction',
    translationKey: 'storage.document-type.options.introduction.photo',
    value: 'Foto',
  },
  {
    key: 'storage.specification.options.environment.plants',
    application: 'frontend',
    group: 'storage.specification.options.environment',
    translationKey: 'storage.specification.options.environment.plants',
    value: 'Pflanzen',
  },
  {
    key: 'storage.specification.options.foundation-building-site-appraisal.ordnance',
    application: 'frontend',
    group: 'storage.specification.options.foundation-building-site-appraisal',
    translationKey: 'storage.specification.options.foundation-building-site-appraisal.ordnance',
    value: 'Kampfmittel',
  },
  {
    key: 'storage.specification.options.foundation-building-site-appraisal.geological-survey-of-the-soil',
    application: 'frontend',
    group: 'storage.specification.options.foundation-building-site-appraisal',
    translationKey: 'storage.specification.options.foundation-building-site-appraisal.geological-survey-of-the-soil',
    value: 'Bodengutachten geologisch',
  },
  {
    key: 'storage.specification.options.environment.contamination',
    application: 'frontend',
    group: 'storage.specification.options.environment',
    translationKey: 'storage.specification.options.environment.contamination',
    value: 'Kontaminierung',
  },
  {
    key: 'storage.specification.options.building-construction-status-analysis.disturbance-notice',
    application: 'frontend',
    group: 'storage.specification.options.building-construction-status-analysis',
    translationKey: 'storage.specification.options.building-construction-status-analysis.disturbance-notice',
    value: 'Behinderungsanzeige',
  },
  {
    key: 'storage.specification.options.foundation-building-site-appraisal.soil-survey-of-contaminated-sites',
    application: 'frontend',
    group: 'storage.specification.options.foundation-building-site-appraisal',
    translationKey: 'storage.specification.options.foundation-building-site-appraisal.soil-survey-of-contaminated-sites',
    value: 'Bodengutachten Altlasten',
  },
  {
    key: 'storage.specification.options.building-construction-status-analysis.appraisal',
    application: 'frontend',
    group: 'storage.specification.options.building-construction-status-analysis',
    translationKey: 'storage.specification.options.building-construction-status-analysis.appraisal',
    value: 'Wertgutachten',
  },
  {
    key: 'storage.specification.options.fire-protection.key-fire-department',
    application: 'frontend',
    group: 'storage.specification.options.fire-protection',
    translationKey: 'storage.specification.options.fire-protection.key-fire-department',
    value: 'Schlüssel Feuerwehr',
  },
  {
    key: 'storage.specification.options.building-construction-status-analysis.site-manager-report',
    application: 'frontend',
    group: 'storage.specification.options.building-construction-status-analysis',
    translationKey: 'storage.specification.options.building-construction-status-analysis.site-manager-report',
    value: 'Bauleiterbericht',
  },
  {
    key: 'storage.specification.options.building-construction-status-analysis.construction-diary',
    application: 'frontend',
    group: 'storage.specification.options.building-construction-status-analysis',
    translationKey: 'storage.specification.options.building-construction-status-analysis.construction-diary',
    value: 'Bautagebuch',
  },
  {
    key: 'storage.specification.options.incoming-invoice.insurance',
    application: 'frontend',
    group: 'storage.specification.options.incoming-invoice',
    translationKey: 'storage.specification.options.incoming-invoice.insurance',
    value: 'Versicherung',
  },
  {
    key: 'storage.specification.options.fire-protection.exams',
    application: 'frontend',
    group: 'storage.specification.options.fire-protection',
    translationKey: 'storage.specification.options.fire-protection.exams',
    value: 'Prüfungen',
  },
  {
    key: 'storage.specification.options.fire-protection.instruction',
    application: 'frontend',
    group: 'storage.specification.options.fire-protection',
    translationKey: 'storage.specification.options.fire-protection.instruction',
    value: 'Unterweisung',
  },
  {
    key: 'storage.specification.options.fire-protection.valuation',
    application: 'frontend',
    group: 'storage.specification.options.fire-protection',
    translationKey: 'storage.specification.options.fire-protection.valuation',
    value: 'Bewertung',
  },
  {
    key: 'storage.specification.options.incoming-invoice.maintenance',
    application: 'frontend',
    group: 'storage.specification.options.incoming-invoice',
    translationKey: 'storage.specification.options.incoming-invoice.maintenance',
    value: 'Wartung',
  },
  {
    key: 'storage.specification.options.fire-protection.concept',
    application: 'frontend',
    group: 'storage.specification.options.fire-protection',
    translationKey: 'storage.specification.options.fire-protection.concept',
    value: 'Konzept',
  },
  {
    key: 'storage.specification.options.fire-protection.perpetration',
    application: 'frontend',
    group: 'storage.specification.options.fire-protection',
    translationKey: 'storage.specification.options.fire-protection.perpetration',
    value: 'Begehung',
  },
  {
    key: 'storage.specification.options.incoming-invoice.repair',
    application: 'frontend',
    group: 'storage.specification.options.incoming-invoice',
    translationKey: 'storage.specification.options.incoming-invoice.repair',
    value: 'Reparatur',
  },
  {
    key: 'storage.specification.options.incoming-invoice.others',
    application: 'frontend',
    group: 'storage.specification.options.incoming-invoice',
    translationKey: 'storage.specification.options.incoming-invoice.others',
    value: 'Sonstige',
  },
  {
    key: 'storage.specification.options.incoming-invoice.invest',
    application: 'frontend',
    group: 'storage.specification.options.incoming-invoice',
    translationKey: 'storage.specification.options.incoming-invoice.invest',
    value: 'Invest',
  },
  {
    key: 'storage.specification.options.incoming-invoice.rent',
    application: 'frontend',
    group: 'storage.specification.options.incoming-invoice',
    translationKey: 'storage.specification.options.incoming-invoice.rent',
    value: 'Miete',
  },
  {
    key: 'storage.specification.options.outgoing-invoice.others',
    application: 'frontend',
    group: 'storage.specification.options.outgoing-invoice',
    translationKey: 'storage.specification.options.outgoing-invoice.others',
    value: 'Sonstige',
  },
  {
    key: 'storage.specification.options.outgoing-invoice.meter-load-to-tenants',
    application: 'frontend',
    group: 'storage.specification.options.outgoing-invoice',
    translationKey: 'storage.specification.options.outgoing-invoice.meter-load-to-tenants',
    value: 'Meterbelastung an Mieter',
  },
  {
    key: 'storage.specification.options.management-contracts.winter-service',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.winter-service',
    value: 'Winterdienst',
  },
  {
    key: 'storage.specification.options.outgoing-invoice.rental-period-invoice',
    application: 'frontend',
    group: 'storage.specification.options.outgoing-invoice',
    translationKey: 'storage.specification.options.outgoing-invoice.rental-period-invoice',
    value: 'Mietdauerrechnung',
  },
  {
    key: 'storage.specification.options.official-certificates.grant-notification',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.grant-notification',
    value: 'Zuwendungsbescheid',
  },
  {
    key: 'storage.specification.options.management-contracts.garden-maintenance',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.garden-maintenance',
    value: 'Gartenpflege',
  },
  {
    key: 'storage.specification.options.management-contracts.meter-reading',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.meter-reading',
    value: 'Zählerablesung',
  },
  {
    key: 'storage.specification.options.management-contracts.caretaker-service',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.caretaker-service',
    value: 'Hausmeisterservice',
  },
  {
    key: 'storage.specification.options.management-contracts.elevator-maintenance',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.elevator-maintenance',
    value: 'Aufzugswartung',
  },
  {
    key: 'storage.specification.options.management-contracts.maintenance',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.maintenance',
    value: 'Wartung',
  },
  {
    key: 'storage.specification.options.official-certificates.division-permit',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.division-permit',
    value: 'Teilungsgenehmigung',
  },
  {
    key: 'storage.specification.options.official-certificates.use-permit',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.use-permit',
    value: 'Nutzungsgenehmigung',
  },
  {
    key: 'storage.specification.options.official-certificates.heat-pump',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.heat-pump',
    value: 'Wärmepumpe',
  },
  {
    key: 'storage.specification.options.official-certificates.shell-acceptance',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.shell-acceptance',
    value: 'Rohbauabnahme',
  },
  {
    key: 'storage.specification.options.official-certificates.rainwater',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.rainwater',
    value: 'Niederschlagswasser',
  },
  {
    key: 'storage.specification.options.official-certificates.others',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.others',
    value: 'Sonstige',
  },
  {
    key: 'storage.specification.options.official-certificates.geothermal-probe',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.geothermal-probe',
    value: 'Erdwärmesonde',
  },
  {
    key: 'storage.specification.options.official-certificates.completion-notice',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.completion-notice',
    value: 'Fertigstellungsbescheid',
  },
  {
    key: 'storage.specification.options.official-certificates.resident-certificate',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.resident-certificate',
    value: 'Anliegerbescheinigung',
  },
  {
    key: 'storage.specification.options.official-certificates.contaminated-sites',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.contaminated-sites',
    value: 'Altlasten',
  },
  {
    key: 'storage.specification.options.official-certificates.construction-load',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.construction-load',
    value: 'Baulast',
  },
  {
    key: 'storage.specification.options.building-permit.statics',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.statics',
    value: 'Statik',
  },
  {
    key: 'storage.specification.options.building-permit.building-structure-inspection',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.building-structure-inspection',
    value: 'Bausubstanzbesichtigung',
  },
  {
    key: 'storage.specification.options.building-permit.preliminary-inquiry',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.preliminary-inquiry',
    value: 'Voranfrage',
  },
  {
    key: 'storage.specification.options.building-permit.change-of-use',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.change-of-use',
    value: 'Nutzungsänderung',
  },
  {
    key: 'storage.specification.options.building-permit.start-of-building',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.start-of-building',
    value: 'Baubeginn',
  },
  {
    key: 'storage.specification.options.building-permit.preliminary-decision',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.preliminary-decision',
    value: 'Vorbescheid',
  },
  {
    key: 'storage.specification.options.building-permit.deviation',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.deviation',
    value: 'Abweichung',
  },
  {
    key: 'storage.specification.options.building-permit.permit',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.permit',
    value: 'Genehmigung',
  },
  {
    key: 'storage.specification.options.building-permit.application',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.application',
    value: 'Antrag',
  },
  {
    key: 'storage.specification.options.building-permit.cancellation',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.cancellation',
    value: 'Abbruch',
  },
  {
    key: 'storage.document-type.options.litigation.events',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.events',
    value: 'Termine',
  },
  {
    key: 'storage.document-type.options.litigation.general-correspondence',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.general-correspondence',
    value: 'Allgemeine Korrespondenz',
  },
  {
    key: 'storage.document-type.options.litigation.other-documents-contracts-agreements',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.other-documents-contracts-agreements',
    value: 'Sonstige Dokumente / Verträge / Vereinbarungen',
  },
  {
    key: 'storage.document-type.options.litigation.enforcement-order',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.enforcement-order',
    value: 'Vollstreckungsbescheid',
  },
  {
    key: 'storage.document-type.options.litigation.cost-fixing-decision',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.cost-fixing-decision',
    value: 'Kostenfestsetzungsbeschluss',
  },
  {
    key: 'storage.document-type.options.litigation.notification-of-defects',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.notification-of-defects',
    value: 'Mängelrüge',
  },
  {
    key: 'storage.document-type.options.litigation.verdict',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.verdict',
    value: 'Urteil',
  },
  {
    key: 'storage.document-type.options.litigation.statement-of-defense',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.statement-of-defense',
    value: 'Klageerwiderung',
  },
  {
    key: 'storage.document-type.options.litigation.court-costs',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.court-costs',
    value: 'Gerichtskosten',
  },
  {
    key: 'storage.document-type.options.litigation.assessment',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.assessment',
    value: 'Gutachten',
  },
  {
    key: 'storage.document-type.options.litigation.legal-action',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.legal-action',
    value: 'Klage',
  },
  {
    key: 'storage.document-type.options.renter.important-correspondence',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.important-correspondence',
    value: 'Wichtiger Schriftverkehr',
  },
  {
    key: 'storage.document-type.options.renter.overview-of-tenancies-usage-relationships',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.overview-of-tenancies-usage-relationships',
    value: 'Übersicht Mietverhältnisse / Nutzungsverhältnisse',
  },
  {
    key: 'storage.document-type.options.litigation.guarantees',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.guarantees',
    value: 'Bürgschaften',
  },
  {
    key: 'storage.document-type.options.renter.handover-protocols',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.handover-protocols',
    value: 'Übergabeprotokolle',
  },
  {
    key: 'storage.document-type.options.renter.rental-agreements-including-supplements',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.rental-agreements-including-supplements',
    value: 'Mietverträge (inkl. Nachträge)',
  },
  {
    key: 'storage.document-type.options.renter.rent-arrears',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.rent-arrears',
    value: 'Mietrückstände',
  },
  {
    key: 'storage.document-type.options.renter.rent-adjustments-long-term-rent-invoice',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.rent-adjustments-long-term-rent-invoice',
    value: 'Mietanpassungen / Dauermietrechnung',
  },
  {
    key: 'storage.document-type.options.renter.tenant-security',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.tenant-security',
    value: 'Mietersicherheiten',
  },
  {
    key: 'storage.document-type.options.renter.invoice-for-additional-costs',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.invoice-for-additional-costs',
    value: 'Nebenkostenabrechnung',
  },
  {
    key: 'storage.document-type.options.renter.terminations',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.terminations',
    value: 'Kündigungen',
  },
  {
    key: 'storage.document-type.options.renter.house-rule',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.house-rule',
    value: 'Hausordnung',
  },
  {
    key: 'storage.document-type.options.commercial-documents.offer',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.offer',
    value: 'Angebot',
  },
  {
    key: 'storage.document-type.options.commercial-documents.warning',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.warning',
    value: 'Mahnung',
  },
  {
    key: 'storage.document-type.options.commercial-documents.invoice',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.invoice',
    value: 'Rechnung',
  },
  {
    key: 'storage.document-type.options.plot.superstructures',
    application: 'frontend',
    group: 'storage.document-type.options.plot',
    translationKey: 'storage.document-type.options.plot.superstructures',
    value: 'Überbauungen',
  },
  {
    key: 'storage.document-type.options.commercial-documents.announcement',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.announcement',
    value: 'Ausschreibung',
  },
  {
    key: 'storage.document-type.options.plot.foundation-building-site-appraisal',
    application: 'frontend',
    group: 'storage.document-type.options.plot',
    translationKey: 'storage.document-type.options.plot.foundation-building-site-appraisal',
    value: 'Gründungs- / Baugrundgutachten',
  },
  {
    key: 'storage.document-type.options.commercial-documents.payment-order',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.payment-order',
    value: 'Mahnbescheid',
  },
  {
    key: 'storage.document-type.options.commercial-documents.order-confirmation',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.order-confirmation',
    value: 'Auftragsbestätigung',
  },
  {
    key: 'storage.document-type.options.plot.environment',
    application: 'frontend',
    group: 'storage.document-type.options.plot',
    translationKey: 'storage.document-type.options.plot.environment',
    value: 'Umwelt',
  },
  {
    key: 'storage.document-type.options.building.area-measurement',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.area-measurement',
    value: 'Flächenaufmaß',
  },
  {
    key: 'storage.document-type.options.plot.ground-value-card',
    application: 'frontend',
    group: 'storage.document-type.options.plot',
    translationKey: 'storage.document-type.options.plot.ground-value-card',
    value: 'Bodenrichtwertkarte',
  },
  {
    key: 'storage.document-type.options.plot.land-map-cadastre-official-site-plan',
    application: 'frontend',
    group: 'storage.document-type.options.plot',
    translationKey: 'storage.document-type.options.plot.land-map-cadastre-official-site-plan',
    value: 'Flurkarte / Kataster / amtlicher Lageplan',
  },
  {
    key: 'storage.document-type.options.building.ventilation',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.ventilation',
    value: 'Lüftung',
  },
  {
    key: 'storage.document-type.options.building.fire-protection',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.fire-protection',
    value: 'Brandschutz',
  },
  {
    key: 'storage.document-type.options.building.building-construction-status-analysis',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.building-construction-status-analysis',
    value: 'Gebäude / Bauzustandsanalyse',
  },
  {
    key: 'storage.document-type.options.building.cuts',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.cuts',
    value: 'Schnitte',
  },
  {
    key: 'storage.document-type.options.building.layout',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.layout',
    value: 'Grundriss',
  },
  {
    key: 'storage.document-type.options.building.sanitary',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.sanitary',
    value: 'Sanitär',
  },
  {
    key: 'storage.document-type.options.building.heater',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.heater',
    value: 'Heizung',
  },
  {
    key: 'storage.document-type.options.building.views',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.views',
    value: 'Ansichten',
  },
  {
    key: 'storage.document-type.options.building.climate',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.climate',
    value: 'Klima',
  },
  {
    key: 'storage.document-type.options.building.electric',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.electric',
    value: 'Elektro',
  },
  {
    key: 'storage.document-type.options.building.sustainability',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.sustainability',
    value: 'Nachhaltigkeit (inkl. Energieausweis)',
  },
  {
    key: 'storage.document-type.options.building.warranty-mirror',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.warranty-mirror',
    value: 'Gewährleistungsspiegel',
  },
  {
    key: 'storage.document-type.options.financing.balance-confirmation',
    application: 'frontend',
    group: 'storage.document-type.options.financing',
    translationKey: 'storage.document-type.options.financing.balance-confirmation',
    value: 'Saldenbestätigung',
  },
  {
    key: 'storage.document-type.options.building.maintenance-protocols-revision-documents-operating-instructions',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.maintenance-protocols-revision-documents-operating-instructions',
    value: 'Wartungsprotokolle / Revisionsunterlagen / Bedienungsanleitungen',
  },
  {
    key: 'storage.document-type.options.building.building-description',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.building-description',
    value: 'Baubeschreibung',
  },
  {
    key: 'storage.document-type.options.property.part-ownership',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.part-ownership',
    value: 'Teileigentum',
  },
  {
    key: 'storage.document-type.options.financing.general-correspondence',
    application: 'frontend',
    group: 'storage.document-type.options.financing',
    translationKey: 'storage.document-type.options.financing.general-correspondence',
    value: 'Allgemeine Korrespondenz',
  },
  {
    key: 'storage.document-type.options.building.trade',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.trade',
    value: 'Gewerk',
  },
  {
    key: 'storage.document-type.options.financing.loan-agreement',
    application: 'frontend',
    group: 'storage.document-type.options.financing',
    translationKey: 'storage.document-type.options.financing.loan-agreement',
    value: 'Kreditvertrag',
  },
  {
    key: 'storage.document-type.options.property.land-purchase-agreement',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.land-purchase-agreement',
    value: 'Grundstückskaufvertrag',
  },
  {
    key: 'storage.document-type.options.property.land-register-extract',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.land-register-extract',
    value: 'Grundbuchauszug',
  },
  {
    key: 'storage.document-type.options.property.commercial-register-extract',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.commercial-register-extract',
    value: 'Handelsregisterauszug',
  },
  {
    key: 'storage.document-type.options.accounting.payment-order',
    application: 'frontend',
    group: 'storage.document-type.options.accounting',
    translationKey: 'storage.document-type.options.accounting.payment-order',
    value: 'Mahnbescheid',
  },
  {
    key: 'storage.document-type.options.property.neighborhood-contracts',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.neighborhood-contracts',
    value: 'Nachbarschaftsverträge',
  },
  {
    key: 'storage.document-type.options.accounting.warning',
    application: 'frontend',
    group: 'storage.document-type.options.accounting',
    translationKey: 'storage.document-type.options.accounting.warning',
    value: 'Mahnung',
  },
  {
    key: 'storage.document-type.options.accounting.incoming-invoice',
    application: 'frontend',
    group: 'storage.document-type.options.accounting',
    translationKey: 'storage.document-type.options.accounting.incoming-invoice',
    value: 'Eingangsrechnung',
  },
  {
    key: 'storage.document-type.options.property.leasehold-contracts',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.leasehold-contracts',
    value: 'Erbbaurechtsverträge',
  },
  {
    key: 'storage.document-type.options.management.insurance-damage',
    application: 'frontend',
    group: 'storage.document-type.options.management',
    translationKey: 'storage.document-type.options.management.insurance-damage',
    value: 'Versicherungsschaden',
  },
  {
    key: 'storage.document-type.options.management.management-contracts',
    application: 'frontend',
    group: 'storage.document-type.options.management',
    translationKey: 'storage.document-type.options.management.management-contracts',
    value: 'Bewirtschaftungsverträge',
  },
  {
    key: 'storage.document-type.options.building-planning-law.urban-development-contracts',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.urban-development-contracts',
    value: 'Städtebauliche Verträge',
  },
  {
    key: 'storage.document-type.options.accounting.outgoing-invoice',
    application: 'frontend',
    group: 'storage.document-type.options.accounting',
    translationKey: 'storage.document-type.options.accounting.outgoing-invoice',
    value: 'Ausgangsrechnung',
  },
  {
    key: 'storage.document-type.options.building-planning-law.zoning-plan',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.zoning-plan',
    value: 'Flächennutzungsplan',
  },
  {
    key: 'storage.document-type.options.management.proof-of-insurance',
    application: 'frontend',
    group: 'storage.document-type.options.management',
    translationKey: 'storage.document-type.options.management.proof-of-insurance',
    value: 'Versicherungsnachweise',
  },
  {
    key: 'storage.document-type.options.management.statement-of-operating-costs',
    application: 'frontend',
    group: 'storage.document-type.options.management',
    translationKey: 'storage.document-type.options.management.statement-of-operating-costs',
    value: 'Betriebskostenaufstellung',
  },
  {
    key: 'storage.document-type.options.building-planning-law.development-area',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.development-area',
    value: 'Entwicklungsgebiet/Erhaltungssatzung',
  },
  {
    key: 'storage.document-type.options.building-planning-law.official-certificates',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.official-certificates',
    value: 'Behördliche Bescheinigungen',
  },
  {
    key: 'storage.document-type.options.building-regulations.building-permit',
    application: 'frontend',
    group: 'storage.document-type.options.building-regulations',
    translationKey: 'storage.document-type.options.building-regulations.building-permit',
    value: 'Baugenehmigungen',
  },
  {
    key: 'storage.document-type.options.building-planning-law.monument-protection',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.monument-protection',
    value: 'Denkmalschutz',
  },
  {
    key: 'storage.document-type.options.building-planning-law.development',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.development',
    value: 'Erschließung',
  },
  {
    key: 'storage.document-type.options.building-regulations.other-permits',
    application: 'frontend',
    group: 'storage.document-type.options.building-regulations',
    translationKey: 'storage.document-type.options.building-regulations.other-permits',
    value: 'Andere Genehmigungen',
  },
  {
    key: 'storage.document-type.options.building-regulations.statics',
    application: 'frontend',
    group: 'storage.document-type.options.building-regulations',
    translationKey: 'storage.document-type.options.building-regulations.statics',
    value: 'Statik',
  },
  {
    key: 'storage.document-type.options.building-planning-law.development-plan',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.development-plan',
    value: 'Bebauungsplan',
  },
  { key: 'storage.folder.options.litigation', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.litigation', value: 'Rechtstreitigkeiten' },
  {
    key: 'storage.folder.options.project-cost-estimate',
    application: 'frontend',
    group: 'storage.folder.options',
    translationKey: 'storage.folder.options.project-cost-estimate',
    value: 'Projektkostenschätzung',
  },
  { key: 'storage.folder.options.property', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.property', value: 'Eigentum' },
  {
    key: 'storage.folder.options.commercial-documents',
    application: 'frontend',
    group: 'storage.folder.options',
    translationKey: 'storage.folder.options.commercial-documents',
    value: 'Kaufmännische Unterlagen',
  },
  { key: 'storage.folder.options.building', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.building', value: 'Gebäude' },
  { key: 'storage.folder.options.plot', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.plot', value: 'Grundstück' },
  { key: 'storage.folder.options.renter', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.renter', value: 'Mieter' },
  { key: 'storage.folder.options.introduction', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.introduction', value: 'Einleitung' },
  { key: 'storage.folder.options.financing', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.financing', value: 'Finanzierung' },
  { key: 'storage.folder.options.accounting', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.accounting', value: 'Buchhaltung' },
  { key: 'storage.folder.options.management', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.management', value: 'Bewirtschaftung' },
  {
    key: 'object.type-of-usage.sub-options.micro-und-serviced-living',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.micro-und-serviced-living',
    value: 'Micro- und Serviced Living',
  },
  {
    key: 'object.type-of-usage.sub-options.healthcare',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.healthcare',
    value: 'Healthcare',
  },
  {
    key: 'storage.folder.options.building-regulations',
    application: 'frontend',
    group: 'storage.folder.options',
    translationKey: 'storage.folder.options.building-regulations',
    value: 'Bauordnungsrecht',
  },
  {
    key: 'object.type-of-usage.sub-options.hotel',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.hotel',
    value: 'Hotel',
  },
  {
    key: 'storage.folder.options.building-planning-law',
    application: 'frontend',
    group: 'storage.folder.options',
    translationKey: 'storage.folder.options.building-planning-law',
    value: 'Bauplanungsrecht',
  },
  {
    key: 'object.type-of-usage.sub-options.logistik-industrie',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.logistik-industrie',
    value: 'Logistik / Industrie',
  },
  {
    key: 'object.type-of-usage.options.industriegebiet-gi',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.industriegebiet-gi',
    value: 'Industriegebiet (GI)',
  },
  {
    key: 'object.type-of-usage.sub-options.buero-gewerbe',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.buero-gewerbe',
    value: 'Büro / Gewerbe',
  },
  {
    key: 'object.type-of-usage.options.kerngebiet-mk',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.kerngebiet-mk',
    value: 'Kerngebiet (MK)',
  },
  {
    key: 'object.type-of-usage.sub-options.handel-retail',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.handel-retail',
    value: 'Handel / Retail',
  },
  {
    key: 'object.type-of-usage.options.gewerbegebiet-ge',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.gewerbegebiet-ge',
    value: 'Gewerbegebiet (GE)',
  },
  {
    key: 'object.type-of-usage.sub-options.wohnen',
    application: 'frontend',
    group: 'object.type-of-usage.sub-options',
    translationKey: 'object.type-of-usage.sub-options.wohnen',
    value: 'Wohnen',
  },
  {
    key: 'object.type-of-usage.options.dorfgebiet-md',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.dorfgebiet-md',
    value: 'Dorfgebiet (MD)',
  },
  {
    key: 'object.type-of-usage.options.mischgebiet-mi',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.mischgebiet-mi',
    value: 'Mischgebiet (MI)',
  },
  {
    key: 'object.type-of-usage.options.allgemeines-wohngebiet-wa',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.allgemeines-wohngebiet-wa',
    value: 'Allgemeines Wohngebiet (WA)',
  },
  {
    key: 'object.type-of-usage.options.besonderes-wohngebiet-wb',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.besonderes-wohngebiet-wb',
    value: 'Besonderes Wohngebiet (WB)',
  },
  {
    key: 'my-objects.pipeline.assets-class.investment-volume.portfolio.no',
    application: 'frontend',
    group: 'my-objects.pipeline.assets-class.investment-volume.portfolio',
    translationKey: 'my-objects.pipeline.assets-class.investment-volume.portfolio.no',
    value: 'Nein',
  },
  {
    key: 'object.type-of-usage.options.kleinsiedlungsgebiet-ws',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.kleinsiedlungsgebiet-ws',
    value: 'Kleinsiedlungsgebiet (WS)',
  },
  {
    key: 'object.type-of-usage.options.reines-wohngebiet-wr',
    application: 'frontend',
    group: 'object.type-of-usage.options',
    translationKey: 'object.type-of-usage.options.reines-wohngebiet-wr',
    value: 'Reines Wohngebiet (WR)',
  },
  {
    key: 'object.object-type.free-selection.options.autorennstrecken',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.autorennstrecken',
    value: 'Autorennstrecken',
  },
  {
    key: 'my-objects.pipeline.assets-class.investment-volume.portfolio.yes',
    application: 'frontend',
    group: 'my-objects.pipeline.assets-class.investment-volume.portfolio',
    translationKey: 'my-objects.pipeline.assets-class.investment-volume.portfolio.yes',
    value: 'Ja',
  },
  {
    key: 'object.object-type.free-selection.options.strassen',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.strassen',
    value: 'Straßen',
  },
  {
    key: 'object.object-type.free-selection.options.infrastruktur',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.infrastruktur',
    value: 'Infrastruktur',
  },
  {
    key: 'object.object-type.free-selection.options.sporteinrichtungen',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.sporteinrichtungen',
    value: 'Sporteinrichtungen',
  },
  {
    key: 'object.object-type.free-selection.options.autowaschanlagen',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.autowaschanlagen',
    value: 'Autowaschanlagen',
  },
  {
    key: 'object.object-type.free-selection.options.kraftwerke',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.kraftwerke',
    value: 'Kraftwerke',
  },
  {
    key: 'object.object-type.free-selection.options.flughafengebaeude',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.flughafengebaeude',
    value: 'Flughafengebäude',
  },
  {
    key: 'object.object-type.free-selection.options.ferienwohnungen-ferienanlagen',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.ferienwohnungen-ferienanlagen',
    value: 'Ferienwohnungen (Ferienanlagen)',
  },
  {
    key: 'object.object-type.free-selection.options.weingueter',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.weingueter',
    value: 'Weingüter',
  },
  {
    key: 'object.object-type.free-selection.options.kindergaerten',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.kindergaerten',
    value: 'Kindergärten',
  },
  {
    key: 'object.object-type.free-selection.options.freizeitparks',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.freizeitparks',
    value: 'Freizeitparks',
  },
  {
    key: 'object.object-type.free-selection.options.bahnhoefe',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.bahnhoefe',
    value: 'Bahnhöfe',
  },
  {
    key: 'object.object-type.free-selection.options.raststaetten',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.raststaetten',
    value: 'Raststätten',
  },
  {
    key: 'object.object-type.free-selection.options.labore',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.labore',
    value: 'Labore',
  },
  {
    key: 'object.object-type.free-selection.options.staedtische-einrichtungen-beheorden',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.staedtische-einrichtungen-beheorden',
    value: 'Städtische Einri.free-selectionchtungen (Behörden)',
  },
  {
    key: 'object.object-type.free-selection.options.mcdonalds',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.mcdonalds',
    value: 'McDonalds',
  },
  {
    key: 'object.object-type.free-selection.options.burger king',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.burger king',
    value: 'Burger King',
  },
  {
    key: 'object.object-type.free-selection.options.burgen-schloesser',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.burgen-schloesser',
    value: 'Burgen / Schlösser',
  },
  {
    key: 'object.object-type.free-selection.options.rastplaetze',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.rastplaetze',
    value: 'Rastplätze',
  },
  {
    key: 'object.object-type.micro-and-serviced-living.options.business-apartments',
    application: 'frontend',
    group: 'object.object-type.micro-and-serviced-living.options',
    translationKey: 'object.object-type.micro-and-serviced-living.options.business-apartments',
    value: 'Business Apartments',
  },
  {
    key: 'object.object-type.free-selection.options.casinos-spielhallen',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.casinos-spielhallen',
    value: 'Casinos / Spielhallen',
  },
  {
    key: 'object.object-type.micro-and-serviced-living.options.mikroapartments',
    application: 'frontend',
    group: 'object.object-type.micro-and-serviced-living.options',
    translationKey: 'object.object-type.micro-and-serviced-living.options.mikroapartments',
    value: 'Mikroapartments',
  },
  {
    key: 'object.object-type.healthcare.options.behinderteneinrichtung',
    application: 'frontend',
    group: 'object.object-type.healthcare.options',
    translationKey: 'object.object-type.healthcare.options.behinderteneinrichtung',
    value: 'Behinderteneinrichtung',
  },
  {
    key: 'object.object-type.free-selection.options.parkhaeuser',
    application: 'frontend',
    group: 'object.object-type.free-selection.options',
    translationKey: 'object.object-type.free-selection.options.parkhaeuser',
    value: 'Parkhäuser',
  },
  {
    key: 'object.object-type.healthcare.options.ambulante-pflege',
    application: 'frontend',
    group: 'object.object-type.healthcare.options',
    translationKey: 'object.object-type.healthcare.options.ambulante-pflege',
    value: 'Ambulante Pflege',
  },
  {
    key: 'object.object-type.healthcare.options.aerztehaus',
    application: 'frontend',
    group: 'object.object-type.healthcare.options',
    translationKey: 'object.object-type.healthcare.options.aerztehaus',
    value: 'Ärztehaus',
  },
  {
    key: 'object.object-type.healthcare.options.pflege',
    application: 'frontend',
    group: 'object.object-type.healthcare.options',
    translationKey: 'object.object-type.healthcare.options.pflege',
    value: 'Pflege',
  },
  {
    key: 'object.object-type.healthcare.options.betreutes-wohnen',
    application: 'frontend',
    group: 'object.object-type.healthcare.options',
    translationKey: 'object.object-type.healthcare.options.betreutes-wohnen',
    value: 'Betreutes Wohnen',
  },
  {
    key: 'object.object-type.micro-and-serviced-living.options.studenten-apartments',
    application: 'frontend',
    group: 'object.object-type.micro-and-serviced-living.options',
    translationKey: 'object.object-type.micro-and-serviced-living.options.studenten-apartments',
    value: 'Studenten Apartments',
  },
  {
    key: 'object.object-type.logistic.options.speziallager',
    application: 'frontend',
    group: 'object.object-type.logistic.options',
    translationKey: 'object.object-type.logistic.options.speziallager',
    value: 'Speziallager',
  },
  {
    key: 'object.object-type.logistic.options.lagerhalle',
    application: 'frontend',
    group: 'object.object-type.logistic.options',
    translationKey: 'object.object-type.logistic.options.lagerhalle',
    value: 'Lagerhalle',
  },
  {
    key: 'object.object-type.healthcare.options.tagespflege',
    application: 'frontend',
    group: 'object.object-type.healthcare.options',
    translationKey: 'object.object-type.healthcare.options.tagespflege',
    value: 'Tagespflege',
  },
  {
    key: 'object.object-type.logistic.options.umschlaghalle',
    application: 'frontend',
    group: 'object.object-type.logistic.options',
    translationKey: 'object.object-type.logistic.options.umschlaghalle',
    value: 'Umschlaghalle',
  },
  {
    key: 'object.object-type.logistic.options.hochregallager',
    application: 'frontend',
    group: 'object.object-type.logistic.options',
    translationKey: 'object.object-type.logistic.options.hochregallager',
    value: 'Hochregallager',
  },
  {
    key: 'object.object-type.hotel.options.pension',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.pension',
    value: 'Pension',
  },
  {
    key: 'object.object-type.logistic.options.distributionshalle',
    application: 'frontend',
    group: 'object.object-type.logistic.options',
    translationKey: 'object.object-type.logistic.options.distributionshalle',
    value: 'Distributionshalle',
  },
  {
    key: 'object.object-type.hotel.options.motel',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.motel',
    value: 'Motel',
  },
  {
    key: 'object.object-type.hotel.options.hotel-garni',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.hotel-garni',
    value: 'Hotel garni',
  },
  {
    key: 'object.object-type.hotel.options.jugendherberge',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.jugendherberge',
    value: 'Jugendherberge',
  },
  {
    key: 'object.object-type.hotel.options.gaestehaus',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.gaestehaus',
    value: 'Gästehaus',
  },
  {
    key: 'object.object-type.hotel.options.boarding-apartmenthaus',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.boarding-apartmenthaus',
    value: 'Boarding- / Apartmenthaus',
  },
  {
    key: 'object.object-type.hotel.options.apart-apartmenthotel',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.apart-apartmenthotel',
    value: 'Apart- / Apartmenthotel',
  },
  {
    key: 'object.object-type.hotel.options.gasthof',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.gasthof',
    value: 'Gasthof',
  },
  {
    key: 'object.object-type.hotel.options.hotel',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.hotel',
    value: 'Hotel',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.lebensmittelmaerkte',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.lebensmittelmaerkte',
    value: 'Lebensmittelmärkte',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.baumaerkte',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.baumaerkte',
    value: 'Baumärkte',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.shoppingzentren',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.shoppingzentren',
    value: 'Shoppingzentren',
  },
  {
    key: 'object.object-type.hotel.options.all-suite-hotel',
    application: 'frontend',
    group: 'object.object-type.hotel.options',
    translationKey: 'object.object-type.hotel.options.all-suite-hotel',
    value: 'All-Suite-Hotel',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.einkaufsparks',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.einkaufsparks',
    value: 'Einkaufsparks',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.fachmarktzentren',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.fachmarktzentren',
    value: 'Fachmarktzentren',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.fachmaerkte',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.fachmaerkte',
    value: 'Fachmärkte',
  },
  {
    key: 'object.object-type.living.options.quartiersentwicklungen',
    application: 'frontend',
    group: 'object.object-type.living.options',
    translationKey: 'object.object-type.living.options.quartiersentwicklungen',
    value: 'Quartiersentwicklungen',
  },
  {
    key: 'object.object-type.living.options.wohnsiedlung',
    application: 'frontend',
    group: 'object.object-type.living.options',
    translationKey: 'object.object-type.living.options.wohnsiedlung',
    value: 'Wohnsiedlung',
  },
  {
    key: 'object.object-type.commerce-and-retail.options.high-street-retail',
    application: 'frontend',
    group: 'object.object-type.commerce-and-retail.options',
    translationKey: 'object.object-type.commerce-and-retail.options.high-street-retail',
    value: 'High Street Retail',
  },
  {
    key: 'object.object-type.living.options.geschosswohnungsbau',
    application: 'frontend',
    group: 'object.object-type.living.options',
    translationKey: 'object.object-type.living.options.geschosswohnungsbau',
    value: 'Geschosswohnungsbau',
  },
  { key: 'object.project-type.options.plot', application: 'frontend', group: 'object.project-type.options', translationKey: 'object.project-type.options.plot', value: 'Grundstück' },
  {
    key: 'object.object-type.living.options.mehrfamilienhaus',
    application: 'frontend',
    group: 'object.object-type.living.options',
    translationKey: 'object.object-type.living.options.mehrfamilienhaus',
    value: 'Mehrfamilienhaus',
  },
  {
    key: 'object.object-type.living.options.wohn-und-geschaeftshaus',
    application: 'frontend',
    group: 'object.object-type.living.options',
    translationKey: 'object.object-type.living.options.wohn-und-geschaeftshaus',
    value: 'Wohn- und Geschäftshaus',
  },
  { key: 'object.project-type.options.stock', application: 'frontend', group: 'object.project-type.options', translationKey: 'object.project-type.options.stock', value: 'Bestand' },
  {
    key: 'object.project-type.options.project-development',
    application: 'frontend',
    group: 'object.project-type.options',
    translationKey: 'object.project-type.options.project-development',
    value: 'Projetentwicklung',
  },
  {
    key: 'object.asset-class.options.kapitalgeber',
    application: 'frontend',
    group: 'object.asset-class.options',
    translationKey: 'object.asset-class.options.kapitalgeber',
    value: 'Kapitalgeber',
  },
  {
    key: 'object.asset-class.options.freies-ankaufsprofil',
    application: 'frontend',
    group: 'object.asset-class.options',
    translationKey: 'object.asset-class.options.freies-ankaufsprofil',
    value: 'Freies Ankaufsprofil',
  },
  {
    key: 'object.asset-class.options.logistik-industrie',
    application: 'frontend',
    group: 'object.asset-class.options',
    translationKey: 'object.asset-class.options.logistik-industrie',
    value: 'Logistik / Industrie',
  },
  {
    key: 'object.asset-class.options.micro-und-serviced-living',
    application: 'frontend',
    group: 'object.asset-class.options',
    translationKey: 'object.asset-class.options.micro-und-serviced-living',
    value: 'Micro- und Serviced Living',
  },
  {
    key: 'object.asset-class.options.healthcare-medical',
    application: 'frontend',
    group: 'object.asset-class.options',
    translationKey: 'object.asset-class.options.healthcare-medical',
    value: 'Healthcare / Medical',
  },
  { key: 'object.asset-class.options.buero', application: 'frontend', group: 'object.asset-class.options', translationKey: 'object.asset-class.options.buero', value: 'Büro' },
  {
    key: 'object.asset-class.options.handel-retail',
    application: 'frontend',
    group: 'object.asset-class.options',
    translationKey: 'object.asset-class.options.handel-retail',
    value: 'Handel / Retail',
  },
  { key: 'object.asset-class.options.hotel', application: 'frontend', group: 'object.asset-class.options', translationKey: 'object.asset-class.options.hotel', value: 'Hotel' },
  { key: 'object.asset-class.options.wohnen', application: 'frontend', group: 'object.asset-class.options', translationKey: 'object.asset-class.options.wohnen', value: 'Wohnen' },
  {
    key: 'company.intention.options.privatperson',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.privatperson',
    value: 'Privatperson',
  },
  {
    key: 'company.intention.options.family-office',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.family-office',
    value: 'Family Office',
  },
  {
    key: 'company.intention.options.verkaufsberater',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.verkaufsberater',
    value: 'Verkaufsberater',
  },
  { key: 'company.intention.options.hnwi', application: 'frontend', group: 'company.intention.options', translationKey: 'company.intention.options.hnwi', value: 'HNWI' },
  {
    key: 'company.intention.options.stadt-gemeinde',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.stadt-gemeinde',
    value: 'Stadt / Gemeinde',
  },
  { key: 'company.intention.options.bank', application: 'frontend', group: 'company.intention.options', translationKey: 'company.intention.options.bank', value: 'Bank' },
  { key: 'company.intention.options.stiftung', application: 'frontend', group: 'company.intention.options', translationKey: 'company.intention.options.stiftung', value: 'Stiftung' },
  {
    key: 'company.intention.options.asset-property-investment-manager',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.asset-property-investment-manager',
    value: 'Asset - / Property - / Investment Manager',
  },
  { key: 'company.intention.options.bautraeger', application: 'frontend', group: 'company.intention.options', translationKey: 'company.intention.options.bautraeger', value: 'Bauträger' },
  {
    key: 'company.intention.options.pensionskasse',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.pensionskasse',
    value: 'Pensionskasse',
  },
  { key: 'company.corporate-form.options.secs', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.secs', value: 'SECS' },
  { key: 'company.intention.options.investor', application: 'frontend', group: 'company.intention.options', translationKey: 'company.intention.options.investor', value: 'Investor' },
  {
    key: 'company.corporate-form.options.sarl-s',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.sarl-s',
    value: 'SARL-S',
  },
  { key: 'company.corporate-form.options.scs', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.scs', value: 'SCS' },
  {
    key: 'company.intention.options.projektentwickler',
    application: 'frontend',
    group: 'company.intention.options',
    translationKey: 'company.intention.options.projektentwickler',
    value: 'Projektentwickler',
  },
  { key: 'company.corporate-form.options.scsp', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.scsp', value: 'SCSp' },
  { key: 'company.corporate-form.options.sarl', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.sarl', value: 'SARL' },
  { key: 'company.corporate-form.options.seca', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.seca', value: 'SECA' },
  { key: 'company.corporate-form.options.ltd', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.ltd', value: 'Ltd.' },
  { key: 'company.corporate-form.options.se', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.se', value: 'SE' },
  { key: 'company.corporate-form.options.sa', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.sa', value: 'S.A.' },
  {
    key: 'company.corporate-form.options.koerperschaft-d-oeffentl-rechts',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.koerperschaft-d-oeffentl-rechts',
    value: 'Körperschaft d. Öffentl. Rechts',
  },
  {
    key: 'company.corporate-form.options.rechtsfaehige-stiftung',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.rechtsfaehige-stiftung',
    value: 'rechtsfähige Stiftung',
  },
  {
    key: 'company.corporate-form.options.anstalt-d-oeffentl-rechts',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.anstalt-d-oeffentl-rechts',
    value: 'Anstalt d. Öffentl. Rechts',
  },
  {
    key: 'company.corporate-form.options.treuhaenderische-stiftung',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.treuhaenderische-stiftung',
    value: 'treuhänderische Stiftung',
  },
  { key: 'company.corporate-form.options.ev', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.ev', value: 'e.V.' },
  {
    key: 'company.corporate-form.options.partg-mbb',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.partg-mbb',
    value: 'PartG mbB',
  },
  { key: 'company.corporate-form.options.partg', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.partg', value: 'PartG' },
  { key: 'company.corporate-form.options.kg-aa', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.kg-aa', value: 'KG aA' },
  { key: 'company.corporate-form.options.eg', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.eg', value: 'eG' },
  {
    key: 'company.corporate-form.options.gmbh-and-co-kg',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.gmbh-and-co-kg',
    value: 'GmbH & Co. KG',
  },
  { key: 'company.corporate-form.options.ag', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.ag', value: 'AG' },
  { key: 'company.corporate-form.options.ohg', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.ohg', value: 'OHG' },
  { key: 'company.corporate-form.options.kg', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.kg', value: 'KG' },
  {
    key: 'company.corporate-form.options.ug-haftungsbeschraenkt',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.ug-haftungsbeschraenkt',
    value: 'UG (haftungsbeschränkt)',
  },
  { key: 'company.corporate-form.options.gmbh', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.gmbh', value: 'GmbH' },
  { key: 'company.corporate-form.options.gbr', application: 'frontend', group: 'company.corporate-form.options', translationKey: 'company.corporate-form.options.gbr', value: 'GbR' },
  {
    key: 'company.corporate-form.options.einzelunternehmen',
    application: 'frontend',
    group: 'company.corporate-form.options',
    translationKey: 'company.corporate-form.options.einzelunternehmen',
    value: 'Einzelunternehmen',
  },
  { key: 'person.responsibility.options.sales', application: 'frontend', group: 'person.responsibility.options', translationKey: 'person.responsibility.options.sales', value: 'Verkauf' },
  {
    key: 'person.responsibility.options.purchase',
    application: 'frontend',
    group: 'person.responsibility.options',
    translationKey: 'person.responsibility.options.purchase',
    value: 'Einkauf',
  },
  { key: 'person.title.options.prof-dr', application: 'frontend', group: 'person.title.options', translationKey: 'person.title.options.prof-dr', value: 'Prof. Dr.' },
  { key: 'person.salutation.options.diverse', application: 'frontend', group: 'person.salutation.options', translationKey: 'person.salutation.options.diverse', value: 'Divers' },
  { key: 'person.title.options.prof', application: 'frontend', group: 'person.title.options', translationKey: 'person.title.options.prof', value: 'Prof.' },
  { key: 'person.title.options.dr', application: 'frontend', group: 'person.title.options', translationKey: 'person.title.options.dr', value: 'Dr.' },
  { key: 'person.salutation.options.woman', application: 'frontend', group: 'person.salutation.options', translationKey: 'person.salutation.options.woman', value: 'Frau' },
  { key: 'person.salutation.options.man', application: 'frontend', group: 'person.salutation.options', translationKey: 'person.salutation.options.man', value: 'Herr' },
  {
    key: 'storage.specification.options.management-contracts.other',
    application: 'frontend',
    group: 'storage.specification.options.management-contracts',
    translationKey: 'storage.specification.options.management-contracts.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.specification.options.outgoing-invoice.other',
    application: 'frontend',
    group: 'storage.specification.options.outgoing-invoice',
    translationKey: 'storage.specification.options.outgoing-invoice.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.renter.other',
    application: 'frontend',
    group: 'storage.document-type.options.renter',
    translationKey: 'storage.document-type.options.renter.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.specification.options.official-certificates.other',
    application: 'frontend',
    group: 'storage.specification.options.official-certificates',
    translationKey: 'storage.specification.options.official-certificates.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.specification.options.building-permit.other',
    application: 'frontend',
    group: 'storage.specification.options.building-permit',
    translationKey: 'storage.specification.options.building-permit.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.commercial-documents.other',
    application: 'frontend',
    group: 'storage.document-type.options.commercial-documents',
    translationKey: 'storage.document-type.options.commercial-documents.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.building.other',
    application: 'frontend',
    group: 'storage.document-type.options.building',
    translationKey: 'storage.document-type.options.building.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.litigation.other',
    application: 'frontend',
    group: 'storage.document-type.options.litigation',
    translationKey: 'storage.document-type.options.litigation.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.financing.other',
    application: 'frontend',
    group: 'storage.document-type.options.financing',
    translationKey: 'storage.document-type.options.financing.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.plot.other',
    application: 'frontend',
    group: 'storage.document-type.options.plot',
    translationKey: 'storage.document-type.options.plot.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.introduction.other',
    application: 'frontend',
    group: 'storage.document-type.options.introduction',
    translationKey: 'storage.document-type.options.introduction.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.accounting.other',
    application: 'frontend',
    group: 'storage.document-type.options.accounting',
    translationKey: 'storage.document-type.options.accounting.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.property.other',
    application: 'frontend',
    group: 'storage.document-type.options.property',
    translationKey: 'storage.document-type.options.property.other',
    value: 'Sonstiges',
  },
  { key: 'storage.folder.options.other', application: 'frontend', group: 'storage.folder.options', translationKey: 'storage.folder.options.other', value: 'Sonstiges' },
  {
    key: 'storage.document-type.options.management.other',
    application: 'frontend',
    group: 'storage.document-type.options.management',
    translationKey: 'storage.document-type.options.management.other',
    value: 'Sonstiges',
  },
  {
    key: 'storage.document-type.options.building-planning-law.other',
    application: 'frontend',
    group: 'storage.document-type.options.building-planning-law',
    translationKey: 'storage.document-type.options.building-planning-law.other',
    value: 'Sonstiges',
  },
  {
    key: 'object.deal-criteria.investment-type.option.core-plus',
    application: 'frontend',
    group: 'object.deal-criteria.investment-type.option',
    translationKey: 'object.deal-criteria.investment-type.option.core-plus',
    value: 'Core+',
  },
  {
    key: 'storage.document-type.options.building-regulations.other',
    application: 'frontend',
    group: 'storage.document-type.options.building-regulations',
    translationKey: 'storage.document-type.options.building-regulations.other',
    value: 'Sonstiges',
  },
  {
    key: 'object.deal-criteria.investment-type.option.value-add',
    application: 'frontend',
    group: 'object.deal-criteria.investment-type.option',
    translationKey: 'object.deal-criteria.investment-type.option.value-add',
    value: 'Value Add',
  },
  {
    key: 'object.deal-criteria.investment-type.option.core',
    application: 'frontend',
    group: 'object.deal-criteria.investment-type.option',
    translationKey: 'object.deal-criteria.investment-type.option.core',
    value: 'Core',
  },
  {
    key: 'object.deal-criteria.deal-type.option.forward-deal',
    application: 'frontend',
    group: 'object.deal-criteria.deal-type.option',
    translationKey: 'object.deal-criteria.deal-type.option.forward-deal',
    value: 'Forward Deal',
  },
  {
    key: 'object.deal-criteria.investment-type.option.oppurtunistic',
    application: 'frontend',
    group: 'object.deal-criteria.investment-type.option',
    translationKey: 'object.deal-criteria.investment-type.option.oppurtunistic',
    value: 'Opportunistic',
  },
  {
    key: 'my-objects.pipeline.location-criteria.abcd.options.a',
    application: 'frontend',
    group: 'my-objects.pipeline.location-criteria.abcd.options',
    translationKey: 'my-objects.pipeline.location-criteria.abcd.options.a',
    value: 'A',
  },
  {
    key: 'object.deal-criteria.deal-type.option.share-deal',
    application: 'frontend',
    group: 'object.deal-criteria.deal-type.option',
    translationKey: 'object.deal-criteria.deal-type.option.share-deal',
    value: 'Share Deal',
  },
  {
    key: 'object.deal-criteria.deal-type.option.asset-deal',
    application: 'frontend',
    group: 'object.deal-criteria.deal-type.option',
    translationKey: 'object.deal-criteria.deal-type.option.asset-deal',
    value: 'Asset Deal',
  },
  {
    key: 'my-objects.pipeline.location-criteria.abcd.options.b',
    application: 'frontend',
    group: 'my-objects.pipeline.location-criteria.abcd.options',
    translationKey: 'my-objects.pipeline.location-criteria.abcd.options.b',
    value: 'B',
  },
  {
    key: 'object.fixed-rental.price.options.whatever',
    application: 'frontend',
    group: 'object.fixed-rental.price.options',
    translationKey: 'object.fixed-rental.price.options.whatever',
    value: 'Egal',
  },
  {
    key: 'my-objects.pipeline.location-criteria.abcd.options.d',
    application: 'frontend',
    group: 'my-objects.pipeline.location-criteria.abcd.options',
    translationKey: 'my-objects.pipeline.location-criteria.abcd.options.d',
    value: 'D',
  },
  {
    key: 'my-objects.pipeline.location-criteria.abcd.options.c',
    application: 'frontend',
    group: 'my-objects.pipeline.location-criteria.abcd.options',
    translationKey: 'my-objects.pipeline.location-criteria.abcd.options.c',
    value: 'C',
  },
  { key: 'object.fixed-rental.price.options.yes', application: 'frontend', group: 'object.fixed-rental.price.options', translationKey: 'object.fixed-rental.price.options.yes', value: 'Ja' },
  {
    key: 'object.object-condition.wohnen.options.renovation',
    application: 'frontend',
    group: 'object.object-condition.wohnen.options',
    translationKey: 'object.object-condition.wohnen.options.renovation',
    value: 'Renovierung',
  },
  {
    key: 'object.object-condition.wohnen.options.redevelopment',
    application: 'frontend',
    group: 'object.object-condition.wohnen.options',
    translationKey: 'object.object-condition.wohnen.options.redevelopment',
    value: 'Sanierung',
  },
  {
    key: 'object.object-condition.wohnen.options.vacancy',
    application: 'frontend',
    group: 'object.object-condition.wohnen.options',
    translationKey: 'object.object-condition.wohnen.options.vacancy',
    value: 'Leerstand',
  },
  { key: 'object.fixed-rental.price.options.no', application: 'frontend', group: 'object.fixed-rental.price.options', translationKey: 'object.fixed-rental.price.options.no', value: 'Nein' },
  {
    key: 'object.object-condition.wohnen.options.maintenance-backlog',
    application: 'frontend',
    group: 'object.object-condition.wohnen.options',
    translationKey: 'object.object-condition.wohnen.options.maintenance-backlog',
    value: 'Instandhaltungsstau',
  },
  {
    key: 'object.object-condition.wohnen.options.new-buildings',
    application: 'frontend',
    group: 'object.object-condition.wohnen.options',
    translationKey: 'object.object-condition.wohnen.options.new-buildings',
    value: 'Neubauten',
  },
  {
    key: 'object.special-rights.wohnen.options.leasehold',
    application: 'frontend',
    group: 'object.special-rights.wohnen.options',
    translationKey: 'object.special-rights.wohnen.options.leasehold',
    value: 'Erbbaurecht',
  },
  {
    key: 'object.special-rights.wohnen.options.redevelopment-area',
    application: 'frontend',
    group: 'object.special-rights.wohnen.options',
    translationKey: 'object.special-rights.wohnen.options.redevelopment-area',
    value: 'Sanierungsgebiet',
  },
  {
    key: 'object.special-rights.wohnen.options.usufruct',
    application: 'frontend',
    group: 'object.special-rights.wohnen.options',
    translationKey: 'object.special-rights.wohnen.options.usufruct',
    value: 'Nießbrauchrecht',
  },
  {
    key: 'object.special-rights.wohnen.options.monument-protection',
    application: 'frontend',
    group: 'object.special-rights.wohnen.options',
    translationKey: 'object.special-rights.wohnen.options.monument-protection',
    value: 'Denkmalschutz',
  },
  {
    key: 'object.more-types-of-usage.living.options.logistic',
    application: 'frontend',
    group: 'object.more-types-of-usage.living.options',
    translationKey: 'object.more-types-of-usage.living.options.logistic',
    value: 'Logistik',
  },
  {
    key: 'object.more-types-of-usage.living.options.handel-retail',
    application: 'frontend',
    group: 'object.more-types-of-usage.living.options',
    translationKey: 'object.more-types-of-usage.living.options.handel-retail',
    value: 'Handel / Retail',
  },
  {
    key: 'object.building-law-situation.living.options.preliminary-building-permit',
    application: 'frontend',
    group: 'object.building-law-situation.living.options',
    translationKey: 'object.building-law-situation.living.options.preliminary-building-permit',
    value: 'Bauvorbescheid',
  },
  {
    key: 'object.building-law-situation.living.options.building-permit',
    application: 'frontend',
    group: 'object.building-law-situation.living.options',
    translationKey: 'object.building-law-situation.living.options.building-permit',
    value: 'Baugenehmigung',
  },
  {
    key: 'object.building-law-situation.living.options.old-stock',
    application: 'frontend',
    group: 'object.building-law-situation.living.options',
    translationKey: 'object.building-law-situation.living.options.old-stock',
    value: 'Altbestand',
  },
  {
    key: 'object.more-types-of-usage.living.options.office',
    application: 'frontend',
    group: 'object.more-types-of-usage.living.options',
    translationKey: 'object.more-types-of-usage.living.options.office',
    value: 'Büro',
  },
  {
    key: 'object.construction-method.living.options.closed-design',
    application: 'frontend',
    group: 'object.construction-method.living.options',
    translationKey: 'object.construction-method.living.options.closed-design',
    value: 'Geschlossene Bauweise',
  },
  {
    key: 'object.building-law-situation.living.options.conversion-and-fallow-areas',
    application: 'frontend',
    group: 'object.building-law-situation.living.options',
    translationKey: 'object.building-law-situation.living.options.conversion-and-fallow-areas',
    value: 'Konversions- und Brachflächen',
  },
  {
    key: 'object.building-law-situation.living.options.constructions-loads',
    application: 'frontend',
    group: 'object.building-law-situation.living.options',
    translationKey: 'object.building-law-situation.living.options.constructions-loads',
    value: 'Baulasten',
  },
  {
    key: 'object.building-law-situation.living.options.building-laws',
    application: 'frontend',
    group: 'object.building-law-situation.living.options',
    translationKey: 'object.building-law-situation.living.options.building-laws',
    value: 'Baurecht',
  },
  {
    key: 'object.required-information-and-documents.living.options.complete-object-address',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.complete-object-address',
    value: 'Vollständige Objektanschrift',
  },
  {
    key: 'object.required-information-and-documents.living.options.actual-annual-net-rent',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.actual-annual-net-rent',
    value: 'IST-Jahresnettokaltmiete',
  },
  {
    key: 'object.construction-method.living.options.open-design',
    application: 'frontend',
    group: 'object.construction-method.living.options',
    translationKey: 'object.construction-method.living.options.open-design',
    value: 'Offene Bauweise',
  },
  {
    key: 'object.required-information-and-documents.living.options.division-of-space',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.division-of-space',
    value: 'Flächenaufteilung',
  },
  {
    key: 'object.required-information-and-documents.living.options.property-specific-features',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.property-specific-features',
    value: 'Objektspezifische Besonderheiten',
  },
  {
    key: 'object.required-information-and-documents.living.options.target-annual-net-rent',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.target-annual-net-rent',
    value: 'SOLL-Jahresnettokaltmiete',
  },
  {
    key: 'object.required-information-and-documents.living.options.tenant-list',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.tenant-list',
    value: 'Mieterliste',
  },
  {
    key: 'object.required-information-and-documents.living.options.property-photos',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.property-photos',
    value: 'Objektfotos',
  },
  {
    key: 'object.required-information-and-documents.living.options.layout',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.layout',
    value: 'Grundriss',
  },
  {
    key: 'object.required-information-and-documents.living.options.land-map',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.land-map',
    value: 'Flurkarte',
  },
  {
    key: 'object.required-information-and-documents.living.options.sales-mandate-with-sales-consultants',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.sales-mandate-with-sales-consultants',
    value: 'Verkaufsmandat bei Verkaufsberatern',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.drugstore',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.drugstore',
    value: 'Drogerie',
  },
  {
    key: 'object.required-information-and-documents.living.options.zoning-plan',
    application: 'frontend',
    group: 'object.required-information-and-documents.living.options',
    translationKey: 'object.required-information-and-documents.living.options.zoning-plan',
    value: 'Bebauungsplan',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.hardware-store',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.hardware-store',
    value: 'Baumarkt',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.facility',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.facility',
    value: 'Einrichtung',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.electronics',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.electronics',
    value: 'Elektronik',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.department-store',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.department-store',
    value: 'Kaufhaus',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.furniture',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.furniture',
    value: 'Möbel',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.fashion',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.fashion',
    value: 'Fashion',
  },
  {
    key: 'object.anchor-tenant.commerce-and-retail.options.grocer',
    application: 'frontend',
    group: 'object.anchor-tenant.commerce-and-retail.options',
    translationKey: 'object.anchor-tenant.commerce-and-retail.options.grocer',
    value: 'Lebensmittler',
  },
  { key: 'object.hotel.options.flight-hotel', application: 'frontend', group: 'object.hotel.options', translationKey: 'object.hotel.options.flight-hotel', value: 'Flughotel' },
  { key: 'object.hotel.options.jugendstilhotel', application: 'frontend', group: 'object.hotel.options', translationKey: 'object.hotel.options.jugendstilhotel', value: 'Jugendstilhotel' },
  { key: 'object.hotel.options.city-hotel', application: 'frontend', group: 'object.hotel.options', translationKey: 'object.hotel.options.city-hotel', value: 'Stadthotel' },
  { key: 'object.hotel.options.country-hotel', application: 'frontend', group: 'object.hotel.options', translationKey: 'object.hotel.options.country-hotel', value: 'Landhotel' },
  { key: 'object.hotel.options.beach-hotel', application: 'frontend', group: 'object.hotel.options', translationKey: 'object.hotel.options.beach-hotel', value: 'Strandhotel' },
  { key: 'object.hotel.options.mountain-hotel', application: 'frontend', group: 'object.hotel.options', translationKey: 'object.hotel.options.mountain-hotel', value: 'Berghotel' },
  {
    key: 'object.type-of-financing.options.mezzanine-loan',
    application: 'frontend',
    group: 'object.type-of-financing.options',
    translationKey: 'object.type-of-financing.options.mezzanine-loan',
    value: 'Mezzanine Loan',
  },
  {
    key: 'object.type-of-financing.options.whole-loan',
    application: 'frontend',
    group: 'object.type-of-financing.options',
    translationKey: 'object.type-of-financing.options.whole-loan',
    value: 'Whole Loan',
  },
  {
    key: 'object.hotel.options.winter-sports-hotel',
    application: 'frontend',
    group: 'object.hotel.options',
    translationKey: 'object.hotel.options.winter-sports-hotel',
    value: 'Wintersporthotel',
  },
  {
    key: 'object.type-of-financing.options.senior-loan',
    application: 'frontend',
    group: 'object.type-of-financing.options',
    translationKey: 'object.type-of-financing.options.senior-loan',
    value: 'Senior Loan',
  },
  { key: 'object.salesprocess.speech', application: 'frontend', group: 'object.salesprocess', translationKey: 'object.salesprocess.speech', value: 'strukturierte Ansprache' },
  { key: 'object.salesprocess.bidding-procedure', application: 'frontend', group: 'object.salesprocess', translationKey: 'object.salesprocess.bidding-procedure', value: 'Bieterverfahren' },
  {
    key: 'object.sales-process.documents.expression-of-interest',
    application: 'frontend',
    group: 'object.sales-process.documents',
    translationKey: 'object.sales-process.documents.expression-of-interest',
    value: 'Interessensbekundung (LOI)',
  },
  {
    key: 'object.sales-process.documents.confidentiality-agreement',
    application: 'frontend',
    group: 'object.sales-process.documents',
    translationKey: 'object.sales-process.documents.confidentiality-agreement',
    value: 'Geheimhaltungsvereinbarung (NDA)',
  },
  {
    key: 'my-objects.pipeline.mapping-metric.options.pid',
    application: 'frontend',
    group: 'my-objects.pipeline.mapping-metric.options',
    translationKey: 'my-objects.pipeline.mapping-metric.options.pid',
    value: 'PID',
  },
  {
    key: 'my-objects.pipeline.mapping-metric.options.aid',
    application: 'frontend',
    group: 'my-objects.pipeline.mapping-metric.options',
    translationKey: 'my-objects.pipeline.mapping-metric.options.aid',
    value: 'AID',
  },
  {
    key: 'my-objects.pipeline.mapping-metric.options.cid',
    application: 'frontend',
    group: 'my-objects.pipeline.mapping-metric.options',
    translationKey: 'my-objects.pipeline.mapping-metric.options.cid',
    value: 'CID',
  },
  {
    key: 'object.operatorcontract.managementcontract',
    application: 'frontend',
    group: 'object.operatorcontract.options',
    translationKey: 'object.operatorcontract.managementcontract',
    value: 'Managementvertrag',
  },
  {
    key: 'object.operatorcontract.options.leaseagreement',
    application: 'frontend',
    group: 'object.operatorcontract.options',
    translationKey: 'object.operatorcontract.options.leaseagreement',
    value: 'Pachtvertrag',
  },
  {
    key: 'object.object-type.searched.options.wohnen.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.wohnen',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.operatorcontract.options.operatorfree',
    application: 'frontend',
    group: 'object.operatorcontract.options',
    translationKey: 'object.operatorcontract.options.operatorfree',
    value: 'Betreiberfrei',
  },
  {
    key: 'object.object-type.searched.options.wohnen.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.wohnen',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
  {
    key: 'object.object-type.searched.options.wohnen.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.wohnen',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.office.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.office',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.office.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.office',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.object-type.searched.options.handel-retail.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.handel-retail',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.object-type.searched.options.office.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.office',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
  {
    key: 'object.object-type.searched.options.handel-retail.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.handel-retail',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.handel-retail.renter',
    application: 'frontend',
    group: 'object.object-type.searched.options.handel-retail',
    translationKey: 'object.object-type.searched.option.renter',
    value: 'Mieter',
  },
  {
    key: 'object.object-type.searched.options.handel-retail.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.handel-retail',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
  {
    key: 'object.object-type.searched.options.hotel.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.hotel',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.hotel.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.hotel',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.object-type.searched.options.hotel.operator',
    application: 'frontend',
    group: 'object.object-type.searched.options.hotel',
    translationKey: 'object.object-type.searched.option.operator',
    value: 'Betreiber',
  },
  {
    key: 'object.object-type.searched.options.hotel.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.hotel',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
  {
    key: 'object.object-type.searched.options.logistik-industrie.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.logistik-industrie',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.object-type.searched.options.logistik-industrie.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.logistik-industrie',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.logistik-industrie.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.logistik-industrie',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
  {
    key: 'object.object-type.searched.options.logistik-industrie.renter',
    application: 'frontend',
    group: 'object.object-type.searched.options.logistik-industrie',
    translationKey: 'object.object-type.searched.option.renter',
    value: 'Mieter',
  },
  {
    key: 'object.object-type.searched.options.healthcare-medical.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.healthcare-medical',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.healthcare-medical.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.healthcare-medical',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.object-type.searched.options.healthcare-medical.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.healthcare-medical',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
  {
    key: 'object.object-type.searched.options.micro-und-serviced-living.project-developer',
    application: 'frontend',
    group: 'object.object-type.searched.options.micro-und-serviced-living',
    translationKey: 'object.object-type.searched.option.project-developer',
    value: 'Projektentwickler',
  },
  {
    key: 'object.object-type.searched.options.healthcare-medical.operator',
    application: 'frontend',
    group: 'object.object-type.searched.options.healthcare-medical',
    translationKey: 'object.object-type.searched.option.operator',
    value: 'Betreiber',
  },
  {
    key: 'object.object-type.searched.options.micro-und-serviced-living.contractor',
    application: 'frontend',
    group: 'object.object-type.searched.options.micro-und-serviced-living',
    translationKey: 'object.object-type.searched.option.contractor',
    value: 'Bauunternehmer',
  },
  {
    key: 'object.object-type.searched.options.micro-und-serviced-living.investor',
    application: 'frontend',
    group: 'object.object-type.searched.options.micro-und-serviced-living',
    translationKey: 'object.object-type.searched.option.investor',
    value: 'Investor',
  },
]
export default settings
