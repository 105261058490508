import './Confirm.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Layout from '../../../Layout/Layout'
import fetch from '../../../../fetch'
import { useParams } from 'react-router-dom'
import Link from '../../../UI/Link/Link'

const Confirm = () => {
  const [success, setSuccess] = useState(null)
  const settings = useSelector((state) => state.settings)
  const params = useParams()

  const translations = useSelector((state) => state.translations)

  useEffect(() => {
    const submit = async () => {
      const { status } = await fetch('/auth/registration/confirm', { code: params.code })
      setSuccess(status)
    }
    if (params && params.code) {
      submit()
    }
  }, [params])

  if (!settings) return <></>
  return (
    <Layout>
      <div id="registration-confirm">
        <div className="message">
          <div className="headline">{translations['registration.headline']}</div>
          <div className="text">
            {success === true && (
              <>
                <div className="success">{translations['registration.confirm.success']}</div>
                <Link href="/login" text={translations['registration.done.login']} />
              </>
            )}
            {success === false && <div className="failure">{translations['registration.confirm.unknown-error']}</div>}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Confirm
