import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'
import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const SalesProcess = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    // dispatch(setNewObject({ ...newObject, salesProcess: { ...newObject.salesProcess, [key]: value } }))
    dispatch(setLocal(props.OBJECT, { ...newObject, salesProcess: { ...newObject.salesProcess, [key]: value } }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.sales-process']}</div>
        <div className="values">
          <RadioGroup
            options={settings.getGroup('object.salesProcess').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
            value={newObject}
            onChange={update('salesProcessSelection')}
          />
          <Input type="date" placeholder={translations['object.sales-process.start-date']} value={newObject.salesProcess.startDate} onChange={update('startDate')} />
          <Input type="date" placeholder={translations['object.sales-process.start-time']} value={newObject.salesProcess.startTime} onChange={update('startTime')} />
          {/* <Dropdown placeholder={translations['object.sales-process.interval']} options="" onChange={update('interval')} /> */}
          <Input type="number" placeholder={translations['object.sales-process.per-interval']} value={newObject.salesProcess.perInterval} onChange={update('perInterval')} />
        </div>
      </div>
      <div className="group">
        <div className="values">
          <CheckboxGroup
            options={settings.getGroup('object.sales-process.documents').map((option) => ({ label: translations[option.translationKey], value: option.key }))}
            values={newObject.salesProcess.documents}
            onChange={update('documents')}
          />
        </div>
      </div>
      <div className="group">
        <div className="values">
          {/* <Dropdown /> */}
          <Input
            type="customNumber"
            placeholder={translations['object.sales-process.courtage']}
            value={newObject.salesProcess.courtage}
            onChange={update('courtage')}
            symbol={translations['percent.symbol']}
          />
        </div>
      </div>
      <div className="group">
        <div className="values">{/* <Dropdown /> */}</div>
      </div>
      <div className="group">
        <div className="values">
          <Input type="customNumber" placeholder={translations['object.sales-process.phone']} value={newObject.salesProcess.phone} onChange={update('phone')} />
          <Input type="customNumber" placeholder={translations['object.sales-process.mobile']} value={newObject.salesProcess.mobile} onChange={update('mobile')} />
        </div>
      </div>
      <div className="group">
        <div className="values">
          <Input type="customNumber" placeholder={translations['object.sales-process.email']} value={newObject.salesProcess.email} onChange={update('email')} />
        </div>
      </div>
    </div>
  )
}

export default SalesProcess
