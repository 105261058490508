export const SET_TRANSLATION = 'set_translation'

export const SET_USER_TOKEN = 'set_user_token'
export const SET_USER = 'set_user'

export const SET_SETTINGS = 'set_settings'

export const SET_TRANSLATIONS = 'set_translations'
export const SET_LANG_OPTIONS = 'set_lang_options'
export const SET_LANG = 'set_lang'

export const SET_NEW_PURCHASE_PIPELINE = 'set_new_purchase_pipeline'
export const SET_NEW_PURCHASE_PIPELINE_NAVIGATION_INDEX = 'set_new_purchase_pipeline_navigation_index'
export const TRIGGER_NEW_PURCHASE_PROFILE_CLEANUP = 'trigger_new_purchase_profile_cleanup'

export const SET_NEW_OBJECT_PIPELINE = 'set_new_object_pipeline'
export const TRIGGER_NEW_OBJECT_CLEANUP = 'trigger_new_object_cleanup'

export const SET_LOCATION_COUNTRY = 'set_location_country'
export const SET_LOCATION_STATE = 'set_location_state'
export const SET_LOCATION_CITY = 'set_location_city'

export const SET_RESPONSIVE_STATE = 'set_responsive_state'
