import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const FactorAndReturn = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (factorValue, returnValue) => {
    const updatePurchaseProfile = { ...purchaseProfile, factorAndReturn: { factorValue, returnValue } }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  const updateFactorValue = (value) => (value > 0 ? update(value, 100 / value) : update('', ''))

  const updateReturnValue = (value) => (value > 0 ? update(100 / value, value) : update('', ''))

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.factor-and-return.max-factor']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.factorAndReturn.factorValue}
            onChange={updateFactorValue}
            placeholder={translations['my-objects.pipeline.location-criteria.factor-and-return.max-factor']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.factor-and-return.min-return']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.factorAndReturn.returnValue}
            onChange={updateReturnValue}
            placeholder={translations['my-objects.pipeline.location-criteria.factor-and-return.min-return']}
            suffix={translations['percent.symbol']}
          />
        </div>
      </div>
    </div>
  )
}

export default FactorAndReturn
