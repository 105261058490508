import de from '../translations/de'
import settings from '../settings/settings'

const defaultState = {
  TRANSLATION: de.translation,
  CURRENTLANG: de.langKey,
  langOptions: ['DE', 'EN'],
  translations: de.reduce((acc, t) => {
    acc[t.key] = t.value
    return acc
  }, {}),
  settings: {
    items: settings,
    getGroup: function (group) {
      return this.items.filter((item) => item.group === group)
    },
    getSingle: function (key) {
      return this.items.find((item) => item.key === key)
    },
    getSizeDefinitions: function () {
      return this.items.sizeDefinitions
    },
  },
  OBJECT: {
    financing: {
      lender: '',
      interest: '',
      repayment: '',
      annuity: '',
      runningTime: '',
      remainingTerm: '',
      specialRepayment: '',
    },

    tenantList: [],

    uuid: '',
    countOfBeds: '',
    operatorContract: null,
    operatingCost: {
      recoverable: '',
      notRecoverable: '',
    },
    projectType: [],
    multiTenant: false,
    multiTenantMinRunningTime: '',
    singleTenant: false,
    singleTenantMinRunningTime: '',
    operatingConst: {
      recoverable: '',
      notRecoverable: '',
    },
    pitches: {
      garage: 0,
      outdoor: 0,
      underground: 0,
    },
    rentalIncome: {
      pa: '',
      pm: '',
      commercialPa: '',
      commercialPm: '',
      averageResidentialRentalIncomePerSqm: '',
      commercialAverageResidentialRentalIncomePerSqm: '',
      totalPa: '',
      totalPm: '',
      basicRentPa: '',
      single: '',
      double: '',
      family: '',
      juniorSuite: '',
      suite: '',
      multiBed: '',
      apartment: '',
    },
    administration: {
      costs: '',
    },
    mappingMetrics: '',
    hotelRating: [],
    hotelAdditional: {
      roomOccupancy: '',
      bedOccupancy: '',
      averageRoomRate: '',
      revenueHotelGuests: '',
      revenueExternalGuests: '',
      costsOfSalesRatio: '',
      personalCostsPerFullTimeEmployee: '',
      productivityPerFullTimeEmployee: '',
      gastronomy: '',
    },
    hotelRooms: {
      single: '',
      double: '',
      family: '',
      junior: '',
      suite: '',
      multiBed: '',
      apartment: '',
    },
    assetClass: '',
    objectType: '',
    dealCriteria: {
      dealType: [],
      investmentType: null,
    },
    measures: [],
    more: {
      objectDescription: '',
      locationDescription: '',
      more: '',
    },
    objectData: {
      year: 0,
      size: 0,
      condition: 0,
      lift: false,
      basement: false,
      floorSpace: '',
    },
    salesProcess: {
      startDate: '',
      startTime: '',
      interval: null,
      perInterval: '',
      documents: [],
      courtage: '',
      phone: '',
      mobile: '',
      email: '',
    },
    sellingPrice: '',
    yieldCriteria: {
      livingSize: '',
      commercialSize: '',
      livingIncomming: '',
      commercialIncomming: '',
      livingCount: '',
      commercialCount: '',
    },
    photos: [],
    digitalViewingURL: '',
    location: {
      abcd: '',
      state: '',
      region: '',
      district: '',
      postcode: '',
      city: '',
      street: '',
      houseNumber: '',
    },
    investment: {
      buyingPrice: 0,
      propertyTransferTax: 0,
      notaryFees: 0,
      landRegisterCosts: 0,
      agencyFee: 0,
      pairingFee: 0,
      faktor: 0,
      yield: 0,
      groundValue: 0,
      rentalGuaranteeSelection: '',
      rentalGuarantee: {
        from: '',
        until: '',
      },
    },
    other: {
      monumentProtection: '',
      leasehold: '',
      usufruct: '',
      socialBond: '',
      constructionCharges: '',
      legacy: '',
    },
    searched: {},
    plotPrice: 0,
    portfolio: '',
    freeTags: [],
  },
  PURCHASE_PROFILE: {
    uuid: '',

    // ASSET CLASS

    assetClass: '',
    projectType: [],
    typeOfFinancing: '',
    mezzanineLoan: {
      from: '',
      until: '',
    },
    seniorLoan: {
      from: '',
      until: '',
    },
    wholeLoan: {
      from: '',
      until: '',
    },
    objectType: [],
    investmentVolume: {
      singleFrom: '',
      singleTo: '',
      totalFrom: '',
      totalTo: '',
      portfolioSearch: '',
    },
    portfolioInvestmentVolume: {
      singleFrom: '',
      singleTo: '',
      totalFrom: '',
      totalTo: '',
      minInvestSingle: '',
    },
    totalInvestment: {
      from: '',
      to: '',
    },
    plotPrice: {
      qmFrom: '',
      qmTo: '',
    },
    dealCriteria: {
      dealType: [],
      investmentType: [],
    },
    typeOfUsage: {
      main: '',
      sub: [],
    },

    // LOCATION CRITERIA

    locationCountry: [],
    locationState: [],
    locationCity: [],

    // YIELD AND OBJECT CRITERIA

    factorAndReturn: {
      factorValue: '',
      returnValue: '',
    },
    operatorContract: {
      leaseAgreement: false,
      managementContract: false,
      leaseAgreementMinRemainingTime: '',
      managementContractMinRemainingTime: '',
    },
    units: {
      from: '',
      until: '',
    },
    rentalStatus: {
      from: '',
      until: '',
    },
    singleTenant: false,
    singleTenantMinRunningTime: '',
    multiTenant: false,
    multiTenantMinRunningTime: '',
    anchorTenant: [],
    anchorTenantSplit: {
      spacePercentrageFrom: '',
      spacePercentrageUntil: '',
      rentPercentrageFrom: '',
      rentPercentrageUntil: '',
      minimumTermFrom: '',
      minimumTermUntil: '',
    },
    fixedRentalPrice: '',
    fixedRentalPriceTimespan: {
      from: '',
      until: '',
    },
    generalObjectCriteria: {
      residentialUnitsFrom: '',
      residentialUnitsUntil: '',
      livingSpaceFrom: '',
      livingSpaceUntil: '',
    },
    plotSize: {
      from: '',
      until: '',
    },
    constructionYear: {
      from: '',
      until: '',
    },
    objectCondition: {},
    specialRights: {},
    residentialAndCommercialBuilding: {
      commercialShareFrom: '',
      commercialShareUntil: '',
      shareOfCommercialIncomeFrom: '',
      shareOfCommercialIncomeUntil: '',
    },
    moreTypesOfUsage: [],
    buildingLawSituation: [],
    constructionSituation: {
      bgfFrom: '',
      bgfUntil: '',
      grzFrom: '',
      grzUntil: '',
      gfzFrom: '',
      gfzUntil: '',
    },
    constructionMethod: [],
    usableArea: {
      from: '',
      until: '',
    },
    rooms: {
      from: '',
      until: '',
    },
    beds: {
      from: '',
      until: '',
      ezQuotaFrom: '',
      ezQuotaUntil: '',
    },
    hotel: {
      stars: [],
      characteristics: [],
    },
    retail: {
      shareOfFMCGFrom: '',
      shareOfFMCGUntil: '',
      retailFrom: '',
      retailUntil: '',
    },

    // EXTENDED CRITERIA
    freeTags: [],
    notes: {
      url: '',
      text: '',
    },
    requiredDocuments: [],

    // DATENRAUM

    storageFiles: [],
  },
  PURCHASE_PROFILE_NAVIGATION_INDEX: 0,
}

export default defaultState
