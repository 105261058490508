import { useEffect, useState } from 'react'
import Entry from './Entry/Entry'
import Button from '../../UI/Button/Button'
import './QAndA.scss'
import { useSelector } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'
import Modal from '../../UI/Modal/Modal'
import Input from '../../UI/Input/Input'
import { useParams } from 'react-router-dom'
import { list, create as crudCreate } from '../../../interface/crud'
import props from '../../../redux/props'

const QAndA = () => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)

  const [searchString, setSearchString] = useState('')

  const [showModal, setShowModal] = useState(false)

  const [newQuestion, setNewQuestion] = useState('')

  const [newAnswer, setNewAnswer] = useState('')

  const { uuid: objectUUID } = useParams()

  const [questions, setQuestions] = useState(null)

  useEffect(() => {
    const getQuestions = async () => {
      const result = await list(token, props.QUESTION, { objectUUID })
      // const { status, result } = await fetch('/questions/get', { token, objectUUID })
      if (Array.isArray(result)) setQuestions(result)
    }

    if (token && !questions) getQuestions()
  }, [token, questions, objectUUID, searchString])

  const filter = () => {
    if (searchString.trim().length === 0) return questions
    return questions.filter((q) => q.question.toLowerCase().includes(searchString.toLowerCase()) || q.answer.toLowerCase().includes(searchString.toLowerCase()))
  }

  const create = async () => {
    if (newQuestion && newAnswer) {
      // const { status, result } = await fetch('/questions/create', { token, question: newQuestion, answer: newAnswer, objectUUID })
      const result = await crudCreate(token, props.QUESTION, { question: newQuestion, answer: newAnswer, objectUUID })
      if (result) {
        setQuestions(Array.isArray(questions) ? [...questions, result] : [result])

        setNewQuestion('')
        setNewAnswer('')
        setShowModal(false)
      }
    }
  }

  const close = () => {
    setShowModal(false)
    setNewQuestion('')
    setNewAnswer('')
  }

  return (
    <div id="qAndA">
      <div className="qAndAHeader">
        <div className="search">
          <SearchIcon className="searchIcon" />
          <input className="searchInput" value={searchString} onChange={(e) => setSearchString(e.target.value)} placeholder={translations['chat.messagestab.room-list.search']} />
        </div>
        <div className="buttonWrapper">
          <Button text={translations['q-and-a.create-button']} onClick={() => setShowModal(true)} />
        </div>
      </div>
      <div className="questionList">{Array.isArray(questions) && filter().map((entry) => <Entry entry={entry} />)}</div>
      {showModal && (
        <Modal open={true} close={close} title={translations['q-and-a.create-button']} className="modalCreateQuestion">
          <div className="modalCreateQuestionBody">
            <div>
              <Input value={newQuestion} onChange={(e) => setNewQuestion(e)} placeholder={translations['q-and-a.new-question']} />
            </div>
            <div>
              <Input value={newAnswer} onChange={(e) => setNewAnswer(e)} placeholder={translations['q-and-a.new-answer']} />
            </div>
            <div className="btnWrapper">
              <Button className="colorRed" text={translations['purchaseprofile.remove-request-modal.btn-abort']} onClick={close} />
              <Button text={translations['settings.save-cta']} onClick={create} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default QAndA
