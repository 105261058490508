import { useDispatch, useSelector } from 'react-redux'
import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'
import { useEffect, useState } from 'react'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const ObjectType = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)

  const [selections, setSelections] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    const localSelections = settings.getGroup(payload)

    const selected = localSelections.filter((s) => purchaseProfile.objectType.includes(s.key)).map((s) => s.key)
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, objectType: selected }))
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, objectType: selected }))

    setSelections(localSelections)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  const update = (newArr) => {
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, objectType: newArr }))
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, objectType: newArr }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.object-type']}</div>
        <div className="values">
          <CheckboxGroup options={selections.map((option) => ({ label: translations[option.key], value: option.key }))} values={purchaseProfile.objectType} onChange={update} itemsPerRow={3} />
        </div>
      </div>
    </div>
  )
}

export default ObjectType
