const conditions = () => {
  return [
    { projectType: { $ne: '' } },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.wohnen': 'object.more-types-of-usage.living.options',
          'object.asset-class.options.handel-retail': 'object.more-types-of-usage.living.options',
          'object.asset-class.options.buero': 'object.more-types-of-usage.living.options',
          'object.asset-class.options.logistik-industrie': 'object.more-types-of-usage.living.options',
        },
      },
    },
  ]
}

export default conditions
