import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import { get } from '../../../../../../interface/crud'
import props from '../../../../../../redux/props'

const MeasuresItem = ({ item, remove }) => {
  const [user, setUser] = useState(null)
  const token = useSelector((state) => state.TOKEN)

  useEffect(() => {
    const getUser = async (uuid) => {
      const user = await get(token, props.USER, { uuid })
      if (user) setUser(user)
    }
    if (!user && token && item) getUser(item.userUUID)
  }, [token, user, item])

  return (
    <div>
      <div>{item.measure}</div>
      <div>{item.description}</div>
      <div>{item.year}</div>
      <div>{user ? `${user.firstname} ${user.lastname}` : ''}</div>
      <CloseIcon onClick={remove(item.uuid)} />
    </div>
  )
}

export default MeasuresItem
