import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const Other = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...newObject, other: { ...newObject.other, [key]: value } }
    // dispatch(setNewObject(updatePurchaseProfile))
    dispatch(setLocal(props.OBJECT, updatePurchaseProfile))
  }

  if (!settings || !newObject || !translations) return <></>
  console.log(newObject.other)
  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.other.label']}</div>
          <div className="values">
            <RadioGroup
              name={translations['object.other.monumentprotection']}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.other.monumentProtection}
              onChange={update('monumentProtection')}
            />
            <RadioGroup
              name={translations['object.other.leasehold']}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.other.leasehold}
              onChange={update('leasehold')}
            />
            <RadioGroup
              name={translations['object.other.usufruct']}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.other.usufruct}
              onChange={update('usufruct')}
            />
            <RadioGroup
              name={translations['object.other.socialbond']}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.other.socialBond}
              onChange={update('socialBond')}
            />
            <RadioGroup
              name={translations['object.other.constructioncharges']}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.other.constructionCharges}
              onChange={update('constructionCharges')}
            />
            <RadioGroup
              name={translations['object.other.legacy']}
              options={settings.getGroup('radiogroup.default.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
              value={newObject.other.legacy}
              onChange={update('legacy')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Other
