export const buildLabel = (location) => {
  const { payload, attributes } = location
  const labelItems = []

  for (const attr of attributes) {
    if (payload[attr]) labelItems.push(payload[attr])
  }

  return labelItems.join(', ')
}
