import { useDispatch, useSelector } from 'react-redux'
// import { useEffect, useState } from 'react'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const More = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)

  const newObject = useSelector((state) => state.OBJECT)

  const dispatch = useDispatch()

  const update = (key) => (value) => {
    // dispatch(setNewObject({ ...newObject, more: { ...newObject.more, [key]: value } }))
    dispatch(setLocal(props.OBJECT, { ...newObject, more: { ...newObject.more, [key]: value } }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.more']}</div>
        <div className="values">
          <Input type="text" value={newObject.more.objectDescription} onChange={update('objectDescription')} placeholder={translations['object.other.objectdescription']} />
          <Input type="text" value={newObject.more.locationDescription} onChange={update('locationDescription')} placeholder={translations['object.other.locationdescription']} />
          <Input type="text" value={newObject.more.more} onChange={update('more')} placeholder={translations['object.other.more']} />
        </div>
      </div>
    </div>
  )
}

export default More
