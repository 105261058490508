
// import { useSelector } from "react-redux";
import "./Requested.scss";

const Requested = () => {
  //   const translations = useSelector(state => state.translations)

  return (
    <div className="new-objects-requested">
      requested objects
    </div>
  );
};

export default Requested;