import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { getUUID } from '../../../../../../utility'
import Input from '../../../../../UI/Input/Input'
import defaultState from '../../../../../../reducer/defaultState'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const SingleTenant = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const mapping = {
      singleTenant: 'singleTenantMinRunningTime',
    }
    const values = {}
    if (value === false && mapping[key]) {
      values[mapping[key]] = defaultState.purchaseProfile[mapping[key]]
    }
    values[key] = value

    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, ...values }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, ...values }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="values">
          <Checkbox key={getUUID()} checked={purchaseProfile.singleTenant} onChange={update('singleTenant')} text={translations['my-objects.pipeline.yield-and-object-criteria.single-tenant']} />
        </div>
      </div>
      <div className="group">
        <div className="values">
          <Input
            type="customNumber"
            prefix={translations['min.symbol']}
            disabled={!purchaseProfile.singleTenant}
            value={purchaseProfile.singleTenantMinRunningTime}
            onChange={update('singleTenantMinRunningTime')}
            symbol={translations['years.symbol']}
            suffix={translations['remainingtime.symbol']}
          />
        </div>
      </div>
    </div>
  )
}

export default SingleTenant
