import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import { useSelector } from 'react-redux'
import './MyObjects.scss'
import FullScreenHeader from '../../Layout/Dashboard/FullScreenHeader/FullScreenHeader'
import TabView from '../../UI/TabView/TabView'
import Active from './Active/Active'
import Paused from './Paused/Paused'
import Link from '../../UI/Link/Link'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

const MyObjects = () => {
  const translations = useSelector((state) => state.translations)
  const tabItems = [
    { name: translations['my-objects.tab.aktive-objects'], component: <Active /> },
    { name: translations['my-objects.tab.paused-objects'], component: <Paused /> },
  ]

  return (
    <DashboardLayout>
      <div id="my-objects">
        <FullScreenHeader headline={translations['my-objects.headline']} />
        <TabView items={tabItems} />
        <div className="new-object-link-wrapper">
          <Link href="/dashboard/new-objects/pipeline">
            <div className="new-object">
              <div className="button">
                <AddOutlinedIcon />
              </div>
              <div className="text">{translations['new-object.pipeline.cta']}</div>
            </div>
          </Link>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default MyObjects
