import { useDispatch, useSelector } from 'react-redux'
import { ImageList, ImageListItem } from '@mui/material'
import PhotoUpload from './PhotoUpload'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const Photos = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)

  const dispatch = useDispatch()

  const addPhotos = (photos) => {
    console.log(photos)
    // dispatch(setNewObject({ ...newObject, photos: [...newObject.photos, ...photos.map((p) => ({ title: p.name, img: p.url }))] }))
    dispatch(setLocal(props.OBJECT, { ...newObject, photos: [...newObject.photos, ...photos.map((p) => ({ title: p.name, img: p.url }))] }))
  }

  const srcset = (image, size, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    }
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.photos']}</div>
        <div className="values">
          <ImageList sx={{ width: 500, height: 450 }} variant="quilted" cols={3} rowHeight={121}>
            {newObject.photos.map((item) => (
              <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                <img {...srcset(item.img, 121, item.rows, item.cols)} alt={item.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
          <PhotoUpload addPhotos={addPhotos} />
        </div>
      </div>
    </div>
  )
}

export default Photos
