import Button from '../../../UI/Button/Button'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'

const ExportImport = ({ selectedLang }) => {
  const [exportText, setExportText] = useState('')
  const [exportTextOpen, setExportTextOpen] = useState(false)
  const [importText, setImportText] = useState('')
  const [importTextOpen, setImportTextOpen] = useState(false)
  const [importTranslationsFileText, setImportTranslationsFileText] = useState('')
  const [importTranslationsFileTextOpen, setImportTranslationsFileTextOpen] = useState(false)
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)

  const exportTranslations = async () => {
    const { status, text } = await fetch('/admin/translations/export', { token, lang: selectedLang })
    if (status && text) {
      setExportText(text)
      setExportTextOpen(true)
    }
  }

  const importTranslations = async () => {
    if (importText.trim().length > 0) {
      const { status } = await fetch('/admin/translations/import', { token, lang: selectedLang, text: importText })
      if (status) {
        setImportText('')
        setImportTextOpen(false)
      }
    }
  }

  const importTranslationFile = async () => {
    if (importTranslationsFileText.trim().length > 0) {
      const { status } = await fetch('/admin/translations/import-translations-from-file-text', { token, lang: selectedLang, text: importTranslationsFileText })
      if (status) {
        setImportTranslationsFileText('')
        setImportTranslationsFileTextOpen(false)
      }
    }
  }

  return (
    <div className="import-export">
      <div className="export-wrapper" onClick={exportTranslations}>
        {translations['admin.translations.export']}
      </div>
      {exportTextOpen && (
        <div className="export-text">
          <textarea value={exportText} readOnly />
          <div className="controls">
            <Button className="close" text={translations['admin.translations.export.close']} onClick={() => setExportTextOpen(false)} />
          </div>
        </div>
      )}
      <div className="import-wrapper" onClick={() => setImportTextOpen(true)}>
        {translations['admin.translations.import']}
      </div>
      {importTextOpen && (
        <div className="import-text">
          <textarea value={importText} onChange={(e) => setImportText(e.target.value)} />
          <div className="controls">
            <Button className="process" text={translations['admin.translations.import.process']} onClick={importTranslations} />
            <Button className="close" text={translations['admin.translations.import.close']} onClick={() => setImportTextOpen(false)} />
          </div>
        </div>
      )}
      <div className="import-wrapper" onClick={() => setImportTranslationsFileTextOpen(true)}>
        {translations['admin.translations.import.translations-from-file']}
      </div>
      {importTranslationsFileTextOpen && (
        <div className="import-translations-from-file-text">
          <textarea value={importTranslationsFileText} onChange={(e) => setImportTranslationsFileText(e.target.value)} />
          <div className="controls">
            <Button className="process" text={translations['admin.translations.import.translations-from-file.process']} onClick={importTranslationFile} />
            <Button className="close" text={translations['admin.translations.import.translations-from-file.close']} onClick={() => setImportTranslationsFileTextOpen(false)} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ExportImport
