import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Input from '../../../../../UI/Input/Input'
import Button from '../../../../../UI/Button/Button'
import CloseIcon from '@mui/icons-material/Close'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const FreeTags = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)

  const [currentFreeTagValue, setCurrentFreeTagValue] = useState('')

  const dispatch = useDispatch()

  const remove = (index) => () => {
    const newTags = [...purchaseProfile.freeTags].filter((item, i) => i !== index)
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, freeTags: newTags }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, freeTags: newTags }))
  }

  const save = () => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, freeTags: [...purchaseProfile.freeTags, currentFreeTagValue] }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, freeTags: [...purchaseProfile.freeTags, currentFreeTagValue] }))
    setCurrentFreeTagValue('')
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.navigation.extendet-criteria.free-tags']}</div>
        <div className="values">
          <Input
            className="value-input"
            value={currentFreeTagValue}
            onChange={(e) => setCurrentFreeTagValue(e)}
            placeholder={translations['my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.key']}
          />
          <Button className="remove" text={translations['my-objects.pipeline.navigation.extendet-criteria.free-tags.tag.save']} onClick={save} />
          <div className="tag-list bottom">
            {purchaseProfile.freeTags.map((tag, i) => {
              return (
                <div className="tag" key={i}>
                  <div className="value">{tag}</div>
                  <CloseIcon className="btnRemove" onClick={remove(i)} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreeTags
