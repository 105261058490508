import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../../../UI/Dropdown/Dropdown'
import Input from '../../../../../UI/Input/Input'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const ObjectData = ({ widthInPercent, payload }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updateNewObject = { ...newObject, objectData: { ...newObject.objectData, [key]: value } }
    // dispatch(setNewObject(updateNewObject))
    dispatch(setLocal(props.OBJECT, updateNewObject))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.object-data']}</div>
          <div className="values">
            <Input value={newObject.objectData.year} onChange={update('year')} placeholder={translations['object.object-data.year']} />
            <Input value={newObject.objectData.size} onChange={update('size')} placeholder={translations['object.object-data.size']} />
            <Dropdown
              name={translations['my-objects.pipeline.assets-class.asset-class']}
              value={newObject.objectData.condition}
              onChange={update('condition')}
              options={settings.getGroup('object.asset-class.options').map((option) => ({
                key: option.key,
                value: translations[option.translationKey],
              }))}
            />
            {payload && payload.showLift && <Checkbox checked={newObject.objectData.lift} onChange={update('lift')} text={translations['object.object-data.lift']} />}
            {payload && payload.showBasement && <Checkbox checked={newObject.objectData.basement} onChange={update('basement')} text={translations['object.object-data.basement']} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectData
