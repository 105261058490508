import Layout from '../Layout'
import './Dashboard.scss'
import logo from '../../../assets/image/logo.png'
import { fallbackProfilImage, projectName } from '../../../utility'
import { useDispatch, useSelector } from 'react-redux'
import ImageWrapper from '../../ImageWrapper/ImageWrapper'
import Link from '../../UI/Link/Link'
import Cookies from 'universal-cookie'
import { setUser, setUserToken } from '../../../action/user'
import { useHistory } from 'react-router-dom'

const Dashboard = (props) => {
  const translations = useSelector((state) => state.translations)
  const user = useSelector((state) => state.USER)
  const { overwriteNavigationComponent } = props
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const history = useHistory()

  const logout = (e) => {
    e.preventDefault()
    cookies.remove('token', { path: '/' })
    dispatch(setUser(null))
    dispatch(setUserToken(null))
    history.push('/')
  }

  return (
    <Layout authRequired={true} header={false} fullsize={true}>
      <div id="dashboard-layout">
        <div id="sidebar">
          <div className="logo">
            <Link href="/dashboard/new-objects">
              <img src={logo} alt={projectName} />
            </Link>
          </div>
          {!overwriteNavigationComponent && (
            <div className="navigation-wrapper">
              {user && user.isAdmin ? (
                <div className="navigation">
                  <div className="title">{translations['layout-dashboard.navigation.admin.title']}</div>
                  <div className="item">
                    <Link href="/admin/users" text={translations['layout-dashboard.navigation.admin.settings']} />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="navigation">
                <div className="title">{translations['layout-dashboard.navigation.purchase.title']}</div>
                <div className="item">
                  <Link href="/dashboard/new-objects" text={translations['layout-dashboard.navigation.purchase.new-objects']} />
                </div>
                <div className="item">
                  <Link href="" text={translations['layout-dashboard.navigation.purchase.in-examination']} />
                </div>
                <div className="item">
                  <Link href="" text={translations['layout-dashboard.navigation.purchase.declined']} />
                </div>
                <div className="item">
                  <Link href="" text={translations['layout-dashboard.navigation.purchase.bought']} />
                </div>
                <div className="item">
                  <Link href="/dashboard/purchase-profile" text={translations['layout-dashboard.navigation.purchase.purchase-profile']} />
                </div>
              </div>
              <div className="navigation">
                <div className="title">{translations['layout-dashboard.navigation.sales.title']}</div>
                <div className="item">
                  <Link href="/dashboard/my-objects" text={translations['layout-dashboard.navigation.sales.my-objects']} />
                </div>
                <div className="item">
                  <Link href="" text={translations['layout-dashboard.navigation.sales.interest-requests']} />
                </div>
                <div className="item">
                  <Link href="" text={translations['layout-dashboard.navigation.sales.received-offers ']} />
                </div>
              </div>
              <div className="profile">
                <div className="image">
                  <ImageWrapper image={user && user.profileImage ? user.profileImage : fallbackProfilImage} round={true} />
                </div>
                <div className="name-logout">
                  <div className="name">
                    <Link href="/dashboard/settings" text={user && `${user.firstname} ${user.lastname}`} />
                  </div>
                  <div className="logout-wrapper">
                    <div className="logout" onClick={logout}>
                      {translations['layout-dashboard.logout']}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {overwriteNavigationComponent && overwriteNavigationComponent}
        </div>
        <div id="dashboard-content-wrapper" className={`${props.card ? 'card' : ''}`}>
          <div id="dashboard-content">{props.children}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard
