import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../../../UI/Dropdown/Dropdown'
import Input from '../../../../../UI/Input/Input'
import Button from '../../../../../UI/Button/Button'
import { setLocationState } from '../../../../../../action/purchaseProfile'
import LocationSuggestion from './LocationSuggestion'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import './LocationCard.scss'
import { buildLabel } from './buildLabel'

const State = () => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const [selectedState, setSelectedState] = useState(null)
  const [abcd, setAbcd] = useState('')
  const [minPopulation, setMinPopulation] = useState('')

  const abcdOptions = settings.getGroup('my-objects.pipeline.location-criteria.abcd.options').map((option) => ({ key: option.value, value: option.value }))

  const onClickAdd = () => {
    dispatch(setLocationState([...purchaseProfile.locationState, { uuid: selectedState.key, abcd, minPopulation, payload: selectedState.payload, attributes: selectedState.attributes }]))

    setSelectedState(null)
    setMinPopulation('')
    setAbcd('')
  }

  const onClickRemove = (uuid) => () => {
    dispatch(setLocationState(purchaseProfile.locationState.filter((el) => el.uuid !== uuid)))
  }

  return (
    <div className="locationCard">
      <div className="locationTitle">{translations['location.title.state']}</div>
      <div className="locationInputFullWidth">
        <LocationSuggestion fetchURI={'state'} onChange={setSelectedState} name={translations['my-objects.pipeline.location-criteria.state']} />
      </div>
      <div className="locationSubTitle">{translations['location.more-criterions']}</div>
      <div className="locationInputNextTo">
        <Input type="number" min={0} value={minPopulation} onChange={setMinPopulation} placeholder={translations['my-objects.pipeline.location-criteria.min-population']} />
        <Dropdown name={translations['my-objects.pipeline.location-criteria.abcd-selection']} options={abcdOptions} value={abcd} onChange={setAbcd} />
      </div>
      <Button disabled={selectedState === null} onClick={onClickAdd} text={translations['add-cta']} fullWidth={false} />
      <div className="itemList">
        {Array.isArray(purchaseProfile.locationState) &&
          purchaseProfile.locationState.map((location) => (
            <div className="locationListItem" key={location.uuid}>
              <div className="value">{`${buildLabel(location)} ${location.minPopulation && `| ${location.minPopulation}`} `}</div>
              <CancelSharpIcon className="btnRemove" onClick={onClickRemove(location.uuid)} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default State
