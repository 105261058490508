import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'

const RequiredDocuments = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const onChangeRequiredDocuments = (newArr) => {
    // const requiredDocuments = checked ? [...purchaseProfile.requiredDocuments, key] : purchaseProfile.requiredDocuments.filter((e) => e !== key)
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, requiredDocuments: newArr }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, requiredDocuments: newArr }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.additional-criteria.object-criteria.required-documents']}</div>
        <div className="values">
          <CheckboxGroup
            options={settings.getGroup(payload).map((option) => ({ label: translations[option.key], value: option.key }))}
            values={purchaseProfile.requiredDocuments}
            onChange={onChangeRequiredDocuments}
            itemsPerRow={3}
          />
        </div>
      </div>
    </div>
  )
}

export default RequiredDocuments
