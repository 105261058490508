import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const PlotPrice = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, plotPrice: { ...purchaseProfile.plotPrice, [key]: value } }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, plotPrice: { ...purchaseProfile.plotPrice, [key]: value } }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.plot-price']}</div>
        <div className="values">
          <div className="subgroup">
            <div className="element">
              <Input
                type="customNumber"
                symbol={translations['currency.string']}
                value={purchaseProfile.plotPrice.qmFrom}
                onChange={update('qmFrom')}
                placeholder={translations['my-objects.pipeline.assets-class.plot-price.from']}
              />
            </div>
            <div className="element">
              <Input
                type="customNumber"
                symbol={translations['currency.string']}
                value={purchaseProfile.plotPrice.qmTo}
                onChange={update('qmTo')}
                placeholder={translations['my-objects.pipeline.assets-class.plot-price.to']}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlotPrice
