import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const SellingPrice = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)

  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (value) => {
    // dispatch(setNewObject({ ...newObject, sellingPrice: value }))
    dispatch(setLocal(props.OBJECT, { ...newObject, sellingPrice: value }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.sales-price']}</div>
        <div className="values">
          <Input type="customNumber" placeholder={translations['object.sales-price']} value={newObject.sellingPrice} onChange={update} symbol={translations['currency.string']} />
        </div>
      </div>
    </div>
  )
}

export default SellingPrice
