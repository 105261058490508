import './PasswordReset.scss'
import fetch from '../../../fetch'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Layout from '../../Layout/Layout'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Link from '../../UI/Link/Link'
import { useParams } from 'react-router-dom'

const PasswordReset = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const translations = useSelector((state) => state.translations)
  const [message, setMessage] = useState(null)
  const params = useParams()

  const submit = async (e) => {
    e.preventDefault()
    if (!!password && !!passwordConfirm && password === passwordConfirm && !!params.code) {
      setMessage(null)
      const { status } = await fetch('/auth/password-reset', {
        code: params.code,
        password,
      })
      setMessage(status ? translations['password-reset.success'] : translations['password-reset.unknown-error'])
    } else {
      setMessage(translations['password-reset.missing-input'])
    }
  }

  return (
    <Layout>
      <div id="password-reset">
        <div className="form-wrapper">
          <div className="headline">{translations['password-reset.headline']}</div>
          <form onSubmit={submit}>
            <div className="element-wrapper">
              <Input type="password" placeholder={translations['password-reset.password']} value={password} onChange={setPassword} />
            </div>
            <div className="element-wrapper">
              <Input type="password" placeholder={translations['password-reset.password']} value={passwordConfirm} onChange={setPasswordConfirm} />
            </div>
            {message ? <div className="message">{message}</div> : ''}
            {!message ? <div className="message">{translations['password-reset.explain']}</div> : ''}
            <div className="element-wrapper">
              <Button text={translations['password-reset.button']} type={'submit'} />
            </div>
            <div className="switches">
              <div className="switch">
                <Link href="/login" text={translations['password-reset.login']} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default PasswordReset
