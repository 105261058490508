import './Login.scss'
import Cookies from 'universal-cookie'
import fetch, { METHOD } from '../../../fetch'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import Layout from '../../Layout/Layout'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Link from '../../UI/Link/Link'
import { get } from '../../../interface/crud'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const translations = useSelector((state) => state.translations)
  const cookies = new Cookies()
  const [show2FAInput, setShow2FAInput] = useState(false)
  const [twoFAInput, setTwoFAInput] = useState('')

  const successfulLogin = (token, user) => {
    console.log('successfulLogin')
    dispatch(setLocal(props.TOKEN, token))
    // dispatch(setUserToken(token))
    dispatch(setLocal(props.USER, user))
    // dispatch(setUser(user))
    cookies.set('token', token, { path: '/' })
    history.push(user.firstLogin ? '/dashboard/settings?firstlogin=true' : '/dashboard/new-objects')
  }

  const login = async (twoFACode) => {
    if (mail.length > 0 && password.length > 0) {
      setError(null)
      const response = await fetch(METHOD.POST, '/auth/login', {
        identifier: mail,
        password,
        twoFaToken: twoFACode,
      })

      const { status, token, twoFaEnabled } = response
      const { account } = await fetch(METHOD.POST, '/auth/resolve-token', { token })
      const user = await get(token, 'USER', { uuid: account.assignedToUUID })
      console.log(response)
      console.log(account)
      console.log(user)
      if (status && token && user) {
        successfulLogin(token, user)
      } else if (status && twoFaEnabled === true) {
        setShow2FAInput(true)
      } else {
        setError(translations['login.wrong-credentials'])
      }
    } else {
      setError(translations['login.missing-inputs'])
    }
  }

  const submit = (e) => {
    e.preventDefault()
    login()
  }

  const loginWith2FA = (input) => {
    setTwoFAInput(input.trim())
    if (input.trim().length === 6) {
      login(input)
    }
  }

  return (
    <Layout>
      <div id="login">
        <div className="form-wrapper">
          <div className="headline">{translations['login.headline']}</div>
          <form onSubmit={submit}>
            <div className="element-wrapper">
              <Input type="email" placeholder={translations['login.mail']} value={mail} onChange={setMail} />
            </div>
            <div className="element-wrapper">
              <Input type="password" placeholder={translations['login.password']} value={password} onChange={setPassword} />
            </div>
            {show2FAInput && (
              <div className="element-wrapper">
                <Input placeholder={translations['login.2fa.code-input']} value={twoFAInput} onChange={loginWith2FA} />
              </div>
            )}
            {error ? <div className="error">{error}</div> : ''}
            <div className="element-wrapper">
              <Button text={translations['login.button']} type={'submit'} />
            </div>
            <div className="switches">
              <div className="switch">
                <Link href="/password-lost" text={translations['login.password-lost']} />
              </div>
              <div className="switch">
                <Link href="/registration" text={translations['login.registration']} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Login
