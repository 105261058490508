const props = {
  TOKEN: 'TOKEN',
  ACCOUNT: 'ACCOUNT',
  CURRENTLANG: 'CURRENTLANG',
  TRANSLATION: 'TRANSLATION',
  USER: 'USER',
  SELECTED_PROJECT: 'SELECTED_PROJECT',
  OBJECT: 'OBJECT',
  PURCHASE_PROFILE: 'PURCHASE_PROFILE',
  PURCHASE_PROFILE_NAVIGATION_INDEX: 'PURCHASE_PROFILE_NAVIGATION_INDEX',
  QUESTION: 'QUESTION',
  COMPANY: 'COMPANY',
  STORAGE: 'STORAGE',
  MATCHING_LOG: 'MATCHING_LOG',
}

export default props
