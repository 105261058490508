import { useSelector } from 'react-redux'
import './YieldAndObjectCriteria.scss'
import CardHeader from '../../../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../../../Layout/Dashboard/CardContent/CardContent'
import Condition from '../Condition/Condition'
import FactorAndReturn from './SubComponents/FactorAndReturn'
import OperatorContract from './SubComponents/OperatorContract'
import RentalStatus from './SubComponents/RentalStatus'
import SingleTenant from './SubComponents/SingleTenant'
import MultiTenant from './SubComponents/MultiTenant'
import AnchorTenant from './SubComponents/AnchorTenant'
import AnchorTenantSplit from './SubComponents/AnchorTenantSplit'
import FixedRentalPrice from './SubComponents/FixedRentalPrice'
import FixedRentalPriceTimespan from './SubComponents/FixedRentalPriceTimespan'
import GeneralObjectCriteria from './SubComponents/GeneralObjectCriteria'
import Plot from './SubComponents/Plot'
import ConstructionYear from './SubComponents/ConstructionYear'
import ObjectCondition from './SubComponents/ObjectCondition'
import Units from './SubComponents/Units'
import SpecialRights from './SubComponents/SpecialRights'
import ResidentialAndCommercialBuilding from './SubComponents/ResidentialAndCommercialBuilding'
import BuildingLawSituation from './SubComponents/BuildingLawSituation'
import ConstructionSituation from './SubComponents/ConstructionSituation'
import ConstructionMethod from './SubComponents/ConstructionMethod'
import UsableArea from './SubComponents/UsableArea'
import Rooms from './SubComponents/Rooms'
import Beds from './SubComponents/Beds'
import Hotel from './SubComponents/Hotel'
import Retail from './SubComponents/Retail'
import CardFooter from '../../../../Layout/Dashboard/CardFooter/CardFooter'

const PurchaseProfilePipelineYieldAndObjectCriteria = ({ NavigationButtons }) => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)

  return (
    <div className="purchase-profile-pipeline-yield-and-object-criteria">
      <CardHeader headline={translations[purchaseProfile.assetClass]} />
      <CardContent>
        <div className="section">
          <div className="input-groups">
            <Condition defaultStateName="factorAndReturn" Component={FactorAndReturn} />
            <Condition defaultStateName="operatorContract" Component={OperatorContract} />
            <Condition defaultStateName="rentalStatus" Component={RentalStatus} />
            <Condition defaultStateName="singleTenant" Component={SingleTenant} />
            <Condition defaultStateName="multiTenant" Component={MultiTenant} />
            <Condition defaultStateName="anchorTenant" Component={AnchorTenant} />
            <Condition defaultStateName="anchorTenantSplit" Component={AnchorTenantSplit} />
            <Condition defaultStateName="fixedRentalPrice" Component={FixedRentalPrice} />
            <Condition defaultStateName="fixedRentalPriceTimespan" Component={FixedRentalPriceTimespan} />
            <Condition defaultStateName="generalObjectCriteria" Component={GeneralObjectCriteria} />
            <Condition defaultStateName="plot" Component={Plot} />
            <Condition defaultStateName="constructionYear" Component={ConstructionYear} />
            <Condition defaultStateName="objectCondition" Component={ObjectCondition} />
            <Condition defaultStateName="units" Component={Units} />
            <Condition defaultStateName="specialRights" Component={SpecialRights} />
            <Condition defaultStateName="residentialAndCommercialBuilding" Component={ResidentialAndCommercialBuilding} />
            <Condition defaultStateName="buildingLawSituation" Component={BuildingLawSituation} />
            <Condition defaultStateName="constructionSituation" Component={ConstructionSituation} />
            <Condition defaultStateName="constructionMethod" Component={ConstructionMethod} />
            <Condition defaultStateName="usableArea" Component={UsableArea} />
            <Condition defaultStateName="rooms" Component={Rooms} />
            <Condition defaultStateName="beds" Component={Beds} />
            <Condition defaultStateName="hotel" Component={Hotel} />
            <Condition defaultStateName="retail" Component={Retail} />
          </div>
        </div>
      </CardContent>
      <CardFooter>{NavigationButtons}</CardFooter>
    </div>
  )
}

export default PurchaseProfilePipelineYieldAndObjectCriteria
