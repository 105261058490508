import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

import Input from '../../../../../UI/Input/Input'

const YieldCriteria = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (type) => (value) => {
    const updateNewObject = { ...newObject, yieldCriteria: { ...newObject.yieldCriteria, [type]: value } }
    // dispatch(setNewObject(updateNewObject))
    dispatch(setLocal(props.OBJECT, updateNewObject))
  }

  if (!newObject) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.yield-criteria']}</div>
          <div className="values">
            <Input
              type="number"
              value={newObject.yieldCriteria.livingSize}
              onChange={update('livingSize')}
              placeholder={translations['object.yield-criteria.livingsize']}
              suffix={translations['square-meter.symbol']}
            />
            <Input
              type="number"
              value={newObject.yieldCriteria.commercialSize}
              onChange={update('commercialSize')}
              placeholder={translations['object.yield-criteria.commercialsize']}
              suffix={translations['square-meter.symbol']}
            />
            <Input
              type="number"
              value={newObject.yieldCriteria.livingIncomming}
              onChange={update('livingIncomming')}
              placeholder={translations['object.yield-criteria.livingincomming']}
              suffix={translations['per-month.symbol']}
            />
            <Input
              type="number"
              value={newObject.yieldCriteria.commercialIncomming}
              onChange={update('commercialIncomming')}
              placeholder={translations['object.yield-criteria.commercialincomming']}
              suffix={translations['per-month.symbol']}
            />
            <Input type="number" value={newObject.yieldCriteria.livingCount} onChange={update('livingCount')} placeholder={translations['object.yield-criteria.livingcount']} />
            <Input type="number" value={newObject.yieldCriteria.commercialCount} onChange={update('commercialCount')} placeholder={translations['object.yield-criteria.commercialcount']} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default YieldCriteria
