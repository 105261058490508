const conditions = () => {
  return [
    { projectType: { $ne: '' } },
    {
      assetClass: {
        $in: [
          'object.asset-class.options.hotel',
          'object.asset-class.options.healthcare-medical',
          'object.asset-class.options.micro-und-serviced-living',
          'object.asset-class.options.freies-ankaufsprofil',
        ],
      },
    },
  ]
}

export default conditions
