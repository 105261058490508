import { useState } from 'react'
import './ImageWrapper.scss'
import { getUUID } from '../../utility'
import UploadIcon from '@mui/icons-material/Upload'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { useSelector } from 'react-redux'
import { fileUploadS3Public } from '../../fetch'

const ImageWrapper = ({ image, round, uploadType, uploadedCallback }) => {
  const uuid = getUUID()
  const [processing, setProcessing] = useState(false)
  const [preview, setPreview] = useState(false)
  const token = useSelector((state) => state.TOKEN)

  const uploadImage = async (files) => {
    if (files.length === 1) {
      const file = files[0]
      setProcessing(true)
      const imageURL = await fileUploadS3Public(token, file, uploadType)
      if (imageURL) {
        setPreview(imageURL)
        uploadedCallback(imageURL)
      }
      setProcessing(false)
    }
  }

  const uploadHandler =
    token && uploadType && typeof uploadedCallback === 'function' ? (
      <div className="upload-handler">
        <input type="file" id={uuid} className="upload-input" accept="image/*" onChange={(e) => uploadImage(e.target.files)} />
        {!processing && (
          <label htmlFor={uuid} className="upload-input-label">
            <UploadIcon />
          </label>
        )}
        {processing && (
          <div className="processing">
            <AutorenewIcon />
          </div>
        )}
      </div>
    ) : (
      ''
    )

  if (round) {
    return (
      <div className="universal-image-wrapper-round" style={{ backgroundImage: `url(${preview || image})` }}>
        {uploadHandler}
      </div>
    )
  }
  return (
    <div className="universal-image-wrapper">
      <img src={preview || image} alt={preview || image} />
      {uploadHandler}
    </div>
  )
}

export default ImageWrapper
