import { useSelector } from 'react-redux'
import './LocationCriteria.scss'
import CardHeader from '../../../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../../../Layout/Dashboard/CardContent/CardContent'
import Condition from '../Condition/Condition'
import Country from './SubComponents/Country'
import State from './SubComponents/State'
import City from './SubComponents/City'
import CardFooter from '../../../../Layout/Dashboard/CardFooter/CardFooter'

const PurchaseProfilePipelineLocationCriteria = ({ NavigationButtons }) => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)

  return (
    <div className="purchase-profile-pipeline-location-criteria">
      <CardHeader headline={translations[purchaseProfile.assetClass]} />
      <CardContent>
        <div className="section">
          <div className="input-groups">
            <Condition defaultStateName="location-country" Component={Country} />
            <Condition defaultStateName="location-state" Component={State} />
            <Condition defaultStateName="location-city" Component={City} />
          </div>
        </div>
      </CardContent>
      <CardFooter>{NavigationButtons}</CardFooter>
    </div>
  )
}

export default PurchaseProfilePipelineLocationCriteria
