import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import { formatFileSize } from '../../../../utility'
import Button from '../../../UI/Button/Button'
import CloseIcon from '@mui/icons-material/Close'
import './File.scss'
// import call from '../../../../interface/functional'

const File = ({ file, remove, enableDownload }) => {
  const token = useSelector((state) => state.TOKEN)
  const translations = useSelector((state) => state.translations)

  const getDownloadURL = async () => {
    // const url = await call(token)
    const { status, url } = await fetch('/storage/download', { token, uuid: file.uuid })
    if (status && url) {
      return url
    }
  }

  const download = async () => {
    const downloadURL = await getDownloadURL()
    if (downloadURL) {
      const link = document.createElement('a')
      link.href = downloadURL
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()
    }
  }

  return (
    <div className="storage-file" onClick={download}>
      <div className="name">{file.filename}</div>
      <div className="size">{formatFileSize(file.filesize)}</div>
      {!enableDownload && (
        <div className="remove-wrapper">
          <CloseIcon fontSize="medium" onClick={() => remove(file)} className="remove" />
        </div>
      )}
      {enableDownload && (
        <div className="download-wrapper">
          <Button text={translations['storage.file.download-cta']} onClick={download} />
        </div>
      )}
    </div>
  )
}

export default File
