import { backendDomain, presignedUploadURLEndpoint } from './utility'
import axios from 'axios'

export const uploadTypes = {
  PROFILE_IMAGE: 'PROFILE_IMAGE',
  COMPANY_IMAGE: 'COMPANY_IMAGE',
  CHAT_ATTACHMENT: 'CHAT_ATTACHMENT',
  DATENRAUM: 'DATENRAUM',
  PURCHASE_PROFILE_REQUIRED_DOCUMENTS: 'PURCHASE_PROFILE_REQUIRED_DOCUMENTS',
  OBJECT_IMAGE: 'OBJECT_IMAGE',
}

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

const mergeRequestHeaders = (headers, mergeObject) => {
  if (!headers) return mergeObject
  return { ...headers, ...mergeObject }
}

const prepRequestForDownload = (request, fileDownloadMimeType) => {
  if (!fileDownloadMimeType) return request
  request.responseType = 'blob'
  request.headers = mergeRequestHeaders(request.headers, {
    Accept: fileDownloadMimeType,
  })
  return request
}

const prepRequestForUpload = (request, body, filesForUpload, uploadProgressCallback) => {
  if (!filesForUpload) return request

  request.method = METHOD.POST

  const formData = new FormData()
  if (Array.isArray(filesForUpload)) {
    filesForUpload.forEach((file) => formData.append('files', file))
  }
  if (typeof body === 'object') {
    Object.keys(body).forEach((key) => formData.append(key, body[key]))
  }

  if (typeof body === 'object' && body.token) {
    request.url = `${request.url}?token=${body.token}`
  }

  request.data = formData
  request.headers = mergeRequestHeaders(request.headers, {
    'Content-Type': 'multipart/form-data',
  })

  if (typeof progressCallback === 'function') {
    request.onUploadProgress = (e) => {
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      uploadProgressCallback(progress)
    }
  }
  return request
}

const fetch = async (method, uriSuffix, body = {}, filesForUpload = null, uploadProgressCallback = null, fileDownloadMimeType = null) => {
  try {
    if (!uriSuffix) return false
    let request = {
      method,
      url: `${backendDomain}${uriSuffix}`,
      [method === METHOD.GET ? 'params' : 'data']: body,
    }

    request = prepRequestForDownload(request, fileDownloadMimeType)
    request = prepRequestForUpload(request, body, filesForUpload, uploadProgressCallback)

    const result = await axios(request)
    return result.data
  } catch (err) {
    return false
  }
}

export const s3Upload = async (presignedURL, file, progressCallback) => {
  const uploadResult = await axios.put(presignedURL, file, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (e) => {
      if (typeof progressCallback !== 'function') return
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      progressCallback(progress)
    },
  })
  return uploadResult.status === 200
}

export const s3Download = async (presignedURL) => {
  let link = document.createElement('a')
  link.href = presignedURL
  link.click()
}

export const fetchGet = async (uriSuffix, overwriteURL = false, headers = {}) => {
  try {
    if (!uriSuffix) return false
    const result = await axios.get(overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`, headers)
    return result
  } catch (err) {
    return false
  }
}

const fileUploadS3Core = async (token, file, type, privacyMode, progressCallback) => {
  const fileMeta = {
    name: file.name,
    type: file.type,
    size: file.size,
  }
  const { status, presignedURL, fileURL, key } = await fetch(presignedUploadURLEndpoint, {
    token,
    fileMeta,
    type,
    privacyMode,
  })
  if (status && presignedURL && (fileURL || key)) {
    const uploadResult = await axios.put(presignedURL, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (e) => {
        if (typeof progressCallback !== 'function') return
        const progress = parseInt(Math.round((e.loaded / e.total) * 100))
        progressCallback(progress)
      },
    })
    return uploadResult.status === 200 ? fileURL || key : false
  } else {
    return false
  }
}

export const fileUploadS3Public = (token, file, type, progressCallback) => {
  return fileUploadS3Core(token, file, type, 'public', progressCallback)
}

export const fileUploadS3Private = async (token, file, type, progressCallback) => {
  return fileUploadS3Core(token, file, type, 'private', progressCallback)
}

export const fileUploadToBackend = async (token, uriSuffix, file, progressCallback) => {
  const formData = new FormData()
  formData.append('file', file)
  const uploadResult = await axios.post(`${backendDomain}${uriSuffix}?token=${token}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (e) => {
      if (typeof progressCallback !== 'function') return
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      progressCallback(progress)
    },
  })
  return uploadResult.status === 200
}

export default fetch
