import { useSelector } from 'react-redux'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import Input from '../../../UI/Input/Input'
import ImageWrapper from '../../../ImageWrapper/ImageWrapper'
import { fallbackCompanyImage, getUUID } from '../../../../utility'
import { uploadTypes } from '../../../../fetch'
import './CompanyForm.scss'

const CompanyForm = ({ company, updateCompanyProperty }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)

  const updateIntentions = (key) => (checked) => {
    const update = updateCompanyProperty('intentions')
    if (checked) {
      update([...company.intentions, key])
    } else {
      update([...company.intentions.filter((r) => r !== key)])
    }
  }

  return (
    <div className="section">
      <div className="headline">{translations['settings.company-data.headline']}</div>
      <div className="column profile-image">
        <ImageWrapper image={company.image || fallbackCompanyImage} uploadType={uploadTypes.COMPANY_IMAGE} uploadedCallback={updateCompanyProperty('image')} />
      </div>
      <div className="column">
        <div className="double-element-wrapper">
          <div className="element">
            <Input placeholder={translations['settings.company-data.name']} value={company.name} onChange={updateCompanyProperty(['name'])} />
          </div>
          <div className="element">
            <Dropdown
              name={translations['settings.company-data.corporate-form']}
              value={company.corporateForm}
              onChange={updateCompanyProperty('corporateForm')}
              options={settings
                .getGroup('company.corporate-form.options')
                .sort((a, b) => {
                  if (a.value.toLowerCase() < b.value.toLowerCase()) return -1
                  if (a.value.toLowerCase() > b.value.toLowerCase()) return 1
                  return 0
                })
                .map((option) => ({
                  key: option.key,
                  value: translations[option.translationKey],
                }))}
            />
          </div>
        </div>
        <div className="address-street-element-wrapper">
          <div className="element">
            <Input placeholder={translations['settings.company-data.street']} value={company.street} onChange={updateCompanyProperty(['street'])} />
          </div>
          <div className="element">
            <Input placeholder={translations['settings.company-data.street-number']} value={company.streetNumber} onChange={updateCompanyProperty(['streetNumber'])} />
          </div>
          <div className="element">
            <Input placeholder={translations['settings.company-data.street-addition']} value={company.streetAddition} onChange={updateCompanyProperty(['streetAddition'])} />
          </div>
        </div>
        <div className="element-wrapper">
          <Input placeholder={translations['settings.company-data.phone']} value={company.phone} onChange={updateCompanyProperty('phone')} />
        </div>
        <div className="element-wrapper">
          <Input placeholder={translations['settings.company-data.mail']} value={company.mail} onChange={updateCompanyProperty('mail')} />
        </div>
      </div>
      <div className="column">
        <div className="double-element-wrapper">
          <div className="element">
            <Input placeholder={translations['settings.company-data.court']} value={company.court} onChange={updateCompanyProperty(['court'])} />
          </div>
          <div className="element">
            <Input placeholder={translations['settings.company-data.registration-number']} value={company.registrationNumber} onChange={updateCompanyProperty(['registrationNumber'])} />
          </div>
        </div>
        <div className="double-element-wrapper">
          <div className="element">
            <Input placeholder={translations['settings.company-data.postcode']} value={company.postcode} onChange={updateCompanyProperty(['postcode'])} />
          </div>
          <div className="element">
            <Input placeholder={translations['settings.company-data.city']} value={company.city} onChange={updateCompanyProperty(['city'])} />
          </div>
        </div>
        <div className="element-wrapper">
          <Input placeholder={translations['settings.company-data.purchase-mail']} value={company.purchaseMail} onChange={updateCompanyProperty('purchaseMail')} />
        </div>
        <div className="element-wrapper">
          <Input placeholder={translations['settings.company-data.website']} value={company.website} onChange={updateCompanyProperty('website')} />
        </div>
      </div>
      <div className="column">
        <div className="responsibilities">
          <div className="title">{translations['settings.company-data.intention.title']}</div>
          <div className="options">
            {settings.getGroup('company.intention.options').map((option) => (
              <Checkbox key={getUUID()} checked={company.intentions.includes(option.key)} onChange={updateIntentions(option.key)} text={translations[option.translationKey]} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyForm
