const conditions = () => {
  return [
    { assetClass: { $ne: '' } },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.wohnen': { showLift: true, showBasement: true },
          'object.asset-class.options.buero': { showLift: true, showBasement: true },
          'object.asset-class.options.handel-retail': { showLift: true, showBasement: true },
          'object.asset-class.options.hotel': { showLift: false, showBasement: true },
          'object.asset-class.options.logistik-industrie': { showLift: false, showBasement: true },
          'object.asset-class.options.healthcare-medical': { showLift: false, showBasement: true },
          'object.asset-class.options.micro-und-serviced-living': { showLift: false, showBasement: true },
        },
      },
    },
  ]
}

export default conditions
