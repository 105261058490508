import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const HotelAdditional = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    // dispatch(setNewObject({ ...newObject, hotelAdditional: { ...newObject.hotelAdditional, [key]: value } }))
    dispatch(setLocal(props.OBJECT, { ...newObject, hotelAdditional: { ...newObject.hotelAdditional, [key]: value } }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.hotelAdditional.number']}</div>
        <div className="values">
          <Input type="number" value={newObject.hotelAdditional.roomOccupancy} onChange={update('roomOccupancy')} placeholder={translations['object.hoteladditional.roomoccupancy']} />
          <Input type="number" value={newObject.hotelAdditional.bedOccupancy} onChange={update('bedOccupancy')} placeholder={translations['object.hoteladditional.bedoccupancy']} />
          <Input type="number" value={newObject.hotelAdditional.averageRoomRate} onChange={update('averageRoomRate')} placeholder={translations['object.hoteladditional.averageroomrate']} />
          <Input type="number" value={newObject.hotelAdditional.revenueHotelGuests} onChange={update('revenueHotelGuests')} placeholder={translations['object.hoteladditional.revenuehotelguests']} />
          <Input
            type="number"
            value={newObject.hotelAdditional.revenueExternalGuests}
            onChange={update('revenueExternalGuests')}
            placeholder={translations['object.hoteladditional.revenueexternalguests']}
          />
          <Input type="number" value={newObject.hotelAdditional.costsOfSalesRatio} onChange={update('costsOfSalesRatio')} placeholder={translations['object.hoteladditional.costsofsalesratio']} />
          <Input
            type="number"
            value={newObject.hotelAdditional.personalCostsPerFullTimeEmployee}
            onChange={update('personalCostsPerFullTimeEmployee')}
            placeholder={translations['object.hoteladditional.personalcostsperfulltimeemployee']}
          />
          <Input
            type="number"
            value={newObject.hotelAdditional.productivityPerFullTimeEmployee}
            onChange={update('productivityPerFullTimeEmployee')}
            placeholder={translations['object.hoteladditional.productivityperfulltimeemployee']}
          />
          <Input type="number" value={newObject.hotelAdditional.gastronomy} onChange={update('gastronomy')} placeholder={translations['object.hoteladditional.gastronomy']} />
        </div>
      </div>
    </div>
  )
}

export default HotelAdditional
