import TabView from '../../UI/TabView/TabView'
import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import { useSelector } from 'react-redux'
import Chat from '../Chat/Chat'
import './ObjectDetail.scss'
import { useParams } from 'react-router-dom'
import Storage from '../Storage/Storage'
import QAndA from '../QAndA/QAndA'

const ObjectDetail = () => {
  const translations = useSelector((state) => state.translations)
  const { uuid: objectUUID } = useParams()

  const tabItems = [
    {
      name: translations['object-detail.tabs.object-profile'],
      component: <div>XXX object profile</div>,
    },
    {
      name: translations['object-detail.tabs.storage'],
      component: <Storage objectUUID={objectUUID} />,
    },
    {
      name: translations['object-detail.tabs.q-and-a'],
      component: <QAndA objectUUID={objectUUID} />,
    },
    {
      name: translations['object-detail.tabs.chat'],
      component: <Chat chatOnly={true} objectUUID={objectUUID} />,
    },
  ]

  return (
    <DashboardLayout card={true}>
      <div id="object-detail">
        <CardHeader headline={'XXX Object name'} hint={'XXX Location'} />
        <CardContent>
          <TabView items={tabItems} />
        </CardContent>
      </div>
    </DashboardLayout>
  )
}

export default ObjectDetail
