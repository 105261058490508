import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const ConstructionYear = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...purchaseProfile, constructionYear: { ...purchaseProfile.constructionYear, [key]: value } }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-year.from']}</div>
        <div className="values">
          <Input type="number" value={purchaseProfile.constructionYear.from} onChange={update('from')} placeholder={translations['my-objects.pipeline.location-criteria.construction-year.from']} />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-year.until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              value={purchaseProfile.constructionYear.until}
              onChange={update('until')}
              placeholder={translations['my-objects.pipeline.location-criteria.construction-year.until']}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConstructionYear
