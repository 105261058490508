import { useDispatch, useSelector } from 'react-redux'
// import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'
import { useEffect, useState } from 'react'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const ObjectType = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const [selections, setSelections] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    const localSelections = settings.getGroup(payload)

    const selected = localSelections.filter((s) => newObject.objectType.includes(s.key)).map((s) => s.key)

    // dispatch(setNewObject({ ...newObject, objectType: selected }))
    dispatch(setLocal(props.OBJECT, { ...newObject, objectType: selected }))

    setSelections(localSelections)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  const update = (newArr) => {
    // dispatch(setNewObject({ ...newObject, objectType: newArr }))
    dispatch(setLocal(props.OBJECT, { ...newObject, objectType: newArr }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.object-type']}</div>
        <div className="values">
          <RadioGroup options={selections.map((option) => ({ value: translations[option.key], key: option.key }))} value={newObject?.objectType} onChange={update} itemsPerRow={2} />
        </div>
      </div>
    </div>
  )
}

export default ObjectType
