const conditions = () => {
  return [
    { assetClass: { $ne: '' } },
    { projectType: { $ne: '' } },
    { assetClass: { $ne: 'object.asset-class.options.micro-und-serviced-living' } },
    { assetClass: { $ne: 'object.asset-class.options.hotel' } },
  ]
}

export default conditions
