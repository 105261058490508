import './FullScreenHeader.scss'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Link from '../../../UI/Link/Link'

const FullScreenHeader = ({ headline }) => {
    if (!headline) return ''
    return <div id="dashboard-layout-fullscreen-header">
        <div className="headline">{headline}</div>
        <div className="controls">
            <div className="control"><Link href='/dashboard/chat'><div className="icon-wrapper"><ChatOutlinedIcon /></div></Link></div>
            <div className="control"><div className="icon-wrapper"><NotificationsNoneOutlinedIcon /></div></div>
            <div className="control"><div className="icon-wrapper"><SettingsOutlinedIcon /></div></div>
        </div>
    </div>
}

export default FullScreenHeader