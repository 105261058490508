import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import fetch, { METHOD } from '../../../fetch'
import { setLocal } from '../../../redux/action/local'
import properties from '../../../redux/props'
import { get } from '../../../interface/crud'

const Secure = (props) => {
  const authRequired = props.authRequired || false
  const user = useSelector((state) => state.USER)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isEvaluated, setIsEvaluated] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    const token = cookies.get('token', { path: '/' })

    const evaluate = (user, newToken, account) => {
      dispatch(setLocal(properties.ACCOUNT, account))
      dispatch(setLocal(properties.USER, user))
      dispatch(setLocal(properties.TOKEN, newToken || token))
      setIsEvaluated(true)
    }

    const invalidatedUser = (withRedirect = false) => {
      cookies.remove('token', { path: '/' })
      dispatch(setLocal(properties.USER, null))
      dispatch(setLocal(properties.TOKEN, null))
      if (withRedirect) {
        setIsEvaluated(false)
        history.push('/login')
      } else {
        setIsEvaluated(true)
      }
    }

    const resolveToken = async () => {
      const { status, account, token: newToken } = await fetch(METHOD.POST, '/auth/resolve-token', { token })

      const user = await get(newToken, 'USER', { uuid: account.assignedToUUID })

      if (status && account && user) {
        if (authRequired === 'admin') {
          if (user.isAdmin === true) {
            evaluate(user, newToken, account)
          } else {
            invalidatedUser(true)
          }
        } else {
          evaluate(user, newToken, account)
        }
      } else {
        invalidatedUser(!!authRequired)
      }
    }

    if (!isEvaluated) {
      if (!user && token) {
        resolveToken()
      } else if (authRequired && !user) {
        invalidatedUser(true)
      } else {
        setIsEvaluated(true)
      }
    }
  }, [isEvaluated, authRequired, dispatch, history, user])

  return isEvaluated ? <>{props.children}</> : <></>
}

export default Secure
