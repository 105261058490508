const conditions = () => {
  return [
    { projectType: { $ne: '' } },
    { multiTenant: true },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.handel-retail': 'object.anchor-tenant.commerce-and-retail.options',
        },
      },
    },
  ]
}

export default conditions
