import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './Settings.scss'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import PersonalForm from './PersonalForm/PersonalForm'
import CompanyForm from './CompanyForm/CompanyForm'
import Button from '../../UI/Button/Button'
import { setUser } from '../../../action/user'
import CardFooter from '../../Layout/Dashboard/CardFooter/CardFooter'
import { get, update } from '../../../interface/crud'
import crudProps from '../../../redux/props'

const Settings = (props) => {
  const translations = useSelector((state) => state.translations)
  const user = useSelector((state) => state.USER)
  const token = useSelector((state) => state.TOKEN)
  const dispatch = useDispatch()
  const [success, setSuccess] = useState(null)
  const [profile, setProfile] = useState(null)
  const account = useSelector((state) => state.ACCOUNT)

  useEffect(() => {
    const fetchProfile = async () => {
      const user = await get(token, 'USER', { uuid: account.assignedToUUID })
      const company = await get(token, 'COMPANY', { userUUID: account.assignedToUUID })

      // const { status, profile } = await fetch('/profile/get', { token })
      if (user) {
        setProfile({ ...user, company })
      }
    }
    if (!profile && token && account) {
      fetchProfile()
    }
  }, [token, profile, account])

  const isFirstLogin = () => props.location.search.includes('firstlogin=true')

  const updateProperty = (property) => (value) => {
    setProfile({ ...profile, [property]: value })
  }

  const updateCompanyProperty = (property) => (value) => {
    setProfile({
      ...profile,
      company: { ...profile.company, [property]: value },
    })
  }

  const submit = async () => {
    setSuccess(null)
    // const { status } = await fetch('/profile/update', { token, profile })
    let status = await update(token, crudProps.USER, { uuid: account.assignedToUUID, ...profile })
    if (!status) return
    status = await update(token, crudProps.COMPANY, { uuid: profile.company.uuid, ...profile.company })
    if (status) {
      dispatch(
        setUser({
          ...user,
          firstname: profile.firstname,
          lastname: profile.lastname,
          profileImage: profile.profileImage,
        }),
      )
    }
    setSuccess(status)
  }

  return (
    <DashboardLayout card={true}>
      {profile && (
        <div id="settings">
          <CardHeader headline={`${translations['settings.header.headline.prefix']}${user.firstname} ${user.lastname}!`} hint={isFirstLogin() ? translations['settings.header.hint'] : ''} />
          <CardContent>
            <PersonalForm profile={profile} updateProperty={updateProperty} />
            <CompanyForm company={profile.company} updateCompanyProperty={updateCompanyProperty} />
          </CardContent>
          <CardFooter>
            <div className="footerContent">
              <Button fullWidth={false} onClick={submit} text={translations['settings.save-cta']} />
              {success === true && <div>{translations['settings.save.success']}</div>}
              {success === false && <div>{translations['settings.save.error']}</div>}
            </div>
          </CardFooter>
        </div>
      )}
    </DashboardLayout>
  )
}

export default Settings
