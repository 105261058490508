import { useSelector } from 'react-redux'
import Upload from '../../../../../UI/Upload/Upload'

const PhotoUpload = ({ addPhotos }) => {
  const translations = useSelector((state) => state.translations)

  return (
    <div>
      <Upload title={translations['object.photos.label']} uploadType={'OBJECT_IMAGE'} uploadedCallback={addPhotos} isPublicUpload={true} accept={'image/*'} files={[]} />
    </div>
  )
}

export default PhotoUpload
