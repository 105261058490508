import DashboardLayout from '../../../Layout/Dashboard/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import './Pipeline.scss'
import AssetClass from './AssetClass/AssetClass'
import LocationCriteria from './LocationCriteria/LocationCriteria'
import YieldAndObjectCriteria from './YieldAndObjectCriteria/YieldAndObjectCriteria'
import { getUUID } from '../../../../utility'
import AdjustIcon from '@mui/icons-material/Adjust'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ExtendedCriteria from './ExtendedCriteria/ExtendedCriteria'
import Button from '../../../UI/Button/Button'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Storage from './Storage/Storage'
import { setLocal } from '../../../../redux/action/local'
import props from '../../../../redux/props'
import defaultState from '../../../../redux/defaultState'
import { get, upsert } from '../../../../interface/crud'

const Navigation = ({ bulletpoints }) => {
  const purchaseProfileNavigationIndex = useSelector((state) => state.PURCHASE_PROFILE_NAVIGATION_INDEX)

  return (
    <div className="purchase-profile-bulletpoint-navigation">
      <div className="timeline">
        <div className="box">
          <div className="lines">
            {bulletpoints.map((b, i) => (
              <div key={getUUID()}>
                <div className={`dot${purchaseProfileNavigationIndex >= i ? ' active' : ''}`}>{i === purchaseProfileNavigationIndex ? <AdjustIcon /> : <FiberManualRecordIcon />}</div>
                {i < bulletpoints.length - 1 ? <div className={`line${purchaseProfileNavigationIndex >= i ? ' active' : ''}`}></div> : ''}
              </div>
            ))}
          </div>
          <div className="items">
            {bulletpoints.map((b, i) => (
              <div className={`item${purchaseProfileNavigationIndex >= i ? ' active' : ''}`} key={getUUID()}>
                {b.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const NavigationButtons = ({ index, bulletpoints, creationMode }) => {
  const purchaseProfileNavigationIndex = useSelector((state) => state.PURCHASE_PROFILE_NAVIGATION_INDEX)
  const purchaseProfile = useSelector((state) => state[props.PURCHASE_PROFILE])
  const translations = useSelector((state) => state.translations)
  const account = useSelector((state) => state.ACCOUNT)
  const token = useSelector((state) => state.TOKEN)
  const dispatch = useDispatch()
  const history = useHistory()

  const complete = async () => {
    // const endpoint = creationMode ? '/purchase-profile/create' : '/purchase-profile/update'
    // console.log(endpoint)
    const result = await upsert(token, props.PURCHASE_PROFILE, { ...purchaseProfile, userUUID: account.assignedToUUID }, ['uuid'])
    if (result) {
      dispatch(setLocal(props.PURCHASE_PROFILE, defaultState.PURCHASE_PROFILE))
      history.push('/dashboard/purchase-profile')
    }
  }

  const next = () => dispatch(setLocal(props.PURCHASE_PROFILE_NAVIGATION_INDEX, purchaseProfileNavigationIndex + 1))

  const previous = () => dispatch(setLocal(props.PURCHASE_PROFILE_NAVIGATION_INDEX, purchaseProfileNavigationIndex - 1))

  const isLastItem = index === bulletpoints.length - 1
  const isFirstItem = index === 0

  return (
    <div className="purchase-profile-pipeline-component-navigation">
      {!isFirstItem && <Button onClick={previous} text={translations['my-objects.pipeline.component.navigation.back']} fullWidth={false} />}
      {!isLastItem && <Button onClick={next} text={translations['my-objects.pipeline.component.navigation.next']} fullWidth={false} />}
      {isLastItem && <Button onClick={complete} text={translations['my-objects.pipeline.component.navigation.complete']} fullWidth={false} />}
    </div>
  )
}

const PurchaseProfilePipeline = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfileNavigationIndex = useSelector((state) => state.PURCHASE_PROFILE_NAVIGATION_INDEX)
  const token = useSelector((state) => state.TOKEN)
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const pullRawProfile = async (uuid) => {
      console.log('Pull', uuid)
      const profile = await get(token, props.PURCHASE_PROFILE, { uuid })
      console.log(profile)
      if (profile) {
        dispatch(setLocal(props.PURCHASE_PROFILE, profile))
        setLoading(false)
      }
    }

    if (params.uuid) {
      if (token) {
        pullRawProfile(params.uuid)
      }
    } else {
      setLoading(false)
    }
    return () => dispatch(setLocal(props.PURCHASE_PROFILE, defaultState.PURCHASE_PROFILE))
  }, [params, dispatch, token])

  const bulletpoints = [
    { title: translations['my-objects.pipeline.navigation.asset-class.label'], component: AssetClass },
    { title: translations['my-objects.pipeline.navigation.location-criteria.label'], component: LocationCriteria },
    { title: translations['my-objects.pipeline.navigation.yield-and-object-criteria.label'], component: YieldAndObjectCriteria },
    { title: translations['my-objects.pipeline.navigation.extendet-criteria.label'], component: ExtendedCriteria },
    { title: translations['my-objects.pipeline.navigation.storage.label'], component: Storage },
  ]

  return (
    <DashboardLayout card={true} overwriteNavigationComponent={<Navigation bulletpoints={bulletpoints} />}>
      {!loading && (
        <div className="purchase-profile-pipeline">
          {bulletpoints.map((b, i) =>
            purchaseProfileNavigationIndex === i ? <b.component key={getUUID()} NavigationButtons={<NavigationButtons index={i} bulletpoints={bulletpoints} creationMode={!params.uuid} />} /> : '',
          )}
        </div>
      )}
    </DashboardLayout>
  )
}

export default PurchaseProfilePipeline
