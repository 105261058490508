import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import { useState } from 'react'

const Entry = ({ entry }) => {
  const [showAnswer, setShowAnswer] = useState(false)

  const change = () => setShowAnswer(!showAnswer)

  return (
    <div className="entry">
      <div className="question">
        {showAnswer ? <IndeterminateCheckBoxIcon className="iconShow" onClick={change} /> : <LocalHospitalIcon className="iconShow" onClick={change} />}
        <div className="questionText">{entry.question}</div>
      </div>
      {showAnswer && <div className="answer">{entry.answer}</div>}
    </div>
  )
}

export default Entry
