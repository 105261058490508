import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { list } from '../../../../interface/crud'
import props from '../../../../redux/props'
import ObjectCardItem from '../../ObjectCardItem/ObjectCardItem'
import './Active.scss'

const Active = () => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [objects, setObjects] = useState(null)
  const [error, setError] = useState(false)
  const history = useHistory()
  const account = useSelector((state) => state.ACCOUNT)

  useEffect(() => {
    const fetchObjects = async () => {
      const objects = await list(token, props.OBJECT, { userUUID: account.assignedToUUID, active: true })
      if (objects) {
        setObjects(objects)
      } else {
        setError(true)
      }
    }
    if (token && !objects && account) {
      fetchObjects()
    }
  })

  const navigate = (uuid) => () => {
    history.push('/dashboard/new-objects/pipeline/' + uuid)
  }

  return (
    <div className="my-objects-active">
      {Array.isArray(objects) && objects.length === 0 ? <div className="message">{translations['new-object.suggestions.no-results']}</div> : ''}
      {Array.isArray(objects) &&
        objects.length > 0 &&
        objects.map((object) => (
          <ObjectCardItem object={object} onObjectClick={navigate(object.uuid)} />
          // <div key={getUUID()} className="cardItem" onClick={navigate(object.uuid)}>
          //   <div>{object.location.street + ' ' + object.location.houseNumber}</div>
          //   <div>{object.location.city}</div>
          //   <div>{object.location.abcd}</div>
          // </div>
        ))}
      {error && <div className="message">{translations['my-objects.active.error']}</div>}
    </div>
  )
}

export default Active
