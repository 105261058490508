const conditions = () => {
  return [
    { projectType: { $ne: '' } },
    {
      assetClass: {
        $in: [
          'object.asset-class.options.handel-retail',
          'object.asset-class.options.healthcare-medical',
          'object.asset-class.options.buero',
          'object.asset-class.options.logistik-industrie',
          'object.asset-class.options.freies-ankaufsprofil',
        ],
      },
    },
  ]
}

export default conditions
