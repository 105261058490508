import Layout from '../Layout'
import './Admin.scss'
import logo from '../../../assets/image/logo.png'
import { fallbackProfilImage, projectName } from '../../../utility'
import { useDispatch, useSelector } from 'react-redux'
import ImageWrapper from '../../ImageWrapper/ImageWrapper'
import Link from '../../UI/Link/Link'
import Cookies from 'universal-cookie'
import { setUser, setUserToken } from '../../../action/user'
import { useHistory } from 'react-router-dom'

const Admin = (props) => {
  const translations = useSelector((state) => state.translations)
  const user = useSelector((state) => state.USER)
  const { overwriteNavigationComponent } = props
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const history = useHistory()

  const logout = (e) => {
    e.preventDefault()
    cookies.remove('token', { path: '/' })
    dispatch(setUser(null))
    dispatch(setUserToken(null))
    history.push('/')
  }

  return (
    <Layout authRequired={'admin'} header={false} fullsize={true}>
      <div id="admin-layout">
        <div id="sidebar">
          <div className="logo">
            <img src={logo} alt={projectName} />
          </div>
          <div className="logo-label">{translations['layout-admin.logo-label']}</div>
          {!overwriteNavigationComponent && (
            <div className="navigation-wrapper">
              <div className="navigation">
                <div className="title">{translations['layout-admin.navigation.user.title']}</div>
                <div className="item">
                  <Link href="/admin/users" text={translations['layout-admin.navigation.user.list']} />
                </div>
                <div className="item">
                  <Link href="/admin/user-add" text={translations['layout-admin.navigation.user.add']} />
                </div>
              </div>
              <div className="navigation">
                <div className="title">{translations['layout-admin.navigation.company.title']}</div>
              </div>
              <div className="navigation">
                <div className="title">{translations['layout-admin.navigation.buying-profile.title']}</div>
              </div>
              <div className="navigation">
                <div className="title">{translations['layout-admin.navigation.object.title']}</div>
              </div>
              <div className="navigation">
                <div className="title">{translations['layout-admin.navigation.settings.title']}</div>
                <div className="item">
                  <Link href="/admin/settings" text={translations['layout-admin.navigation.settings.global']} />
                </div>
                <div className="item">
                  <Link href="/admin/translations" text={translations['layout-admin.navigation.settings.translation']} />
                </div>
                <div className="item">
                  <Link href="/admin/geo-data" text={translations['layout-admin.navigation.settings.geo-data']} />
                </div>
              </div>
              <div className="profile">
                <div className="image">
                  <ImageWrapper image={user && user.profileImage ? user.profileImage : fallbackProfilImage} round={true} />
                </div>
                <div className="name-logout">
                  <div className="name">
                    <Link href="/dashboard/settings" text={user && `${user.firstname} ${user.lastname}`} />
                  </div>
                  <div className="logout-wrapper">
                    <div className="logout" onClick={logout}>
                      {translations['layout-dashboard.logout']}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {overwriteNavigationComponent && overwriteNavigationComponent}
        </div>
        <div id="admin-content-wrapper" className={`${props.card ? 'card' : ''}`}>
          <div id="admin-content">{props.children}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Admin
