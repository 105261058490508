import { Switch, Route } from 'react-router-dom'
import Settings from './components/Dashboard/Settings/Settings'
import NewObjects from './components/Dashboard/NewObjects/NewObjects'
import NewObjectsPipeline from './components/Dashboard/NewObjects/Pipeline/Pipeline'
import MyObjects from './components/Dashboard/MyObjects/MyObjects'
import PurchaseProfile from './components/Dashboard/PurchaseProfile/PurchaseProfile'
import PurchaseProfilePipeline from './components/Dashboard/PurchaseProfile/Pipeline/Pipeline'
import Chat from './components/Dashboard/Chat/Chat'
import ObjectDetail from './components/Dashboard/ObjectDetail/ObjectDetail'

function DashboardRouting({ match }) {
  return (
    <Switch>
      {/* SETTINGS */}
      <Route exact={true} path={`${match.path}/settings`} component={Settings} />
      {/* NEW OBJECTS */}
      <Route exact={true} path={`${match.path}/new-objects`} component={NewObjects} />
      {/* CREATE NEW OBJECT */}
      <Route exact={true} path={`${match.path}/new-objects/pipeline`} component={NewObjectsPipeline} />
      <Route exact={true} path={`${match.path}/new-objects/pipeline/:uuid`} component={NewObjectsPipeline} />
      {/* PURCHASE PROFILE OVERVIEW */}
      <Route exact={true} path={`${match.path}/purchase-profile`} component={PurchaseProfile} />
      {/* CREATE NEW PURCHASE PROFILE */}
      <Route exact={true} path={`${match.path}/purchase-profile/pipeline`} component={PurchaseProfilePipeline} />
      {/* EDIT PURCHASE PROFILE */}
      <Route exact={true} path={`${match.path}/purchase-profile/pipeline/:uuid`} component={PurchaseProfilePipeline} />
      {/* MY OBJECTS */}
      <Route exact={true} path={`${match.path}/my-objects`} component={MyObjects} />
      {/* OBJECT DETAIL */}
      <Route exact={true} path={`${match.path}/object-detail/:uuid`} component={ObjectDetail} />
      {/* CHAT */}
      <Route exact={true} path={`${match.path}/chat`} component={Chat} />
    </Switch>
  )
}

export default DashboardRouting
