import { useSelector } from 'react-redux'
import './AssetClass.scss'
import CardHeader from '../../../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../../../Layout/Dashboard/CardContent/CardContent'
import AssetClass from './SubComponents/AssetClass'
import ProjectType from './SubComponents/ProjectType'
import TypeOfFinancing from './SubComponents/TypeOfFinancing'
import MezzanineLoan from './SubComponents/MezzanineLoan'
import SeniorLoan from './SubComponents/SeniorLoan'
import WholeLoan from './SubComponents/WholeLoan'
import InvestmentVolume from './SubComponents/InvestmentVolume'
import PortfolioInvestmentVolume from './SubComponents/PortfolioInvestmentVolume'
import TotalInvestment from './SubComponents/TotalInvestment'
import PlotPrice from './SubComponents/PlotPrice'
import ObjectType from './SubComponents/ObjectType'
import DealCriteria from './SubComponents/DealCriteria'
import Condition from '../Condition/Condition'
import CardFooter from '../../../../Layout/Dashboard/CardFooter/CardFooter'

const PurchaseProfilePipelineAssetClass = ({ NavigationButtons }) => {
  const translations = useSelector((state) => state.translations)

  return (
    <div className="purchase-profile-pipeline-asset-class">
      <CardHeader headline={translations['my-objects.pipeline.assets-class.headline']} />
      <CardContent>
        <div className="section">
          <div className="title">{translations['my-objects.pipeline.assets-class']}</div>
          <div className="input-groups">
            <Condition defaultStateName="assetClass" Component={AssetClass} />
            <Condition defaultStateName="projectType" Component={ProjectType} widthInPercent={66} />
            <Condition defaultStateName="typeOfFinancing" Component={TypeOfFinancing} />

            <Condition defaultStateName="mezzanineLoan" Component={MezzanineLoan} />
            <Condition defaultStateName="seniorLoan" Component={SeniorLoan} />
            <Condition defaultStateName="wholeLoan" Component={WholeLoan} />

            <Condition defaultStateName="investmentVolume" Component={InvestmentVolume} />
            <Condition defaultStateName="portfolioInvestmentVolume" Component={PortfolioInvestmentVolume} />
            <Condition defaultStateName="totalInvestment" Component={TotalInvestment} />
            <Condition defaultStateName="plotPrice" Component={PlotPrice} />
            <Condition defaultStateName="objectType" Component={ObjectType} widthInPercent={66} />
            <Condition defaultStateName="dealCriteria" Component={DealCriteria} widthInPercent={100} />
          </div>
        </div>
      </CardContent>
      <CardFooter>{NavigationButtons}</CardFooter>
    </div>
  )
}

export default PurchaseProfilePipelineAssetClass
