import Container from "react-bootstrap/Container";
import "./Footer.scss";
import { useSelector } from 'react-redux'
import logo from "../../../assets/image/logo.png";
import Link from '../../UI/Link/Link'
import { projectName } from "../../../utility";

const Footer = () => {

  const translations = useSelector(state => state.translations)

  return (
    <div id="footer">
      <div className="copyright">
        <Container>
          <div className="logo"><img src={logo} alt={projectName} /></div>
          <div>Copyright &copy; MatchMyEstate {new Date().getFullYear()}</div>
          <div><Link href="/impressum" text={translations['imprint']} /></div>
          <div><Link href="/datenschutz" text={translations['data-protection']} /></div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
