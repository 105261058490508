import { useSelector } from "react-redux";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import Dropdown from "../../../UI/Dropdown/Dropdown";
import Input from "../../../UI/Input/Input";
import ImageWrapper from "../../../ImageWrapper/ImageWrapper";
import { fallbackProfilImage, getUUID } from "../../../../utility";
import { uploadTypes } from "../../../../fetch";
import TwoFA from './TwoFA/TwoFA'

const PersonalForm = ({ profile, updateProperty }) => {
  const translations = useSelector(state => state.translations)
  const settings = useSelector((state) => state.settings);

  const updateResponsibilities = (key) => (checked) => {
    const update = updateProperty("responsibilities");
    if (checked) {
      update([...profile.responsibilities, key]);
    } else {
      update([...profile.responsibilities.filter((r) => r !== key)]);
    }
  };

  return (
    <div className="section">
      <div className="headline">
        {translations["settings.personal-data.headline"]}
      </div>
      <div className="column profile-image">
        <ImageWrapper
          image={profile.profileImage || fallbackProfilImage}
          round={true}
          uploadType={uploadTypes.PROFILE_IMAGE}
          uploadedCallback={updateProperty("profileImage")}
        />
      </div>
      <div className="column">
        <div className="double-element-wrapper">
          <div className="element">
            <Dropdown
              name={translations["settings.personal-data.salutation"]}
              value={profile.salutation}
              onChange={updateProperty("salutation")}
              options={settings
                .getGroup("person.salutation.options")
                .map((option) => ({
                  key: option.key,
                  value: translations[option.translationKey],
                }))}
            />
          </div>
          <div className="element">
            <Dropdown
              name={translations["settings.personal-data.title"]}
              value={profile.title}
              onChange={updateProperty("title")}
              options={settings
                .getGroup("person.title.options")
                .map((option) => ({
                  key: option.key,
                  value: translations[option.translationKey],
                }))}
            />
          </div>
        </div>
        <div className="element-wrapper">
          <Input
            placeholder={translations["settings.personal-data.phone"]}
            value={profile.phone}
            onChange={updateProperty("phone")}
          />
        </div>
        <div className="element-wrapper">
          <Input
            type="email"
            placeholder={translations["settings.personal-data.mail"]}
            value={profile.mail}
            onChange={updateProperty("mail")}
          />
        </div>
        <div className="2fa">
          <TwoFA />
        </div>
      </div>
      <div className="column">
        <div className="double-element-wrapper">
          <div className="element">
            <Input
              placeholder={translations["settings.personal-data.firstname"]}
              value={profile.firstname}
              onChange={updateProperty("firstname")}
            />
          </div>
          <div className="element">
            <Input
              placeholder={translations["settings.personal-data.lastname"]}
              value={profile.lastname}
              onChange={updateProperty("lastname")}
            />
          </div>
        </div>
        <div className="element-wrapper">
          <Input
            placeholder={translations["settings.personal-data.mobile-phone"]}
            value={profile.mobilePhone}
            onChange={updateProperty("mobilePhone")}
          />
        </div>
        <div className="double-element-wrapper">
          <div className="element">
            <Input
              placeholder={translations["settings.personal-data.position"]}
              value={profile.position}
              onChange={updateProperty("position")}
            />
          </div>
          <div className="element">
            <Input
              type="date"
              placeholder={translations["settings.personal-data.birthday"]}
              value={profile.birthday}
              onChange={updateProperty("birthday")}
            />
          </div>
        </div>
      </div>
      <div className="column">
        <div className="responsibilities">
          <div className="title">
            {translations["settings.personal-data.responsibilities.title"]}
          </div>
          <div className="options">
            {settings
              .getGroup("person.responsibility.options")
              .map((option) => (
                <Checkbox
                  key={getUUID()}
                  checked={profile.responsibilities.includes(option.key)}
                  onChange={updateResponsibilities(option.key)}
                  text={translations[option.translationKey]}
                />
              ))}
            <Input
              variant="standard"
              placeholder={
                translations["settings.personal-data.responsibility.other"]
              }
              value={profile.responsibilitiesOther}
              onChange={updateProperty("responsibilitiesOther")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalForm;
