import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { getUUID } from '../../../../../../utility'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const Hotel = ({ payload }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()
  const starOptions = ['1', '2', '3', '4', '5']

  const update = (key, option) => (isChecked) => {
    console.log('within update')
    const newKeySelection = isChecked ? [...purchaseProfile.hotel[key], option] : purchaseProfile.hotel[key].filter((o) => o !== option)
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, hotel: { ...purchaseProfile.hotel, [key]: newKeySelection } }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, hotel: { ...purchaseProfile.hotel, [key]: newKeySelection } }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.hotel.stars']}</div>
        <div className="values">
          {starOptions.map((option) => {
            return <Checkbox key={getUUID()} checked={purchaseProfile.hotel.stars.includes(option)} onChange={update('stars', option)} text={option} />
          })}
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.hotel.characteristics']}</div>
        <div className="values">
          {settings.getGroup(payload).map((option) => (
            <Checkbox
              key={getUUID()}
              checked={purchaseProfile.hotel.characteristics.includes(option.key)}
              onChange={update('characteristics', option.key)}
              text={translations[option.translationKey]}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Hotel
