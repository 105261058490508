import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const Financing = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...newObject, financing: { ...newObject.financing, [key]: value } }
    // dispatch(setNewObject(updatePurchaseProfile))
    dispatch(setLocal(props.OBJECT, updatePurchaseProfile))
  }

  if (!settings || !newObject || !translations) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.financing.label']}</div>
          <div className="values">
            <Input value={newObject.financing.lender} onChange={update('lender')} placeholder={translations['object.financing.lender']} />
            <Input value={newObject.financing.interest} onChange={update('interest')} placeholder={translations['object.financing.interest']} />
            <Input value={newObject.financing.repayment} onChange={update('repayment')} placeholder={translations['object.financing.repayment']} />
            <Input value={newObject.financing.annuity} onChange={update('annuity')} placeholder={translations['object.financing.annuity']} />
            <Input value={newObject.financing.runningTime} onChange={update('runningTime')} placeholder={translations['object.financing.runningTime']} />
            <Input value={newObject.financing.remainingTerm} onChange={update('remainingTerm')} placeholder={translations['object.financing.remainingTerm']} />
            <Input value={newObject.financing.specialRepayment} onChange={update('specialRepayment')} placeholder={translations['object.financing.specialRepayment']} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Financing
