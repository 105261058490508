import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import Button from '../../../../../UI/Button/Button'
import { setLocationCity } from '../../../../../../action/purchaseProfile'
import LocationSuggestion from './LocationSuggestion'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import './LocationCard.scss'
import { buildLabel } from './buildLabel'

const State = () => {
  const translations = useSelector((state) => state.translations)

  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const [selectedCity, setSelectedCity] = useState(null)
  const [minPopulation, setMinPopulation] = useState('')
  const [radius, setRadius] = useState('')

  const onClickAdd = () => {
    dispatch(setLocationCity([...purchaseProfile.locationCity, { uuid: selectedCity.key, radius, minPopulation, payload: selectedCity.payload, attributes: selectedCity.attributes }]))
    setSelectedCity(null)
    setMinPopulation('')
    setRadius('')
  }

  const onClickRemove = (uuid) => () => {
    dispatch(setLocationCity(purchaseProfile.locationCity.filter((el) => el.uuid !== uuid)))
  }

  return (
    <div className="locationCard">
      <div className="locationTitle">{translations['location.title.city']}</div>
      <div className="locationInputFullWidth">
        <LocationSuggestion fetchURI={'city'} onChange={setSelectedCity} name={translations['my-objects.pipeline.location-criteria.city']} />
      </div>
      <div className="locationSubTitle">{translations['location.more-criterions']}</div>
      <div className="locationInputNextTo">
        <Input type="customNumber" value={radius} onChange={(e) => setRadius(e)} placeholder={translations['my-objects.pipeline.location-criteria.radius']} symbol={translations['kilometer']} />
        <Input type="number" value={minPopulation} onChange={(e) => setMinPopulation(e)} placeholder={translations['my-objects.pipeline.location-criteria.min-population']} />
      </div>
      <Button disabled={selectedCity === null} onClick={onClickAdd} text={translations['add-cta']} fullWidth={false} />
      <div className="itemList">
        {Array.isArray(purchaseProfile.locationCity) &&
          purchaseProfile.locationCity.map((location) => (
            <div className="locationListItem" key={location.uuid}>
              <div className="value">{`${buildLabel(location)} ${location.minPopulation && `| ${location.minPopulation}`} `}</div>
              <CancelSharpIcon className="btnRemove" onClick={onClickRemove(location.uuid)} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default State
