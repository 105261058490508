import fetch from '../../../../fetch'
import Input from '../../../UI/Input/Input'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../UI/Button/Button'

const Create = ({ selectedLang }) => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [message, setMessage] = useState('')

  const create = async () => {
    setMessage(null)
    if (key.length > 0 && value.length > 0) {
      const { status } = await fetch('/admin/translations/upsert', { token, lang: selectedLang, key, value })
      if (status) {
        setMessage(translations['admin.translations.create-message.success'])
        setKey('')
        setValue('')
      } else {
        setMessage(translations['admin.translations.create-error.duplicate'])
      }
    } else {
      setMessage(translations['admin.translations.create-error.value-missing'])
    }
  }

  return (
    <div className="create-wrapper">
      <div className="property">
        <Input value={key} onChange={setKey} placeholder={translations['admin.translations.key']} />
      </div>
      <div className="property">
        <Input value={value} onChange={setValue} placeholder={translations['admin.translations.value']} />
      </div>
      <Button text={translations['admin.translations.create']} onClick={create} />
      {message && <div className="error">{message}</div>}
    </div>
  )
}

export default Create
