import './Message.scss'
import { fallbackProfilImage, formatFileSize, getPrettyTime, getUUID } from '../../../../../utility'
import ImageWrapper from '../../../../ImageWrapper/ImageWrapper'
import Link from '../../../../UI/Link/Link'
import { useSelector } from 'react-redux'

const Message = ({ message }) => {
  const user = useSelector((state) => state.USER)

  if (!user) return <></>
  return (
    <div className={`chat-room-message ${message.sender.uuid === user.uuid ? 'own' : 'partner'}`} key={getUUID()}>
      <div className="image-and-time">
        <div className="image">
          <ImageWrapper image={message.sender.profileImage || fallbackProfilImage} round={true} />
        </div>
        <div className="time">{getPrettyTime(new Date(message.createdOn))}</div>
      </div>
      <div className="content">
        <div className="text">{message.message}</div>
        {message.attachments && message.attachments.length > 0 && (
          <div className="attachments">
            {message.attachments.map((a) => (
              <Link key={getUUID()} href={a.url} newTab={true}>
                <div className="item">
                  <div className="name">{a.name}</div>
                  <div className="size">{formatFileSize(a.size)}</div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Message
