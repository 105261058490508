import { useDispatch, useSelector } from 'react-redux'
import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const HotelRating = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()
  const starOptions = ['1', '2', '3', '4', '5']

  const update = (newArr) => {
    // dispatch(setNewObject({ ...newObject, hotelRating: newArr }))
    dispatch(setLocal(props.OBJECT, { ...newObject, hotelRating: newArr }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.hotel.stars']}</div>
        <div className="values">
          <CheckboxGroup
            options={starOptions.map((option) => ({ value: option, label: option }))}
            values={newObject.hotelRating}
            onChange={update}
            name={translations['my-objects.pipeline.yield-and-object-criteria.hotel.stars']}
            itemsPerRow={1}
          />
        </div>
      </div>
    </div>
  )
}

export default HotelRating
