import { useSelector } from 'react-redux'
import './ExtendedCriteria.scss'
import CardHeader from '../../../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../../../Layout/Dashboard/CardContent/CardContent'
import Condition from '../Condition/Condition'
import FreeTags from './SubComponents/FreeTags'
import RequiredDocuments from './SubComponents/RequiredDocuments'
import Notes from './SubComponents/Notes'
import MoreTypesOfUse from './SubComponents/MoreTypesOfUse'
import CardFooter from '../../../../Layout/Dashboard/CardFooter/CardFooter'

const PurchaseProfilePipelineExtendedCriteria = ({ NavigationButtons }) => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)

  return (
    <div className="purchase-profile-pipeline-extended-criteria">
      <CardHeader headline={translations[purchaseProfile.assetClass]} />
      <CardContent>
        <div className="section">
          <div className="title">{translations['my-objects.pipeline.additional-criteria.object-criteria']}</div>
          <div className="input-groups">
            <Condition defaultStateName="requiredDocuments" Component={RequiredDocuments} widthInPercent={100} />
            <Condition defaultStateName="freeTags" Component={FreeTags} />
            <Condition defaultStateName="notes" Component={Notes} />
            <Condition defaultStateName="moreTypesOfUse" Component={MoreTypesOfUse} />
          </div>
        </div>
      </CardContent>
      <CardFooter>{NavigationButtons}</CardFooter>
    </div>
  )
}

export default PurchaseProfilePipelineExtendedCriteria
