import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../../../UI/Dropdown/Dropdown'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const AssetClass = () => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (value) => {
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, assetClass: value }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.asset-class']}</div>
        <div className="values">
          <Dropdown
            name={translations['my-objects.pipeline.assets-class.asset-class']}
            value={purchaseProfile.assetClass}
            onChange={update}
            options={settings.getGroup('object.asset-class.options').map((option) => ({
              key: option.key,
              value: translations[option.translationKey],
            }))}
          />
        </div>
      </div>
    </div>
  )
}

export default AssetClass
