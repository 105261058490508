import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const ConstructionYear = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...purchaseProfile, constructionSituation: { ...purchaseProfile.constructionSituation, [key]: value } }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation']}</div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation.bgf-from']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.constructionSituation.bgfFrom}
            onChange={update('bgfFrom')}
            placeholder={translations['my-objects.pipeline.location-criteria.construction-situation.bgf-from']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation.bgf-until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              value={purchaseProfile.constructionSituation.bgfUntil}
              onChange={update('bgfUntil')}
              placeholder={translations['my-objects.pipeline.location-criteria.construction-situation.bgf-until']}
            />
          </div>
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation.grz-from']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.constructionSituation.grzFrom}
            onChange={update('grzFrom')}
            placeholder={translations['my-objects.pipeline.location-criteria.construction-situation.grz-from']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation.grz-until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              value={purchaseProfile.constructionSituation.grzUntil}
              onChange={update('grzUntil')}
              placeholder={translations['my-objects.pipeline.location-criteria.construction-situation.grz-until']}
            />
          </div>
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation.gfz-from']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.constructionSituation.gfzFrom}
            onChange={update('gfzFrom')}
            placeholder={translations['my-objects.pipeline.location-criteria.construction-situation.gfz-from']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.construction-situation.gfz-until']}</div>
        <div className="values">
          <div className="values">
            <Input
              type="number"
              value={purchaseProfile.constructionSituation.gfzUntil}
              onChange={update('gfzUntil')}
              placeholder={translations['my-objects.pipeline.location-criteria.construction-situation.gfz-until']}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConstructionYear
