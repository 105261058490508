import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
// import CheckboxGroup from '../../../../../UI/CheckboxGroup/CheckboxGroup'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const DealType = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (newArr) => {
    console.log('UPDATE')
    const updatePurchaseProfile = { ...newObject, dealCriteria: { ...newObject.dealCriteria, dealType: newArr } }
    // dispatch(setNewObject(updatePurchaseProfile))
    dispatch(setLocal(props.OBJECT, updatePurchaseProfile))
  }

  if (!settings || !newObject || !translations) return <></>

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.deal-criteria.deal-type']}</div>
          <div className="values">
            <RadioGroup
              options={settings.getGroup('object.deal-criteria.deal-type.option').map((option) => ({ value: translations[option.key], key: option.key }))}
              value={newObject.dealCriteria.dealType}
              onChange={update}
              itemsPerRow={3}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealType
