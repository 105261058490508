import * as types from '../type/types'

export const setNewPurchaseProfile = (object) => ({
  type: types.SET_NEW_PURCHASE_PIPELINE,
  payload: object,
})

export const setNewPurchaseProfileActiveIndex = (index) => ({
  type: types.SET_NEW_PURCHASE_PIPELINE_NAVIGATION_INDEX,
  payload: index,
})

export const triggerPurchaseProfileCleanup = () => ({
  type: types.TRIGGER_NEW_PURCHASE_PROFILE_CLEANUP,
})

export const setLocationCountry = (array) => ({ type: types.SET_LOCATION_COUNTRY, payload: array })
export const setLocationState = (array) => ({ type: types.SET_LOCATION_STATE, payload: array })
export const setLocationCity = (array) => ({ type: types.SET_LOCATION_CITY, payload: array })
