// import { useSelector } from 'react-redux'
import ObjectCardItem from '../../../ObjectCardItem/ObjectCardItem'
import './Suggestion.scss'
// import { AssetClass } from './Suggestion.Styled.js'

const Suggestion = ({ item }) => {
  //   const translations = useSelector((s) => s.translations)
  return (
    <ObjectCardItem object={item} />
    // <div className="new-objects-suggestions-item">
    //   <AssetClass>{translations[item.assetClass]}</AssetClass>
    // </div>
  )
}

export default Suggestion
