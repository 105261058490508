import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const FixedRentalPriceTimespan = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const updatePurchaseProfile = { ...purchaseProfile, fixedRentalPriceTimespan: { ...purchaseProfile.fixedRentalPriceTimespan, [key]: value } }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.factor-and-return-timespan.from']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.fixedRentalPriceTimespan.from}
            onChange={update('from')}
            placeholder={translations['my-objects.pipeline.location-criteria.factor-and-return-timespan.from']}
            suffix={translations['years.symbol']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.factor-and-return-timespan.until']}</div>
        <div className="values">
          <Input
            type="number"
            value={purchaseProfile.fixedRentalPriceTimespan.until}
            onChange={update('until')}
            placeholder={translations['my-objects.pipeline.location-criteria.factor-and-return-timespan.until']}
            suffix={translations['years.symbol']}
          />
        </div>
      </div>
    </div>
  )
}

export default FixedRentalPriceTimespan
