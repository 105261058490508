import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'

const RentalIncome = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)

  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    // dispatch(setNewObject({ ...newObject, rentalIncome: { ...newObject.rentalIncome, value } }))
    dispatch(setLocal(props.OBJECT, { ...newObject, rentalIncome: { ...newObject.rentalIncome, value } }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.rentalIncome']}</div>
        <div className="values">
          <Input type="number" value={newObject.rentalIncome.pa} onChange={update('pa')} placeholder={translations['object.rentalIncome.pa']} />
          <Input type="number" value={newObject.rentalIncome.pm} onChange={update('pm')} placeholder={translations['object.rentalIncome.pm']} />
          <Input
            type="number"
            value={newObject.rentalIncome.averageResidentialRentalIncomePerSqm}
            onChange={update('averageResidentialRentalIncomePerSqm')}
            placeholder={translations['object.rentalIncome.averageResidentialRentalIncomePerSqm']}
          />
          <Input type="number" value={newObject.rentalIncome.commercialPa} onChange={update('commercialPa')} placeholder={translations['object.rentalIncome.commercialPa']} />
          <Input type="number" value={newObject.rentalIncome.commercialPm} onChange={update('commercialPm')} placeholder={translations['object.rentalIncome.commercialPm']} />
          <Input
            type="number"
            value={newObject.rentalIncome.commercialAverageResidentialRentalIncomePerSqm}
            onChange={update('commercialAverageResidentialRentalIncomePerSqm')}
            placeholder={translations['object.rentalIncome.commercialAverageResidentialRentalIncomePerSqm']}
          />
          <Input type="number" value={newObject.rentalIncome.totalPa} onChange={update('totalPa')} placeholder={translations['object.rentalIncome.totalPa']} />
          <Input type="number" value={newObject.rentalIncome.totalPm} onChange={update('totalPm')} placeholder={translations['object.rentalIncome.totalPm']} />
          <Input type="number" value={newObject.rentalIncome.basicRentPa} onChange={update('basicRentPa')} placeholder={translations['object.rentalIncome.basicRentPa']} />
        </div>
      </div>
    </div>
  )
}

export default RentalIncome
