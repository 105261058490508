import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSettings } from '../../action/settings'
import settingsDefault from '../../reducer/default/settings'

const Settings = (props) => {
  const settings = useSelector((state) => state.settings)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getSettings = async () => {
      //   const settings = await list(token, 'SETTINGS', {})
      //   console.log(settings)
      //   const { status, settings } = await fetch('/settings/get', { token })
      if (settingsDefault) {
        dispatch(setSettings(settingsDefault))
        setLoading(false)
      }
    }

    if (!settings) {
      getSettings()
    } else {
      setLoading(false)
    }
  }, [dispatch, settings])

  if (loading) return <></>
  return <>{props.children}</>
}

export default Settings
