import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import Input from '../../../../../UI/Input/Input'
import { getUUID } from '../../../../../../utility'
import defaultState from '../../../../../../reducer/defaultState'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const OperatorContract = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const mapping = {
      leaseAgreement: 'leaseAgreementMinRemainingTime',
      managementContract: 'managementContractMinRemainingTime',
    }

    const operatorContract = { ...purchaseProfile.operatorContract }

    if (value === false && mapping[key]) {
      operatorContract[mapping[key]] = defaultState.purchaseProfile.operatorContract[mapping[key]]
    }

    operatorContract[key] = value

    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, operatorContract }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, operatorContract }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.operator-contract']}</div>
        <div className="values">
          <Checkbox
            key={getUUID()}
            checked={purchaseProfile.operatorContract.leaseAgreement}
            onChange={update('leaseAgreement')}
            text={translations['my-objects.pipeline.yield-and-object-criteria.operator-contract.lease-agreement']}
          />
          <Input
            type="customNumber"
            prefix={translations['min.symbol']}
            disabled={!purchaseProfile.operatorContract.leaseAgreement}
            value={purchaseProfile.operatorContract.leaseAgreementMinRemainingTime}
            onChange={update('leaseAgreementMinRemainingTime')}
            symbol={translations['years.symbol']}
            suffix={translations['remainingtime.symbol']}
          />
          <Checkbox
            key={getUUID()}
            checked={purchaseProfile.operatorContract.managementContract}
            onChange={update('managementContract')}
            text={translations['my-objects.pipeline.yield-and-object-criteria.operator-contract.management-contract']}
          />
          <Input
            type="customNumber"
            prefix={translations['min.symbol']}
            disabled={!purchaseProfile.operatorContract.managementContract}
            value={purchaseProfile.operatorContract.managementContractMinRemainingTime}
            onChange={update('managementContractMinRemainingTime')}
            symbol={translations['years.symbol']}
            suffix={translations['remainingtime.symbol']}
          />
        </div>
      </div>
    </div>
  )
}

export default OperatorContract
