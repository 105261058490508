import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const ProjectType = ({ widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (newArr) => {
    // dispatch(setNewObject({ ...newObject, projectType: newArr }))
    dispatch(setLocal(props.OBJECT, { ...newObject, projectType: newArr }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.assets-class.project-type']}</div>
        <div className="values">
          <RadioGroup
            options={settings.getGroup('object.project-type.options').map((option) => ({ value: translations[option.key], key: option.key }))}
            values={newObject.projectType}
            onChange={update}
            itemsPerRow={1}
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectType
