import { useDispatch, useSelector } from 'react-redux'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const FixedRentalPrice = () => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (value) => {
    const updatePurchaseProfile = { ...purchaseProfile, fixedRentalPrice: value }
    // dispatch(setNewPurchaseProfile(updatePurchaseProfile))
    dispatch(setLocal(props.PURCHASE_PROFILE, updatePurchaseProfile))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="values">
          <RadioGroup
            name={translations['my-objects.pipeline.location-criteria.fixed-rental-price']}
            value={purchaseProfile.fixedRentalPrice}
            onChange={update}
            options={settings.getGroup('object.fixed-rental.price.options').map((option) => ({
              key: option.key,
              value: translations[option.translationKey],
            }))}
          />
        </div>
      </div>
    </div>
  )
}

export default FixedRentalPrice
