const conditions = () => {
  return [
    { projectType: { $ne: '' } },
    {
      assetClass: {
        $in: {
          'object.asset-class.options.wohnen': 'object.object-condition.wohnen.options',
          'object.asset-class.options.handel-retail': 'object.object-condition.wohnen.options',
          'object.asset-class.options.hotel': 'object.object-condition.wohnen.options',
          'object.asset-class.options.healthcare-medical': 'object.object-condition.wohnen.options',
          'object.asset-class.options.buero': 'object.object-condition.wohnen.options',
          'object.asset-class.options.logistik-industrie': 'object.object-condition.wohnen.options',
          'object.asset-class.options.micro-und-serviced-living': 'object.object-condition.wohnen.options',
          'object.asset-class.options.freies-ankaufsprofil': 'object.object-condition.wohnen.options',
        },
      },
    },
  ]
}

export default conditions
