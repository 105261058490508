import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import props from '../../../../../../redux/props'
import { setLocal } from '../../../../../../redux/action/local'

const AnchorTenantSplit = () => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (value) => {
    const anchorTenantSplit = { ...purchaseProfile.anchorTenantSplit, [key]: value }
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, anchorTenantSplit }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, anchorTenantSplit }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split']}</div>
        <div className="values">
          <div className="subgroup">
            <div className="element">
              <Input
                type="number"
                value={purchaseProfile.anchorTenantSplit.spacePercentrageFrom}
                onChange={update('spacePercentrageFrom')}
                placeholder={translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.space-percentage-from']}
                suffix={translations['percent.symbol']}
              />
            </div>
            <div className="element">
              <Input
                type="number"
                value={purchaseProfile.anchorTenantSplit.spacePercentrageUntil}
                onChange={update('spacePercentrageUntil')}
                placeholder={translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.space-percentage-until']}
                suffix={translations['percent.symbol']}
              />
            </div>
          </div>
          <div className="subgroup">
            <div className="element">
              <Input
                type="number"
                value={purchaseProfile.anchorTenantSplit.rentPercentrageFrom}
                onChange={update('rentPercentrageFrom')}
                placeholder={translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.rent-percentage-from']}
                suffix={translations['percent.symbol']}
              />
            </div>
            <div className="element">
              <Input
                type="number"
                value={purchaseProfile.anchorTenantSplit.rentPercentrageUntil}
                onChange={update('rentPercentrageUntil')}
                placeholder={translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.rent-percentage-until']}
                suffix={translations['percent.symbol']}
              />
            </div>
          </div>
          <div className="subgroup">
            <div className="element">
              <Input
                type="number"
                value={purchaseProfile.anchorTenantSplit.minimumTermFrom}
                onChange={update('minimumTermFrom')}
                placeholder={translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.minimum-term-from']}
                suffix={translations['years.symbol']}
              />
            </div>
            <div className="element">
              <Input
                type="number"
                value={purchaseProfile.anchorTenantSplit.minimumTermUntil}
                onChange={update('minimumTermUntil')}
                placeholder={translations['my-objects.pipeline.yield-and-object-criteria.anchor-tenant-split.minimum-term-until']}
                suffix={translations['years.symbol']}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnchorTenantSplit
