import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import Dropdown from '../../../../../UI/Dropdown/Dropdown'
import Button from '../../../../../UI/Button/Button'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const MoreTypesOfUsage = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const [selectedType, setSelectedType] = useState('')
  const [currentShare, setCurrentShare] = useState(0)

  const onClickAdd = () => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, moreTypesOfUsage: [...purchaseProfile.moreTypesOfUsage, { type: selectedType, maxShare: currentShare }] }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, moreTypesOfUsage: [...purchaseProfile.moreTypesOfUsage, { type: selectedType, maxShare: currentShare }] }))
  }

  const onClickRemove = (index) => () => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, moreTypesOfUsage: purchaseProfile.moreTypesOfUsage.filter((el, i) => i !== index) }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, moreTypesOfUsage: purchaseProfile.moreTypesOfUsage.filter((el, i) => i !== index) }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.more-types-of-use']}</div>
        <div className="values">
          <Dropdown
            name={translations['my-objects.pipeline.location-criteria.more-types-of-use']}
            options={settings.getGroup(payload).map((option) => ({ key: option.key, value: translations[option.key] }))}
            value={selectedType}
            onChange={setSelectedType}
            showEmptyOption={true}
          />
        </div>
      </div>
      <div className="group">
        <div className="values">
          <Input
            type="customNumber"
            prefix={'max. '}
            suffix={translations['percent.share']}
            value={currentShare}
            onChange={setCurrentShare}
            placeholder={translations['my-objects.pipeline.location-criteria.more-types-of-use.share-from']}
            symbol={translations['percent.symbol']}
          />
        </div>
      </div>
      <div className="group">
        <div className="label"></div>

        <div className="values">
          <Button onClick={onClickAdd} text={translations['settings.save-cta']} />
        </div>
      </div>
      <div className="group">
        <div className="values">
          <div className="tag-list bottom">
            {purchaseProfile.moreTypesOfUsage.map((item, i) => (
              <div className="tag" key={i}>
                <div className="value">{`${translations[item.type]} | ${item.maxShare} ${translations['percent.symbol']}`}</div>
                <CloseIcon className="btnRemove" onClick={onClickRemove(i)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreTypesOfUsage
