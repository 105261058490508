import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Input from '../../../../../UI/Input/Input'
import { getUUID } from '../../../../../../utility'

import MeasuresItem from './MeasuresItem'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const Measures = ({ widthInPercent }) => {
  const [currentMeasure, setCurrentMeasure] = useState('')
  const [currentDescription, setCurrentDescription] = useState('')
  const [currentYear, setCurrentYear] = useState('')

  const user = useSelector((state) => state.USER)
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const add = () => {
    if (currentMeasure && currentDescription && currentYear && user) {
      // dispatch(setNewObject({ ...newObject, measures: [...newObject.measures, { uuid: getUUID(), measure: currentMeasure, description: currentDescription, year: currentYear, userUUID: user.uuid }] }))
      dispatch(
        setLocal(props.OBJECT, {
          ...newObject,
          measures: [...newObject.measures, { uuid: getUUID(), measure: currentMeasure, description: currentDescription, year: currentYear, userUUID: user.uuid }],
        }),
      )
    }
    setCurrentMeasure('')
    setCurrentDescription('')
    setCurrentYear('')
  }

  const remove = (uuid) => () => {
    // dispatch(setNewObject({ ...newObject, measures: newObject.measures.filter((m) => m.uuid !== uuid) }))
    dispatch(setLocal(props.OBJECT, { ...newObject, measures: newObject.measures.filter((m) => m.uuid !== uuid) }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="element">
          <div className="label">{translations['object.measures']}</div>
          <div className="values">
            {newObject.measures.map((item) => (
              <MeasuresItem item={item} remove={remove} />
            ))}
            <div>
              <Input type="text" placeholder={translations['object.measures.measure']} value={currentMeasure} onChange={setCurrentMeasure} />
              <Input type="text" placeholder={translations['object.measures.description']} value={currentDescription} onChange={setCurrentDescription} />
              <Input type="customNumber" value={currentYear} onChange={setCurrentYear} placeholder={translations['year.symbol']} />
            </div>
            <div>
              <div onClick={add}>{translations['object.measures.add-measure']}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Measures
