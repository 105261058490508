import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import call from '../../../../../../interface/functional'
import { getUUID } from '../../../../../../utility'
import Input from '../../../../../UI/Input/Input'
import './Autocomplete.scss'

const Autocomplete = ({ onSelectedPlace }) => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  // const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } = useGoogle({
  //   apiKey: googleAPIKey,
  //   debounce: 2000,
  //   options: {
  //     fields: ['address_component', 'place_id'],
  //     types: ['address'],
  //   },
  // })

  const [value, setValue] = useState('')
  const [showPredictions, setShowPredictions] = useState(false)
  const [placePredictions, setPlacePredictions] = useState([])
  const [lastInput, setLastInput] = useState(new Date())

  useEffect(() => {
    if (value) setShowPredictions(true)
    else setShowPredictions(false)
  }, [value])

  const getPlacePredictions = async (input) => {
    const currentDate = new Date()

    //currentDate - lastInput < 2000
    if (input.length < 3 || currentDate - lastInput < 2000) return
    const { status, response } = await call(token, 'geo_suggestion_completion', { input })
    if ((status, response)) {
      setPlacePredictions(response)
    }
  }

  const onClick = (item) => async () => {
    const { status, response } = await call(token, 'geo_suggestion_details', { placeId: item.googlePlaceId })

    if (status && response) {
      const { response: coordinates } = await call(token, 'geo_suggestion_coordinates', { placeId: item.googlePlaceId })
      setValue('')
      onSelectedPlace(response?.result?.address_components, coordinates)
    }
  }

  // placesService.getDetails({ placeId: item.place_id }, (placeDetails) => {
  //   setValue('')
  //   onSelectedPlace(placeDetails.address_components)
  // })

  return (
    <div id="autocomplete">
      <Input
        value={value}
        onChange={(value) => {
          getPlacePredictions(value)
          setValue(value)
          setLastInput(new Date())
        }}
        placeholder={translations['object.location.search']}
      />
      <div>
        {showPredictions && (
          <div className="predictionList">
            {placePredictions.map((item) => (
              <div key={getUUID()} onClick={onClick(item)} className="predictionItem">
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Autocomplete
