import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fallbackProfilImage, getPrettyTime, getUUID } from '../../../../utility'
import ImageWrapper from '../../../ImageWrapper/ImageWrapper'
import Input from '../../../UI/Input/Input'
import './RoomList.scss'

const RoomList = ({ socket, roomSelect, activeRoomUUID, objectUUID }) => {
  const token = useSelector((state) => state.TOKEN)
  const user = useSelector((state) => state.USER)
  const translations = useSelector((state) => state.translations)

  const [list, setList] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (token && !list) {
      socket.emit('room-list', { token })
    }

    const sortList = (list) => {
      const withoutMessage = list.filter((r) => !r.message)
      const withMessage = list
        .filter((r) => !!r.message)
        .sort((a, b) => {
          return new Date(b.message.createdOn).getTime() - new Date(a.message.createdOn).getTime()
        })
      return [...withoutMessage, ...withMessage]
    }

    socket.on('room-list', (list) => setList(sortList(list)))
    socket.on('room-list-item-update', (room) => {
      if (room.sellerUserUUID === user.uuid || room.buyerUserUUID === user.uuid) {
        if (list.find((r) => r.uuid === room.uuid)) {
          setList(sortList(list.map((r) => (r.uuid === room.uuid ? room : r))))
        } else {
          setList(sortList([...list, room]))
        }
      }
    })

    return () => {
      socket.off('room-list')
      socket.off('room-list-item-update')
    }
  }, [activeRoomUUID, socket, token, list, user.uuid])

  const matchesSearch = (room) => {
    if (search.trim().length > 0) {
      const person = room.buyer.uuid === user.uuid ? room.seller : room.buyer
      return (
        person.firstname.toLowerCase().includes(search.toLowerCase()) ||
        person.lastname.toLowerCase().includes(search.toLowerCase()) ||
        `${person.firstname} ${person.lastname}`.toLowerCase().includes(search.toLowerCase())
      )
    } else {
      return true
    }
  }
  if (!list || !user) return <></>
  return (
    <div className="chat-room-list">
      <div className="search">
        <Input value={search} onChange={setSearch} placeholder={translations['chat.messagestab.room-list.search']} />
      </div>
      {list.map((r) => {
        if (matchesSearch(r)) {
          const partner = r.buyerUserUUID === user.uuid ? r.seller : r.buyer
          const unseenMessages = r.buyerUserUUID === user.uuid ? r.buyerUnseenMessages : r.sellerUnseenMessages
          return (
            <div key={getUUID()} className={`room${r.uuid === activeRoomUUID || r.objectUUID === objectUUID ? ' active' : ''}`} onClick={() => roomSelect(r.uuid)}>
              <div className="image">
                <ImageWrapper image={partner.profileImage || fallbackProfilImage} round={true} />
              </div>
              <div className="name-and-message-wrapper">
                <div className="name">{`${partner.firstname} ${partner.lastname}`}</div>
                {r.message && <div className="time">{getPrettyTime(new Date(r.message.createdOn))}</div>}
                {r.message && <div className="message">{r.message.message}</div>}
                {r.message && unseenMessages > 0 && (
                  <div className="unseen-messages">
                    <div className="bubble">{unseenMessages}</div>
                  </div>
                )}
              </div>
            </div>
          )
        }
        return <span key={getUUID()}></span>
      })}
    </div>
  )
}

export default RoomList
