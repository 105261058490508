import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import fetch from '../../../../fetch'
import { getUUID } from '../../../../utility'
import './Suggestions.scss'
import Suggestion from './Suggestion/Suggestion.js'
// import call from '../../../../interface/functional'
import { get, list } from '../../../../interface/crud'
import props from '../../../../redux/props'

const Suggestions = () => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [suggestions, setSuggestions] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchSuggestions = async () => {
      const purchaseProfiles = await list(token, props.PURCHASE_PROFILE, { userUUID: token.userUUID })
      console.log(purchaseProfiles)
      const logs = await list(token, props.MATCHING_LOG, { purchaseProfileUUID: { $in: purchaseProfiles.map((p) => p.uuid) } })
      console.log(logs)
      const objects = []
      for (const log of logs) {
        const object = await get(token, props.OBJECT, { uuid: log.objectUUID })
        objects.push(object)
      }
      // const { status, suggestions } = await fetch('/suggestion/list', { token })
      if (objects) {
        setSuggestions(objects)
      } else {
        setError(true)
      }
    }
    if (token && !suggestions) {
      fetchSuggestions()
    }
  })

  return (
    <div className="new-objects-suggestions">
      {Array.isArray(suggestions) && suggestions.length > 0 && suggestions.map((s) => <Suggestion key={getUUID()} item={s} />)}
      {Array.isArray(suggestions) && suggestions.length === 0 ? <div className="message">{translations['new-object.suggestions.no-results']}</div> : ''}
      {error && <div className="message">{translations['new-object.suggestions.error']}</div>}
    </div>
  )
}

export default Suggestions
