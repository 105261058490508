import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { getUUID } from '../../../../../../utility'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const MoreTypesOfUsage = ({ payload }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (key) => (isChecked) => {
    const newBuildingLawSituation = isChecked ? [...purchaseProfile.buildingLawSituation, key] : purchaseProfile.buildingLawSituation.filter((k) => k !== key)
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, buildingLawSituation: newBuildingLawSituation }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, buildingLawSituation: newBuildingLawSituation }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="label">{translations['my-objects.pipeline.location-criteria.building-law-situation']}</div>
        <div className="values">
          {settings.getGroup(payload).map((option) => (
            <Checkbox key={getUUID()} checked={purchaseProfile.buildingLawSituation.includes(option.key)} onChange={update(option.key)} text={translations[option.translationKey]} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MoreTypesOfUsage
