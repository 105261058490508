import { useSelector } from 'react-redux'
import { StyledCard, Header, Wrapper, Section, Item, Headline, SubHeadline, Key, Value } from './ObjectCardItem.styles.js'

const ObjectCardItem = ({ object, onObjectClick, key }) => {
  const translations = useSelector((state) => state.translations)
  return (
    <StyledCard key={key} onClick={onObjectClick}>
      <Header>
        <Headline>{translations[object.assetClass]}</Headline>
        <SubHeadline>{object.location.street + ' ' + object.location.houseNumber + ', ' + object.location.postcode + ' ' + object.location.city}</SubHeadline>
      </Header>
      <Wrapper>
        <Section>
          <Item>
            <Key>Verkaufspreis</Key>
            <Value>{object.sellingPrice}</Value>
          </Item>
          <Item>
            <Key>Assetklasse</Key>
            <Value>{translations[object.assetClass]}</Value>
          </Item>

          <Item>
            <Key>Dealtyp</Key>
            <Value>{translations[object.dealCriteria.dealType]}</Value>
          </Item>
          <Item>
            <Key>Risikoklasse</Key>
            <Value>{translations[object.dealCriteria.investmentType]}</Value>
          </Item>
        </Section>
        <Section>
          {/* <Item>
            <Key>Nettomiete p.a.</Key>
            <Value>{}</Value>
          </Item>
          <Item>
            <Key>Grundstücksgröße</Key>
            <Value>{}</Value>
          </Item>

          <Item>
            <Key>Baujahr</Key>
            <Value>{}</Value>
          </Item>
          <Item>
            <Key>vermietbare Fläche</Key>
            <Value>{}</Value>
          </Item> */}
          <Item>
            <Key></Key>
            <Value></Value>
          </Item>
          <Item>
            <Key></Key>
            <Value></Value>
          </Item>

          <Item>
            <Key></Key>
            <Value></Value>
          </Item>
          <Item>
            <Key></Key>
            <Value></Value>
          </Item>
        </Section>
        <Section>
          <Item>
            <Key></Key>
            <Value></Value>
          </Item>
          <Item>
            <Key></Key>
            <Value></Value>
          </Item>

          <Item>
            <Key></Key>
            <Value></Value>
          </Item>
          <Item>
            <Key></Key>
            <Value></Value>
          </Item>
        </Section>
      </Wrapper>
    </StyledCard>
  )
}

export default ObjectCardItem
