import DashboardLayout from '../../../Layout/Dashboard/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import './Pipeline.scss'
// import { triggerNewObjectPipelineCleanup, setNewObject } from '../../../../action/newObject'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Elements from './Elements/Elements'
import { setLocal } from '../../../../redux/action/local'
import props from '../../../../redux/props'
import defaultState from '../../../../redux/defaultState'
import { get } from '../../../../interface/crud'

const NewObjectPipeline = () => {
  const token = useSelector((state) => state.TOKEN)
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const pullRawObject = async (uuid) => {
      const object = await get(token, props.OBJECT, { uuid })
      if (object) {
        dispatch(setLocal(props.OBJECT, object))
        setLoading(false)
      }
    }

    if (params.uuid) {
      if (token) {
        pullRawObject(params.uuid)
      }
    } else {
      setLoading(false)
    }
    return () => dispatch(setLocal(props.OBJECT, defaultState.OBJECT))
  }, [params, dispatch, token])

  return (
    <DashboardLayout card={true}>
      {!loading && (
        <div className="new-objects-pipeline">
          <Elements />
        </div>
      )}
    </DashboardLayout>
  )
}

export default NewObjectPipeline
