import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'

const OperatorContract = ({ payload, widthInPercent }) => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const newObject = useSelector((state) => state.OBJECT)
  const dispatch = useDispatch()

  const update = (value) => {
    // dispatch(setNewObject({ ...newObject, operatorContract: value }))
    dispatch(setLocal(props.OBJECT, { ...newObject, operatorContract: value }))
  }

  return (
    <div className="wrapper" style={{ width: `${widthInPercent}%` }}>
      <div className="group">
        <div className="label">{translations['object.operatorcontract']}</div>
        <div className="values">
          <RadioGroup
            name={translations['object.operatorcontract']}
            options={settings.getGroup('object.operatorcontract.options').map((option) => ({ key: option.key, value: translations[option.translationKey] }))}
            onChange={update}
            value={newObject.operatorContract}
          />
        </div>
      </div>
    </div>
  )
}

export default OperatorContract
