import { useSelector } from 'react-redux'
import './Elements.scss'
import CardHeader from '../../../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../../../Layout/Dashboard/CardContent/CardContent'
import Condition from '../Condition/Condition'
import ObjectType from './SubComponents/ObjectType'
import AssetClass from './SubComponents/AssetClass'
import ProjectType from './SubComponents/ProjectType'
import DealType from './SubComponents/DealType'
import Location from './SubComponents/Location'
import YieldCriteria from './SubComponents/YieldCriteria'
import ObjectData from './SubComponents/ObjectData'
import More from './SubComponents/More'
import Measures from './SubComponents/Measures'
import SalesProcess from './SubComponents/SalesProcess'
import SellingPrice from './SubComponents/sellingPrice'
import Photos from './SubComponents/Photos'
import InvestmentType from './SubComponents/InvestmentType'
import Portfolio from './SubComponents/Portfolio'
import Investment from './SubComponents/Investment'
import Other from './SubComponents/Other'
import FreeTags from './SubComponents/FreeTags'
import HotelRating from './SubComponents/HotelRating'
import Administration from './SubComponents/Administration'
import Financing from './SubComponents/Financing'
import HotelRooms from './SubComponents/HotelRooms'
import HotelAdditional from './SubComponents/HotelAdditional'
import NumberOfPitches from './SubComponents/NumberOfPitches'
import OperatingCost from './SubComponents/OperatingCost'
import OperatorContract from './SubComponents/OperatorContract'
import RentalIncome from './SubComponents/RentalIncome'
import Searched from './SubComponents/Searched'
import TenantList from './SubComponents/TenantList'
// import fetch from '../../../../../fetch'
import Button from '../../../../UI/Button/Button'
import { useHistory, useParams } from 'react-router-dom'
import { upsert } from '../../../../../interface/crud'
import PlotPrice from './SubComponents/PlotPrice.js'
import TotalInvestment from './SubComponents/TotalInvestment.js'
import props from '../../../../../redux/props'

const NewObjectPipelineElements = () => {
  const translations = useSelector((state) => state.translations)
  const newObject = useSelector((state) => state.OBJECT)
  const token = useSelector((state) => state.TOKEN)
  const params = useParams()
  const history = useHistory()
  const account = useSelector((state) => state.ACCOUNT)

  const save = async () => {
    const uuid = params.uuid

    const result = await upsert(token, props.OBJECT, { ...newObject, uuid, userUUID: account.assignedToUUID }, ['uuid'])
    if (!result) return

    history.push('/dashboard/my-objects')
  }

  return (
    <div className="new-object-pipeline-elements">
      <CardHeader headline={translations['my-objects.pipeline.assets-class.headline']} />
      <CardContent>
        <div className="section">
          <div className="title">{translations['my-objects.pipeline.assets-class']}</div>
          <div className="input-groups">
            <Condition defaultStateName="assetClass" Component={AssetClass} widthInPercent={45} />
            <Condition defaultStateName="location" Component={Location} widthInPercent={45} />
            <Condition defaultStateName="objectType" Component={ObjectType} widthInPercent={66} />
            <Condition defaultStateName="hotelRating" Component={HotelRating} />
            <Condition defaultStateName="hotelRooms" Component={HotelRooms} />
            <Condition defaultStateName="totalInvestment" Component={TotalInvestment} />
            <Condition defaultStateName="hotelAdditional" Component={HotelAdditional} />
            <Condition defaultStateName="projectType" Component={ProjectType} />
            <Condition defaultStateName="dealType" Component={DealType} />
            <Condition defaultStateName="plotPrice" Component={PlotPrice} />
            <Condition defaultStateName="investmentType" Component={InvestmentType} />
            <Condition defaultStateName="investment" Component={Investment} />
            <Condition defaultStateName="other" Component={Other} />
            <Condition defaultStateName="portfolio" Component={Portfolio} />
            <Condition defaultStateName="yieldCriteria" Component={YieldCriteria} />
            <Condition defaultStateName="financing" Component={Financing} />
            <Condition defaultStateName="objectData" Component={ObjectData} />
            <Condition defaultStateName="numberOfPitches" Component={NumberOfPitches} />
            <Condition defaultStateName="more" Component={More} />
            <Condition defaultStateName="measures" Component={Measures} />
            <Condition defaultStateName="operatingCost" Component={OperatingCost} />
            <Condition defaultStateName="operatorContract" Component={OperatorContract} />
            <Condition defaultStateName="administration" Component={Administration} />
            <Condition defaultStateName="rentalIncome" Component={RentalIncome} />
            <Condition defaultStateName="searched" Component={Searched} />
            <Condition defaultStateName="salesProcess" Component={SalesProcess} />
            <Condition defaultStateName="sellingPrice" Component={SellingPrice} />
            <Condition defaultStateName="photos" Component={Photos} />
            <Condition defaultStateName="freeTags" Component={FreeTags} />
            <Condition defaultStateName="tenantList" Component={TenantList} />
          </div>
        </div>
      </CardContent>
      <Button onClick={save} text="Speichern" />
    </div>
  )
}

export default NewObjectPipelineElements
