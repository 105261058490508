import { useSelector } from 'react-redux'
import { formatPrice } from '../../../../../utility'
import './Card.scss'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { update } from '../../../../../interface/crud'
import props from '../../../../../redux/props'

const Card = ({ data, copy, remove }) => {
  const translations = useSelector((state) => state.translations)
  const token = useSelector((state) => state.TOKEN)
  const [isVisible, setIsVisible] = useState(true)
  const history = useHistory()

  const toggleActiveState = async () => {
    const result = await update(token, props.PURCHASE_PROFILE, { ...data, active: !data.active })
    if (result) {
      setIsVisible(false)
    }
  }

  const edit = (uuid) => history.push(`/dashboard/purchase-profile/pipeline/${uuid}`)

  if (!isVisible) return <></>
  return (
    <div className="purchase-profile-card">
      <div className="header">
        <div className="title">{translations[data.assetClass]}</div>
        <div className="controls">
          <div className="control" onClick={toggleActiveState}>
            {data.active ? <PauseIcon /> : <PlayArrowIcon />}
          </div>
          <div className="control" onClick={() => copy(data.uuid)}>
            <ContentCopyIcon />
          </div>
          <div className="control" onClick={() => edit(data.uuid)}>
            <EditIcon />
          </div>
          <div className="control" onClick={() => remove(data.uuid)}>
            <DeleteIcon />
          </div>
        </div>
      </div>
      <div className="body">
        <div className="column">
          <div className="prop">
            <div className="key">{translations['purchase-profile.deal-type.single-investment']}</div>
            <div className="value">{`${formatPrice(data.investmentVolume.singleFrom * 100)} - ${formatPrice(data.investmentVolume.singleUntil * 100)}`}</div>
          </div>
          <div className="prop">
            <div className="key">{translations['purchase-profile.deal-type.porfolio-investment']}</div>
            <div className="value">{`${formatPrice(data.portfolioInvestmentVolume.singleFrom * 100)} - ${formatPrice(data.portfolioInvestmentVolume.singleUntil * 100)}`}</div>
          </div>
          <div className="prop">
            <div className="key">{translations['purchase-profile.deal-type']}</div>
            <div className="value">{data.dealCriteria.dealType.map((type) => translations[type]).join(' | ')}</div>
          </div>
          <div className="prop">
            <div className="key">{translations['purchase-profile.risk-class']}</div>
            <div className="value">{data.dealCriteria.investmentType.map((type) => translations[type]).join(' | ')}</div>
          </div>
        </div>
        <div className="column">
          <div className="prop">
            <div className="key">{translations['purchase-profile.return']}</div>
            <div className="value">
              {translations['purchase-profile.return.factor']}
              {data.factorAndReturn.factorValue}
              {` (${data.factorAndReturn.returnValue} ${translations['percent.symbol']})`}
            </div>
          </div>
          <div className="prop">
            <div className="key">{translations['purchase-profile.plot-size']}</div>
            <div className="value">{`${data.plotSize.from} - ${data.plotSize.until}${translations['square-meter.symbol']}`}</div>
          </div>
          <div className="prop">
            <div className="key">{translations['purchase-profile.construction-year']}</div>
            <div className="value">{`${data.constructionYear.from} - ${data.constructionYear.until}`}</div>
          </div>
          <div className="prop">
            <div className="key">{translations['purchase-profile.rentable-space']}</div>
            <div className="value">{`${data.generalObjectCriteria.livingSpaceFrom} - ${data.generalObjectCriteria.livingSpaceUntil}${translations['square-meter.symbol']}`}</div>
          </div>
        </div>
        <div className="column">
          {/* <div className="prop locations">
            <div className="key">{translations['purchase-profile.locations']}</div>
            <div className="list">
              {data.locations.map((loc) => (
                <div key={getUUID()} className="item">{`${loc} | ${data.minPopulation}${translations['purchase-profile.locations.population']}`}</div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Card
