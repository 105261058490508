import "./PasswordLost.scss";
import fetch from "../../../fetch";
import { useSelector } from "react-redux";
import { useState } from "react";
import Layout from "../../Layout/Layout";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import Link from '../../UI/Link/Link'

const Login = () => {
  const [mail, setMail] = useState("");
  const translations = useSelector(state => state.translations)
  const [message, setMessage] = useState(null);

  const submit = async (e) => {
    e.preventDefault();
    if (!!mail) {
      setMessage(null);
      const { status } = await fetch("/auth/password-lost", {
        mail,
      });
      setMessage(status ? translations['password-lost.success'] : translations['password-lost.unknown-error'])
    } else {
      setMessage(translations["password-lost.missing-input"]);
    }
  };

  return (
    <Layout>
      <div id="password-lost">
        <div className="form-wrapper">
          <div className="headline">{translations["password-lost.headline"]}</div>
          <form onSubmit={submit}>
            <div className="element-wrapper">
              <Input
                type="email"
                placeholder={translations["password-lost.mail"]}
                value={mail}
                onChange={setMail}
              />
            </div>
            {message ? <div className="message">{message}</div> : ""}
            {!message ? <div className="message">{translations['password-lost.explain']}</div> : ""}
            <div className="element-wrapper">
              <Button text={translations["password-lost.button"]} type={"submit"} />
            </div>
            <div className="switches">
              <div className="switch">
                <Link href="/login" text={translations["password-lost.login"]} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
