import { useDispatch, useSelector } from 'react-redux'
import RadioGroup from '../../../../../UI/RadioGroup/RadioGroup'
import { setLocal } from '../../../../../../redux/action/local'
import props from '../../../../../../redux/props'

const TypeOfFinancing = () => {
  const translations = useSelector((state) => state.translations)
  const settings = useSelector((state) => state.settings)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const update = (value) => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, typeOfFinancing: value }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, typeOfFinancing: value }))
  }

  return (
    <div className="wrapper">
      <div className="group">
        <div className="values">
          <RadioGroup
            name={translations['my-objects.pipeline.assets-class.type-of-financing']}
            value={purchaseProfile.typeOfFinancing}
            onChange={update}
            options={settings.getGroup('object.type-of-financing.options').map((option) => ({
              key: option.key,
              value: translations[option.translationKey],
            }))}
          />
        </div>
      </div>
    </div>
  )
}

export default TypeOfFinancing
