import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import { useSelector } from 'react-redux'
import './PurchaseProfile.scss'
import FullScreenHeader from '../../Layout/Dashboard/FullScreenHeader/FullScreenHeader'
import TabView from '../../UI/TabView/TabView'
import Link from '../../UI/Link/Link'
import List from './List/List'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

const PurchaseProfile = () => {
  const translations = useSelector((state) => state.translations)
  const tabItems = [
    { name: translations['purchase-profile.tab.aktive-profiles'], component: <List state={'active'} /> },
    { name: translations['purchase-profile.tab.paused-profiles'], component: <List state={'paused'} /> },
  ]

  return (
    <DashboardLayout>
      <div id="purchase-profile">
        <FullScreenHeader headline={translations['purchase-profile.headline']} />
        <TabView items={tabItems} />
        <div className="new-purchase-profile-link-wrapper">
          <Link href="/dashboard/purchase-profile/pipeline">
            <div className="new-purchase-profile">
              <div className="button">
                <AddOutlinedIcon />
              </div>
              <div className="text">{translations['purchase-profile.pipeline.cta']}</div>
            </div>
          </Link>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default PurchaseProfile
