import './LocationSuggestion.scss'
import FormControl from '@mui/material/FormControl'
import { useState } from 'react'
import Input from '../../../../../UI/Input/Input'
import { useSelector } from 'react-redux'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildLabel } from './buildLabel'
import { getUUID } from '../../../../../../utility'
import call from '../../../../../../interface/functional'

const LocationSuggestion = (props) => {
  const token = useSelector((state) => state.TOKEN)
  const translations = useSelector((state) => state.translations)
  const { onChange, name, fetchURI, payload } = props
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [selectedSuggestions, setSelectedSuggestions] = useState(null)

  const propIsMissing = () => {
    const requiredProps = ['onChange', 'name', 'fetchURI']
    return requiredProps.reduce((acc, prop) => (acc === false ? false : props[prop] === undefined), true)
  }

  const updateValue = async (value) => {
    setValue(value)
    if (value.length >= 3) {
      // const { status, suggestions } = await fetch(fetchURI, { token, input: value, payload })
      const { status, response: suggestions } = await call(token, `geo_suggestion_${fetchURI}`, { input: value, payload })
      if (status && Array.isArray(suggestions)) {
        const formattedSuggestions = []
        for (const suggestion of suggestions) {
          formattedSuggestions.push({
            key: suggestion.uuid,
            payload: {
              stateLevel1: suggestion.stateLevel1,
              districtsLevel2: suggestion.districtsLevel2,
              metropolitanAreaByCity: suggestion.metropolitanAreaByCity,
              metropolitanAreaByDistrict: suggestion.metropolitanAreaByDistrict,
              city: suggestion.city,
              postcode: suggestion.postcode,
            },
            attributes: suggestion.attributes,
          })
        }
        setSuggestions(formattedSuggestions)
      }
    } else {
      setSuggestions([])
    }
  }

  const select = (item) => () => {
    const itemCopy = { ...item }
    onChange(itemCopy)
    setSelectedSuggestions(itemCopy)
    setValue('')
  }

  const remove = () => {
    onChange(null)
    setSelectedSuggestions(null)
    setValue('')
  }

  if (propIsMissing()) return <></>

  return (
    <FormControl className="custom-MUI-suggestion" variant="filled" fullWidth={true}>
      <Input value={value} onChange={updateValue} placeholder={name} />
      <div className="float">
        <div className="selected-suggestions">
          {selectedSuggestions && (
            <>
              <div className="title">{translations['custom-mui-suggestion.selected.title']}</div>
              <div className="item" key={selectedSuggestions.key}>
                <div className="suggestion-label">{buildLabel(selectedSuggestions)}</div>
                <div className="suggestion-remove" onClick={remove}>
                  <CloseOutlinedIcon />
                </div>
              </div>
            </>
          )}
        </div>

        {Array.isArray(suggestions) && suggestions.length > 0 && value.length > 3 && (
          <div className="suggestions">
            <div className="title">{translations['custom-mui-suggestion.options.title']}</div>
            <div className="item-wrapper">
              {suggestions.map((s) => (
                <div className={`item ${selectedSuggestions === s ? 'selected' : ''}`} onClick={select(s)} key={getUUID()}>
                  {buildLabel(s)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </FormControl>
  )
}

export default LocationSuggestion
