import { useDispatch, useSelector } from 'react-redux'
import './Storage.scss'
import CardHeader from '../../../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../../../Layout/Dashboard/CardContent/CardContent'
import Storage from '../../../Storage/Storage'
import CardFooter from '../../../../Layout/Dashboard/CardFooter/CardFooter'
import { setLocal } from '../../../../../redux/action/local'
import props from '../../../../../redux/props'

const PurchaseProfilePipelineStorage = ({ NavigationButtons }) => {
  const translations = useSelector((state) => state.translations)
  const purchaseProfile = useSelector((state) => state.PURCHASE_PROFILE)
  const dispatch = useDispatch()

  const pushFiles = (files) => {
    // dispatch(setNewPurchaseProfile({ ...purchaseProfile, storageFiles: files }))
    dispatch(setLocal(props.PURCHASE_PROFILE, { ...purchaseProfile, storageFiles: files }))
  }

  return (
    <div className="purchase-profile-pipeline-storage">
      <CardHeader headline={translations['my-objects.pipeline.navigation.storage.label']} />
      <CardContent>
        <Storage pushFiles={pushFiles} />
      </CardContent>
      <CardFooter>{NavigationButtons}</CardFooter>
    </div>
  )
}

export default PurchaseProfilePipelineStorage
